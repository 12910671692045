// core components


import {makeStyles} from "@mui/styles";

export const TypeCommunication = Object.freeze({
    EMAIL       : { code:'E',color:'#2DA4F3',codeServer:'email'},
    FAX         : { code:'F',color:'#C46330',codeServer:'fax'},
    MAIL        : { code:'M',color:'#F3E12D',codeServer:'mail'},
    SMS         : { code:'S',color:'#57be27',codeServer:'sms'},
});

export const stylesTypeCommunication= makeStyles((theme) => ({
    E:{
        color:TypeCommunication.EMAIL.color,
    },
    F:{
        color:TypeCommunication.FAX.color,
    },
    M:{
        color:TypeCommunication.MAIL.color,
    },
    S:{
        color:TypeCommunication.SMS.color,
    },
}));

export const cardTemplateStyles = makeStyles((theme) => ({
    root:{
        display:"flex",
        flexDirection:"row",
        width:'100%',
        maxWidth:'100%',
        backgroundColor:"white",
        boxShadow:'1px 1px 3px lightgrey',
        marginTop:"10px",
        cursor:"pointer",
        '&:first-child':{
            marginTop:"0px",
        },
        '&:hover':{
            backgroundColor:'#F0F0F0',
        }
    },
    colState:{
        width:'70px',
        textAlign: 'center',
        margin:'auto',
    },
    noWrap:{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    col:{
        flex:1,
        padding:'20px',
    },
    colEdit:{
        width:'70px',
        textAlign: 'center',
        margin:'auto',
    },
    colClone:{
        width:'70px',
        textAlign: 'center',
        margin:'auto',
    },
    colDelete:{
        width:'70px',
        textAlign: 'center',
        margin:'auto',
    },
    btDisable:{
        color:'#d3d3d3!important',
    },
    icon:{
        fontSize:'2rem',
    },
    rented:{
        color:'green',
    },
    notRented:{
        color:'orange',
    },
    clone:{
        color:theme.status.warn,
    },
    delete:{
        color:theme.status.danger,
    },
    edit:{
        color:theme.status.success,
    },
    preview:{
        color:theme.palette.primary.main,
    }
}));
