import React, {useContext} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField, Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {capitalizeFirstLetter} from "../../common/tools";
import PropTypes from "prop-types";
import { makeStyles } from '@mui/styles'
import {stateConnection} from "../../services/ServiceTest";
import {AppContext} from "../login/Login";
import {ServiceEnterprise} from "../../services/ServiceEnterprise";
import {SnackBarContext} from "../shared/MessageSnackBar";
import Loader from "../shared/Loader";

const useStyles = makeStyles((theme) => ({
    title:{
        color:theme.palette.primary.main,
    },
    msgErrorArea:{
        textAlign:"right",
        paddingTop: '8px',
    },
    msgErrorText:{
        color:"red",
        cursor:"pointer",
    },
    resetPasswordMsgSuccess:{
        backgroundColor:"#5ce54b",
        borderRadius:"3px",
        padding:"10px"
    }
}));

const isValidForm=(url,apiKey)=>{
    return !!(url && apiKey)
}

const DialogUpdateUrlAndApiKey=(props)=>{

    const appContext=useContext(AppContext);
    const appSnackBar=useContext(SnackBarContext);

    const classes = useStyles();
    const {t} = useTranslation();

    const [url,setUrl] = React.useState(appContext.enterprise.url);
    const [apiKey,setApiKey]= React.useState('');
    const [serviceEnterprise]= React.useState(new ServiceEnterprise());
    const [loading,setLoading] = React.useState(false);
    const [msgError,setMsgError] = React.useState('');

    const handleClose = () => {
        if (typeof props.onCancel === "function"){
            props.onCancel();
        }
    };

    const handleUpdate = ()=>{
        setLoading(true);
        stateConnection(url,apiKey)
            .then(()=>{
                setLoading(false);
                const newEnterprise={
                    ...appContext.enterprise,
                    url:url,
                    apiKey:apiKey
                };

                serviceEnterprise.update(newEnterprise).then((ent)=>{
                    appSnackBar.success(capitalizeFirstLetter(t('comee.admin.saveSuccess')))
                    if (typeof props.onUpdate === "function"){
                        props.onUpdate(ent);
                    }
                },()=>{
                    appSnackBar.error(capitalizeFirstLetter(t('comee.admin.saveFailed')))
                });
            })
            .catch(()=>{
                setLoading(false);
                setMsgError(capitalizeFirstLetter(t('comee.dialogTestApp.stateConnectionError')));
            })
    }

    const onKeyPress = (e) =>{
        console.log(e.key);
        if (e.key === 'Enter') { //Enter
            if (isValidForm(url,apiKey)){
                handleUpdate();
            }
        }else{
            if(e.keyCode === 27) { //Escape
                handleClose();
            }
        }
    }

    return (
        <div>
            <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle>
                    <Typography component={"p"} variant={"span"} className={classes.title}>
                        {
                            capitalizeFirstLetter(t('comee.dialogUpdateUrlAndApiKey.title'))
                        }
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    {
                        loading ? <Loader color={"primary"}/> :
                            <React.Fragment>
                                <Typography>{capitalizeFirstLetter(t('comee.dialogUpdateUrlAndApiKey.msg1'))}</Typography>
                                <Typography>{capitalizeFirstLetter(t('comee.dialogUpdateUrlAndApiKey.msg2'))}</Typography>
                                <Typography>{capitalizeFirstLetter(t('comee.dialogUpdateUrlAndApiKey.msg3'))}</Typography>
                                <TextField
                                    style={{marginTop:"30px"}}
                                    autoFocus
                                    required
                                    margin="dense"
                                    onChange={(e)=>setUrl(e.target.value)}
                                    label= {capitalizeFirstLetter(t('comee.dialogUpdateUrlAndApiKey.url'))}
                                    value={url}
                                    type="url"
                                    fullWidth
                                    onKeyPress={onKeyPress}
                                />
                                <TextField
                                    style={{marginTop:"30px"}}
                                    autoFocus
                                    required
                                    margin="dense"
                                    onChange={(e)=>setApiKey(e.target.value)}
                                    label= {capitalizeFirstLetter(t('comee.dialogUpdateUrlAndApiKey.apiKey'))}
                                    value={apiKey}
                                    type="password"
                                    fullWidth
                                    onKeyPress={onKeyPress}
                                />
                                {
                                    msgError && (
                                        <div className={classes.msgErrorArea}>
                                            <span className={classes.msgErrorText}>{msgError}</span>
                                        </div>
                                    )
                                }
                            </React.Fragment>
                    }

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}
                            color="primary"
                    >{capitalizeFirstLetter(t('comee.bt.cancel'))}
                    </Button>
                    <Button onClick={handleUpdate}
                            color="primary"
                            disabled={!isValidForm(url,apiKey)}
                    >
                        {capitalizeFirstLetter(t('comee.bt.apply'))}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

DialogUpdateUrlAndApiKey.propTypes = {
    onCancel:PropTypes.func.isRequired,
    onUpdate:PropTypes.func.isRequired
}

export default DialogUpdateUrlAndApiKey;
