import {initializeApp} from 'firebase/app';
import {getAuth,connectAuthEmulator} from 'firebase/auth';
import {initializeAppCheck, ReCaptchaV3Provider} from 'firebase/app-check';
import {getFirestore,connectFirestoreEmulator} from 'firebase/firestore';
import {getStorage,connectStorageEmulator} from 'firebase/storage';
import {getFunctions,connectFunctionsEmulator} from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyB94qlz-587dYFMsWn7VbrYVai1a6Nrc_U",
  authDomain: "comee-request-editor.firebaseapp.com",
  projectId: "comee-request-editor",
  storageBucket: "comee-request-editor.appspot.com",
  messagingSenderId: "603497707825",
  appId: "1:603497707825:web:31c0b929943467e0c05810"
};

// Initialize Firebase
export const firebaseApp=initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const firestore = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
export const functions = getFunctions(firebaseApp);


if (window.location.hostname === "localhost") {
    connectAuthEmulator(auth,"http://localhost:9099");
    connectFirestoreEmulator(firestore,"localhost", 8080);
    connectStorageEmulator(storage,"localhost",9199);
    connectFunctionsEmulator(functions, "localhost", 6001);
}else{
    /**
     * Mise en place de la sécurité dans le mode
     * Remarque, ce mode est bypass dans le mode émulateur (développement local)
     */
    initializeAppCheck(firebaseApp, {
        provider: new ReCaptchaV3Provider('6LfmmqQeAAAAAN0exTe3g6P1OlaYdeA9CX3DimA2'),
        // Optional argument. If true, the SDK automatically refreshes App Check
        // tokens as needed.
        isTokenAutoRefreshEnabled: true
    });
}

