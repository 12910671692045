import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, Box} from "@mui/material";
import {Button} from "@mui/material";
import {capitalizeFirstLetter} from "../../common/tools";
import {makeStyles} from "@mui/styles";
import {GetIcon, TypeIcon} from "../utils/Icones";

const useStyles = makeStyles((theme) => ({
    dialog: {
        bottom:"inherit",
        position: 'absolute',
        //top: 50
    },
    root: {
        display:"flex",
        flexDirection:"column",
        width:'100%',
        maxWidth:'100%',
        height: '100%',
        maxHeight: '100%',
        overflow:"hidden",
        borderRadius:"0",
        boxShadow:"none",
    },
    titleBar:{
        //backgroundColor: theme.palette.primary.main,
        color:theme.palette.primary.main,
        display:'flex',
        flexDirection:'row',
        padding: '12px 15px 5px',
    },
    titleBarType:{
        '&>svg':{
            color:theme.status.warn,
            fontSize:'2rem',
        }
    },
    titleBarText:{
        flex:'1',
        paddingLeft:'18px',
    },
    msg2:{
        marginTop:"20px",
    }
}));

const DialogConfirmCloseWithoutSave=(props)=>{

    const {t} = useTranslation();
    const classes=useStyles();

    const handleClick = (action)=>{
        switch (action){
            case "close":
                if (typeof props.onClose === "function"){
                    props.onClose();
                }
                break;
            case "cancel":
                if (typeof props.onCancel === "function"){
                    props.onCancel();
                }
                break;
            default: throw new Error("action not defined")
        }
    }

    return (
        <Dialog open={true}
                onClose={()=>handleClick("cancel")}
                fullWidth
                maxWidth={"md"}>
            <DialogTitle>
                <Box className={classes.titleBar}>
                    <Box className={classes.titleBarType}>{GetIcon(TypeIcon.WARNING)}</Box>
                    <Box className={classes.titleBarText}>{props.title}</Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.msg1}
                </DialogContentText>
                {
                    props.msg2 && (
                        <DialogContentText className={classes.msg2}>
                            {props.msg2}
                        </DialogContentText>
                    )
                }
                {
                    props.msg3 && (
                        <DialogContentText className={classes.msg3}>
                            {props.msg3}
                        </DialogContentText>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>handleClick("cancel")} color="primary">
                    {capitalizeFirstLetter(t('comee.bt.cancel'))}
                </Button>
                <Button onClick={()=>handleClick("close")} color="primary">
                    {capitalizeFirstLetter(t('comee.bt.close'))}
                </Button>
            </DialogActions>
        </Dialog>
    );
}


DialogConfirmCloseWithoutSave.propTypes = {
    onClose:PropTypes.func.isRequired,
    onCancel:PropTypes.func.isRequired,
    title:PropTypes.string.isRequired,
    msg1:PropTypes.string.isRequired,
    msg2:PropTypes.string,
    msg3:PropTypes.string,
};

export default DialogConfirmCloseWithoutSave;
