import React from "react";
//import {useTranslation} from "react-i18next";


//import format from "date-fns/format";
import frLocale from "date-fns/locale/fr";
import enLocale from "date-fns/locale/en-US";
//import DateFnsUtils from "@date-io/date-fns";

export const localeMap = {
    en: enLocale,
    fr: frLocale,
};
/*

class FrLocalizedUtils extends DateFnsUtils {
    getDatePickerHeaderText(date) {
        return format(date, "d MMM yyyy", { locale: this.locale });
    }
}

export const localeUtilsMap = {
    en: DateFnsUtils,
    fr: FrLocalizedUtils,
};

export const localeFormatMap = {
    en: "MMMM d, yyyy",
    fr: "d MMM yyyy",
};
*/
export const useDate = (isHour12,localeInput) => {
    //const {t} = useTranslation();
    const locale = localeInput ? localeInput : 'en';
    const [today, setDate] = React.useState(new Date()); // Save the current date to be able to trigger an update

    React.useEffect(() => {
        const timer = setInterval(() => { // Creates an interval which will update the current data every minute
            // This will trigger a rerender every component that uses the useDate hook.
            setDate(new Date());
        }, 60 * 1000);
        return () => {
            clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
        }
    }, []);

    const day = today.toLocaleDateString(locale, { weekday: 'long' });
    const date = `${day}, ${today.getDate()} ${today.toLocaleDateString(locale, { month: 'long' })}\n\n`;

    const hour = today.getHours();
    const wish = `Good ${(hour < 12 && 'Morning') || (hour < 17 && 'Afternoon') || 'Evening'}, `;

    const time = today.toLocaleTimeString(locale, { hour: 'numeric', hour12: isHour12, minute: 'numeric' });

    return {
        date,
        time,
        wish,
    };
};
