
import {makeStyles} from "@mui/styles";
import {Box, Typography } from "@mui/material";
import {GetIcon, TypeIcon} from "../components/utils/Icones";
import {capitalizeFirstLetter} from "../common/tools";
import PropTypes from "prop-types";


const useStyles = makeStyles((theme) => ({
    root: {
        margin:'auto',
        display: 'flex',
        height: '100%',
        width: '100%',
        textAlign: 'center',
        flex: '1',
    },
    card:{
        boxShadow:'none',
        backgroundColor:'transparent',
        margin: 'auto',
        width: '100%',
    },
    cover:{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        height: '200px',
        fontSize: '5rem',
        color: '#e23f3f',

    },
    text:{
        color:theme.palette.primary.A400,
    },

}));

const ErrorArea=(props)=>{
    const classes=useStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.card}>
                {
                    GetIcon(TypeIcon.ERROR,classes.cover)
                }
                {
                    props.label && (
                        <Typography component='p' className={classes.text}>{capitalizeFirstLetter(props.label)}</Typography>
                    )
                }
            </Box>
        </Box>
    )
}

ErrorArea.propTypes = {
    label:PropTypes.string,
};

export default ErrorArea;
