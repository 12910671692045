import {ServiceFirebase} from "./ServiceFireBase";
import {getFunctions, httpsCallable} from "firebase/functions";
import {getAuth,sendPasswordResetEmail} from "firebase/auth";

const ACCOUNTS_COLLECTION="accounts"

export class ServiceAccounts extends ServiceFirebase{
    constructor() {
        super([ACCOUNTS_COLLECTION])
    }

    resetPassword=(email)=>{
        return new Promise((resolve, reject) => {

            const auth = getAuth();
            auth.region = "europe-west3";
            sendPasswordResetEmail(auth,email).then((result)=>{
                console.log(result);
                resolve();
            },(error)=>{
                error && console.log(error);
                reject(error);
            })
        });

    }

    createContext=(email,passwd,url,apiKey)=>{
        return new Promise((resolve, reject) => {

            const functions = getFunctions();
            functions.region = "europe-west3";
            const createNewUser = httpsCallable(functions, 'createContext');
            const data = {
                "email": email,
                "passwd": passwd,
                "url": url,
                "apiKey":apiKey
            };

            createNewUser(data)
                .then((response) => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject();
                    }
                }).catch((error) => {
                console.log(error);
                reject(error);
            });
        });
    }
}

