import {ServiceFirebase} from "./ServiceFireBase";
import {ENTERPRISES_COLLECTION} from "./ServiceEnterprise";

const TEMPLATE_GROUP_COLLECTION="templateDataFile"

export class ServiceTemplateDataFile extends ServiceFirebase{

    constructor(enterpriseId) {
        super([
            ENTERPRISES_COLLECTION,
            enterpriseId,
            TEMPLATE_GROUP_COLLECTION
        ]);
    }
}
