import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField, Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {capitalizeFirstLetter, isValidEmailAddress, isValidPassword} from "../../common/tools";
import PropTypes from "prop-types";
import { makeStyles } from '@mui/styles'
import {ServiceAccounts} from "../../services/ServiceAccounts";
import Loader from "../shared/Loader";
import {stateConnection} from "../../services/ServiceTest";
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme) => ({
    title:{
        color:theme.palette.primary.main,
    },
    msgErrorArea:{
        width:'100%',
        textAlign:"right",
        padding: '8px 24px',
    },
    msgErrorText:{
        color:"red",
        cursor:"pointer",
    },
    resetPasswordMsgSuccess:{
        backgroundColor:"#5ce54b",
        borderRadius:"3px",
        padding:"10px"
    }
}));

const DialogResetPassword=(props)=>{

    const classes = useStyles();
    const {t} = useTranslation();

    const [email,setEmail] = React.useState('');
    const [reset,setReset]= React.useState(false);

    const handleClose = () => {
        if (typeof props.onCancel === "function"){
            props.onCancel();
        }
    };

    const handleContinue = ()=>{
        const serviceAccounts=new ServiceAccounts();
        serviceAccounts.resetPassword(email).then((result)=>{
            setReset(true);
        },(e)=>{
            setReset(false);
            e && console.error(e);
        });
    }

    const onKeyPress = (e) =>{
        console.log(e.key);
        if (e.key === 'Enter') { //Enter
            if (reset === false && isValidEmailAddress(email)){
                handleContinue();
            }
        }else{
            if(e.keyCode === 27) { //Escape
                handleClose();
            }
        }
    }
    return (
        <div>
            <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle>
                    <Typography component={"p"} variant={"span"} className={classes.title}>
                        {
                             capitalizeFirstLetter(t('comee.login.resetPasswordTitle'))
                        }
                    </Typography>
                   </DialogTitle>
                    <DialogContent>
                        <React.Fragment>
                            <Typography>{capitalizeFirstLetter(t('comee.login.resetPasswordMsg'))}</Typography>
                            <TextField
                                style={{marginTop:"30px"}}
                                autoFocus
                                required
                                margin="dense"
                                onChange={(e)=>setEmail(e.target.value)}
                                label= {capitalizeFirstLetter(t('comee.dialogTestApp.email'))}
                                value={email}
                                type="email"
                                fullWidth
                                onKeyPress={onKeyPress}
                            />
                            {
                                reset && (
                                    <Box className={classes.resetPasswordMsgSuccess}>
                                        <Typography>
                                            {
                                                capitalizeFirstLetter(t('comee.login.resetPasswordMsgSuccess'))
                                            }
                                        </Typography>
                                    </Box>

                                )
                            }
                        </React.Fragment>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}
                                color="primary"
                        >
                            {
                                reset ?
                                capitalizeFirstLetter(t('comee.bt.close'))
                                    :
                                capitalizeFirstLetter(t('comee.bt.cancel'))
                            }
                        </Button>
                        {
                            reset === false && (
                                <Button onClick={handleContinue}
                                        color="primary"
                                        disabled={!isValidEmailAddress(email)}
                                >
                                    {capitalizeFirstLetter(t('comee.bt.sendRequest'))}
                                </Button>
                            )
                        }

                    </DialogActions>
            </Dialog>
        </div>
    );
}

DialogResetPassword.propTypes = {
    onCancel:PropTypes.func.isRequired,
    onReset:PropTypes.func.isRequired
}

export default DialogResetPassword;
