import React, {useContext, useEffect} from "react";
import {SnackBarContext} from "../shared/MessageSnackBar";
import CodeMirror from '@uiw/react-codemirror';
import { xml } from '@codemirror/lang-xml';
import PropTypes from "prop-types";
import _ from "lodash";
import "../../common/ReactTags.scss"
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField, Typography
} from "@mui/material";
import {capitalizeFirstLetter} from "../../common/tools";
import {useTranslation} from "react-i18next";
import Loader from "../shared/Loader";
import ErrorArea from "../../common/ErrorArea";
import {dialogTemplateStyles} from "../../assets/jss/dialogTemplate";
import {ServiceTemplateDataFile} from "../../services/ServiceTemplateDataFile";
import base64 from "base-64";
import utf8 from "utf8";

const generateTemplateEmpty=()=>{
    return {
        name:"",
        buffer:""
    }
}

const checkForm=(template)=> {
    if (template) {
        return true;
    }
    return false;
};

const DialogTemplateDataFile=(props)=>{

    const appSnackBar = useContext(SnackBarContext);

    //chargement des styles
    //const classes = useStyles();
    const classes = dialogTemplateStyles();

    //chargement i18n
    const {t} = useTranslation();

    const [loading,setLoading]= React.useState(true)
    const [loadFailed,setLoadFailed]= React.useState(false)
    const [template,setTemplate]=React.useState(props.template ? _.cloneDeep(props.template) : null);
    const [displayError,setDisplayError]= React.useState(false)

    //service templateCommunication
    const [serviceTemplate]= React.useState(new ServiceTemplateDataFile(props.enterpriseId))

    //initialisation
    useEffect(() => {

        //Si le template n'est pas renseigné
        if (!template){

            //on regarde si l'identifiant est renseigné
            //Si oui => On va chercher sur le serveur le template
            if (props.id){
                serviceTemplate.document(props.id).then(doc=>{
                        setTemplate(doc);
                        setLoading(false);

                    },()=>{
                        setLoading(false);
                        setLoadFailed(true);
                    }
                )
            }else{
                setTemplate(generateTemplateEmpty())
                setLoading(false);
            }
        }else{
            setLoading(false);
        }

    },[])

    //flag si nouveau contact
    const newTemplate = props.id ? false : true;

    //evenement sur la fermeture de la fenetre
    const handleClose = ()=>{
        if (typeof props.onCancel === "function") {
            props.onCancel();
        }
    }

    //evenement sur la sauvegarde du contact
    const handleSave = ()=>{

        if (checkForm(template)){
            //normalisation de l'objet
            const tcTmp={
                ...template,
                org:props.organization.code
            };

            //sauvegarde de l'objet
            serviceTemplate.update(tcTmp).then( tc =>{

                //notification
                appSnackBar.success(capitalizeFirstLetter(t('comee.repository.dataFile.successSave')))

                //fermeture de la fenêtre
                handleClose();

            },(error)=>{
                console.error("Error save document: ", error);

                //notification
                appSnackBar.error(capitalizeFirstLetter(t('comee.repository.dataFile.errorSave')))
            })

        }else{
            appSnackBar.warning(capitalizeFirstLetter(t('comee.repository.dataFile.errorCheck')))
            setDisplayError(true);
        }
    }

    const getContent=()=>{
        if (loading){
            return (
                <Loader color={"primary"}/>
            )
        }

        if (loadFailed ){
            return (
                <ErrorArea label={capitalizeFirstLetter(t('comee.repository.dataFile.loading'))} />
            )
        }

        return(
            <Box>
                <div className={classes.itemArea}>
                    <Box>
                        <TextField
                            label={capitalizeFirstLetter(t('comee.repository.dataFile.name'))}
                            type="text"
                            value={template.name}
                            onChange={(e)=>setTemplate({
                                ...template,
                                name:e.target.value
                            })}
                            fullWidth
                            variant="outlined"
                            error={displayError && template.name.isEmpty()}
                            className={classes.itemRow}
                        />
                    </Box>
                </div>
                <div className={classes.itemArea}>
                    <Box>
                        <Typography variant={"h6"} component={"h6"} className={classes.title2}>
                            {capitalizeFirstLetter(t('comee.repository.dataFile.bufferTitle'))}
                        </Typography>
                        <CodeMirror
                            value={template.buffer ? utf8.decode(base64.decode(template.buffer)) : ""}
                            extensions={[xml({  })]}
                            onChange={(value, viewUpdate) => {
                                setTemplate({
                                    ...template,
                                    buffer:base64.encode(utf8.encode(value))
                                })
                            }}
                        />
                    </Box>
                </div>
            </Box>
        )
    }
    return (
        <Dialog
                className={classes.root}
                open={props.open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={"xl"}
                sx={{height:"100%"}}
        >

            <DialogTitle className={classes.contentTitle}>
                {newTemplate ? capitalizeFirstLetter(t('comee.repository.dataFile.create')) :  capitalizeFirstLetter(t('comee.repository.dataFile.edit'))}
            </DialogTitle>
            <DialogContent>
                {
                    getContent()
                }
            </DialogContent>

            <DialogActions>
                <Box className={classes.contentAction}>
                    <Box flex={"1"}></Box>
                    <Button variant="contained" disableElevation onClick={handleClose} className={classes.btAction}>
                        {capitalizeFirstLetter(t('comee.bt.cancel'))}
                    </Button>
                    {
                        loading === false && loadFailed === false && (
                            <Button onClick={handleSave}
                                    variant="contained"
                                    disableElevation
                                    color="primary"
                                    style={{marginLeft:"16px"}}
                                    className={classes.btAction}
                                //disabled={!(isValidEmailAddress(email) && isValidPassword(passwd))}
                            >
                                {capitalizeFirstLetter(t('comee.bt.save'))}
                            </Button>
                        )
                    }
                </Box>
            </DialogActions>
        </Dialog>
    )
}

DialogTemplateDataFile.propTypes = {
    organization:PropTypes.object.isRequired,
    id:PropTypes.string,
    enterpriseId: PropTypes.string.isRequired,
    open:PropTypes.bool,
    template:PropTypes.object,
    onCancel:PropTypes.func.isRequired,
}
export default DialogTemplateDataFile;
