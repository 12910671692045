
import {ServiceFirebase} from "./ServiceFireBase";
import {ENTERPRISES_COLLECTION} from "./ServiceEnterprise";

const TEMPLATE_COMMUNICATION_COLLECTION="templateCommunication"

export class ServiceTemplateCommunication extends ServiceFirebase{

    constructor(enterpriseId) {
        super([
                ENTERPRISES_COLLECTION,
                enterpriseId,
                TEMPLATE_COMMUNICATION_COLLECTION
            ]);
    }
}
