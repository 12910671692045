
export function capitalizeFirstLetter([ first, ...rest ], locale = navigator.language) {
    return [ first.toLocaleUpperCase(locale), ...rest ].join('');
}

export function isValidEmailAddress (emailAddress) {

    // eslint-disable-next-line
    const pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return pattern.test(emailAddress);
}

export function isValidPassword (password) {
    if (password) {
        return true;
    }
    return false;
}
export function isValidTelephoneNumber(number) {
    return (typeof number !== "undefined") && number !== null && number !== '' ? /^(0|\+33)[1-9]([-. ]?[0-9]{2}){4}$/.test(number.trim()) : false;
}

export function formatUser(firstname, name) {
    if (firstname) {
        firstname = capitalizeFirstLetter(firstname.trim());
    }
    if (name) {
        name = name.trim().toUpperCase();
    }
    return firstname + ' ' + name;
}

export function formatBytes(bytes,decimals){
    const thresh = 1024;
    const dm = decimals || 0;

    if(Math.abs(bytes) < thresh) {
        return bytes + ' octets';
    }
    const units =  ['Ko','Mo','Go','To','Po','Eo','Zo','Yo'];
    let u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while(Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(dm)+' '+units[u];
}


export function timestampToDate(timestamp, withTime) {

    if (timestamp === null) {
        return '';
    }

    if (typeof timestamp === 'undefined') {
        return '';
    }

    let d = new Date(parseInt(timestamp) || Date.now()),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hours = d.getHours(),
        minutes = d.getMinutes();

    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }

    if (withTime) {

        if (hours < 10) {
            hours = '0' + hours;
        }
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        return [day, month, year].join('/') + ' ' + [hours, minutes].join(':');
    } else {
        return [day, month, year].join('/');
    }
}
