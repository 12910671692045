import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import {FormControl, IconButton, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {GetIcon, TypeIcon} from "../utils/Icones";
import {capitalizeFirstLetter} from "../../common/tools";
import React from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        display:"flex",
        flexDirection:"row",
        width:"100%",
    },
    iconArea:{
        width:"80px",
        margin:"auto",
        textAlign:"center",
    },
    iconDocument:{
        color:theme.status.success,
        transform: "rotate(180deg)"
    },
    iconDelete:{
        color:theme.status.danger
    },
    textArea:{
        margin:"auto",
        flex:"1",
    },
    dataFileArea:{
        margin:"auto",
        flex:"1",
    }

}));

const CardTemplateDocument=(props)=>{
//chargement des styles
    const classes = useStyles();

    //chargement i18n
    const {t} = useTranslation();
    return (
        <Box className={classes.root}>
            <Box className={classes.iconArea}>
                {
                    GetIcon(TypeIcon.ARROW_BACK,classes.iconDocument)
                }
            </Box>
            <Typography className={classes.textArea}>
                {
                    props.templatesDocument.find(td=>td.id===props.templateDocument.id).name
                }
            </Typography>
            <Box className={classes.dataFileArea}>
                <FormControl sx={{width: "100%" }} size={"small"}>
                    <InputLabel id="demo-simple-select-dataFile-label">{capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.templateDocument.selectDataFile'))}</InputLabel>
                    <Select
                        fullWidth
                        labelId="demo-simple-select-dataFile"
                        id="demo-simple-select-dataFile"
                        value={props.templateDocument.buffer}
                        label={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.templateDocument.selectDataFile'))}
                        onChange={(event) => {
                            if (typeof props.onChange === "function"){
                                props.onChange({
                                    ...props.templateDocument,
                                    buffer:event.target.value
                                })
                            }
                        }}
                    >
                        <MenuItem key={0} value="">
                            <em>{"None"}</em>
                        </MenuItem>
                        {
                            props.templatesDataFile.map((dataFile) => (
                                <MenuItem key={dataFile.id} value={dataFile.id}>{dataFile.name}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Box>
            <Box  className={classes.iconArea}>
                <IconButton
                    edge="start" color="inherit"
                    className={clsx(classes.button)}
                    onClick={() => {
                        if (typeof props.onDelete === "function") {
                            props.onDelete()
                        }
                    }}
                >
                    {GetIcon(TypeIcon.DELETE, classes.iconDelete)}
                </IconButton>
            </Box>
        </Box>
    )
}


CardTemplateDocument.propTypes = {

    templateDocument:PropTypes.object.isRequired,
    templatesDocument:PropTypes.array.isRequired,
    templatesDataFile:PropTypes.array.isRequired,
    onDelete:PropTypes.func.isRequired,
    onChange:PropTypes.func.isRequired,

}

export default CardTemplateDocument;
