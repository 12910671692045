import { getFunctions, httpsCallable } from "firebase/functions";

export const createCommunicationJSON=(url,dataRequest)=>{
    return new Promise((resolve, reject) => {

        const functions=getFunctions();
        functions.region="europe-west3";
        const CreateCommunication = httpsCallable(functions, 'createCommunication');
        const data={
            "url":url,
            "dataRequest":dataRequest
        }
        CreateCommunication(data)
            .then((response) => {
                if (response.data.returnCode === 0){
                    resolve(response.data);
                }else{
                    reject(response.data);
                }
            }).catch((error) => {
            console.log(error);
            reject(error);
        });
    });
}
