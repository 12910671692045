import React from "react";
import {useTranslation} from "react-i18next";
import {
    Box, Checkbox,
    Collapse,
    FormControl, FormControlLabel,
    FormGroup,
    InputLabel,
    ListItem,
    ListItemText,
    MenuItem,
    Select, TextField
} from "@mui/material";
import {GetIcon, TypeIcon} from "../utils/Icones";
import {capitalizeFirstLetter} from "../../common/tools";
import PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    title:{
        marginTop:"20px",
        color: theme.palette.primary.main,
        backgroundColor:'rgba(150, 150, 150, 0.13)',
        fontSize:14,
    },
    itemArea:{
        flex:1,
        marginTop:theme.spacing(2),
        marginLeft:theme.spacing(2),
        marginRight:theme.spacing(2),

        '&:first-child':{
            //marginLeft:0,
        },

        '&:last-child':{
            //marginRight:0,
        }
    },
    iconExpandLessRotate: {
        transform: "rotate(90deg)"
    }
}));


const AreaDeliveryCommon=(props)=>{

    const [expand, setExpand] = React.useState(false);

    //chargement des styles
    const classes = useStyles();

    //chargement i18n
    const {t} = useTranslation();

    if (props.delivery === null){
        console.log("delivery est null")
        return (
            <Box>Je suis null</Box>
        )
    }

    return (
        <Box>
            <ListItem key={"myCode"}
                      button
                      onClick={()=>setExpand(!expand)}
                      className={classes.title}
            >
                <ListItemText primary={props.title}/>
                {expand ? GetIcon(TypeIcon.EXPANDMORE) : GetIcon(TypeIcon.EXPANDLESS,classes.iconExpandLessRotate) }
            </ListItem>
            <Collapse in={expand} timeout={300} >
                <div className={classes.itemArea}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.mode.title'))}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.delivery.mode}
                            label={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.mode.title'))}
                            onChange={(e)=>{
                                if (typeof props.onchange === "function"){
                                    console.log(e.target.value);
                                    props.onchange({
                                        ...props.delivery,
                                        mode:e.target.value
                                    })
                                }
                            }}
                        >
                            <MenuItem value={"ENABLED"}>{capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.mode.enabled'))}</MenuItem>
                            <MenuItem value={"DISABLED"}>{capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.mode.disabled'))}</MenuItem>
                            <MenuItem value={"OBLIGED"}>{capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.mode.obliged'))}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                {
                    props.delivery.mode !== "DISABLED" && (
                        <Box>
                            <div className={classes.itemArea}>
                                <FormGroup>
                                    <FormControlLabel defaultChecked={props.delivery.enabled}
                                                      control={<Checkbox
                                                          defaultChecked={props.delivery.enabled}
                                                          onClick={()=>{
                                                              if (typeof props.onchange === "function"){
                                                                  props.onchange({
                                                                      ...props.delivery,
                                                                      enabled:!props.delivery.enabled
                                                                  })
                                                              }
                                                          }}/>}
                                                      label={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.enabled'))}

                                    />
                                </FormGroup>
                            </div>
                            <div className={classes.itemArea}>
                                <TextField
                                    label={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.deliveryName'))}
                                    type="text"
                                    value={props.delivery.deliveryName}
                                    onChange={(e)=> {
                                        if (typeof props.onchange === "function"){
                                            props.onchange({
                                                ...props.delivery,
                                                deliveryName:e.target.value
                                            })
                                        }
                                    }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </div>
                            {props.children}
                            <div className={classes.itemArea}>
                                <TextField
                                    label={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.delay'))}
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={props.delivery.delay}
                                    onChange={(e)=> {
                                        if (typeof props.onchange === "function"){
                                            props.onchange({
                                                ...props.delivery,
                                                delay:e.target.value
                                            })
                                        }
                                    }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </div>
                        </Box>
                    )
                }
            </Collapse>

        </Box>
    )
}
AreaDeliveryCommon.propTypes={
    title:PropTypes.string.isRequired,
    delivery:PropTypes.object.isRequired,
    onchange:PropTypes.func.isRequired
}

export default AreaDeliveryCommon;
