// core components


import {makeStyles} from "@mui/styles";

export const dialogTemplateStyles = makeStyles((theme) => ({
    root:{
        bottom:"inherit",
    },
    title:{
        borderBottom:'1px solid rgba(0, 0, 0, 0.23)',
    },
    title2:{
        marginTop:"20px",
        color: theme.palette.primary.main,
        fontSize:18,
    },
    contentTitle:{
        color: theme.palette.primary.main,
    },
    contentAction:{
        marginBottom:theme.spacing(2),
    },
    row:{
        marginTop:theme.spacing(2),
    },
    btCloseTop:{
        cursor:"pointer",
        margin: "auto",
    },
    btGroup:{
        padding:"16px 20px",
        '&:hover':{
            color:"red",
            backgroundColor:theme.palette.primary.main,
        }
    },
    btGroupSelect:{
        color:"white",
        backgroundColor:theme.palette.primary.main,
    },
    itemArea:{
        flex:1,
        marginTop:theme.spacing(2),
        marginLeft:theme.spacing(2),
        marginRight:theme.spacing(2),

        '&:first-child':{
            //marginLeft:0,
        },

        '&:last-child':{
            //marginRight:0,
            marginBottom:theme.spacing(2),
        }
    },
    add:{
        color:theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        cursor:"pointer",
        paddingLeft:'20px',
        marginTop:theme.spacing(2),
    },
    itemRow:{
        marginTop:theme.spacing(3),
    },
    formTypeControlVariable:{
        marginTop:theme.spacing(3),
        paddingTop:theme.spacing(2),
    }
}));
