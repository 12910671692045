import { getFunctions, httpsCallable } from "firebase/functions";

export const stateConnection=(url,apiKey)=>{
    return new Promise((resolve, reject) => {

        const functions=getFunctions();
        functions.region="europe-west3";
        const StateConnection = httpsCallable(functions, 'stateConnection');
        const data={
            "url":url,
            "apiKey":apiKey
        }
        StateConnection(data)
            .then((response) => {
                if (response.data === true){
                    resolve();
                }else{
                    reject();
                }
            }).catch((error) => {
            console.log(error);
            reject(error);
        });
    });
}
