import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    setDoc,
    onSnapshot,
    query,
    where,
    limit,
    limitToLast,
    startAt,
    orderBy,
    startAfter,
    endAt,
    endBefore,
    deleteDoc
} from "firebase/firestore";
import {firebaseApp} from "../firebase";

export const OperatorType = Object.freeze({
    EQUAL: "==",
    NOT_EQUAL: "!=",
    IN: "in",
    NOT_IN: "not-in",
    ARRAY_CONTAINS: "array-contains",
    ARRAY_CONTAINS_ANY: "array-contains-any",
    MORE_THAN: ">",
    SMALLER_THAN: "<",
    MORE_THAN_OR_EQUAL: ">=",
    SMALLER_THAN_OR_EQUAL: "<="
});

export const OrderByDirection = Object.freeze({
    DESCENDING: "desc",
    ASCENDING : "asc",
});

export class FirebaseConstraintsGenerator {

    where = [];
    limit = null;
    limitToLast = null;
    startAt = null;
    orderBy = [];
    startAfter = null;
    endAt = null;
    endBefore = null;

    addWhere = (key, operator, value) => {
        this.where.push({
            key: key,
            operator: operator,
            value: value
        });
    }

    getWhere = () => {
        return this.where;
    }

    clearWhere = () => {
        this.where = [];
    }

    setLimit = (value) => {
        this.limit = value;
    }

    getLimit = () => {
        return this.limit;
    }

    setLimitToLast = (value) => {
        this.limitToLast = value;
    }

    getLimitToLast = () => {
        return this.limitToLast;
    }

    setStartAt = (value) => {
        this.startAt = value;
    }

    getStartAt = () => {
        return this.startAt;
    }

    addOrderBy = (key,direction= OrderByDirection.DESCENDING) => {
        this.orderBy.push({
            key:key,
            direction:[OrderByDirection.ASCENDING,OrderByDirection.DESCENDING].indexOf(direction)>-1 ? direction : OrderByDirection.DESCENDING
        });
    }

    getOrderBy = () => {
        return this.orderBy;
    }

    clearOrderBy = () => {
        this.orderBy = [];
    }

    setStartAfter = (value) => {
        this.startAfter = value;
    }

    getStartAfter = () => {
        return this.startAfter;
    }

    setEndAt = (value) => {
        this.endAt = value;
    }

    getEndAt = () => {
        return this.endAt;
    }

    setEndBefore=(value)=>{
        this.endBefore=value;
    }

    getEndBefore=()=>{
        return this.endBefore;
    }

    get = () => {

        const constrains = [];

        // where
        if (this.getWhere() && Array.isArray(this.getWhere()) && this.getWhere().length>0){
            this.getWhere().forEach(w=>{
                constrains.push(where(w.key,w.operator,w.value))
            });
        }

        // limit
        if (this.getLimit()){
            constrains.push(limit(this.getLimit()));
        }

        // limitToLast
        if (this.getLimitToLast()){
            constrains.push(limitToLast(this.getLimitToLast()));
        }

        // startAt
        if (this.getStartAt()){
            constrains.push(startAt(this.getStartAt()));
        }

        // orderBy
        if (this.getOrderBy() && Array.isArray(this.getOrderBy()) && this.getOrderBy().length>0){
            this.getOrderBy().forEach(o=>constrains.push(orderBy(o.key,o.direction)));
        }

        // startAfter
        if (this.getStartAfter()){
            constrains.push(startAfter(this.getStartAfter()));
        }

        // startAfter
        if (this.getEndAt()){
            constrains.push(endAt(this.getEndAt()));
        }

        // endBefore
        if (this.getEndBefore()){
            constrains.push(endBefore(this.getEndBefore()));
        }

        return constrains;
    }
}

export class ServiceFirebase {
    _db = null;
    _path = ""
    _pathSegment = [];

    _unsubscribe = null;
    _refCol = null;

    constructor(pathSegment) {
        this._db = getFirestore(firebaseApp);

        if (pathSegment && Array.isArray(pathSegment) && pathSegment.length > 0) {
            this._path = pathSegment[0];
            pathSegment.splice(0, 1);
            if (pathSegment.length > 0) {
                this._pathSegment = pathSegment;
            }

            this._refCol = collection(this._db, this._path, this._pathSegment.join("/"));
        } else {
            throw new Error("pathSegment not defined")
        }
    }

    destructor() {
        if (typeof this._unsubscribe === "function") {
            this._unsubscribe();
        }
    }

    document(uid) {
        return new Promise((resolve, reject) => {
            const docRef = doc(this._refCol, uid);
            getDoc(docRef).then((docSnap) => {
                if (docSnap.exists) {
                    resolve({
                        ...docSnap.data(),
                        id: docSnap.id
                    });
                } else {
                    resolve(null);
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            }, reject);
        })
    }

    update(item, merge) {

        merge = merge === true;

        return new Promise((resolve, reject) => {

            if (item) {

                //Si l'id est renseigné alors il s'agit d'un document existant
                if (item.id) {

                    //copy la clé du document
                    const uid = item.id;

                    //suppression de la clé du document au niveau de l'objet
                    delete item.id;

                    //sauvegarde du document
                    const docRef = doc(this._refCol, uid);
                    setDoc(docRef, item, {merge: merge}).then(() => {
                        // on reposition l'id
                        item.id = uid;

                        //on résout la promesse
                        resolve(item);

                    }, reject)

                } else { //il s'agit d'un nouveau document
                    addDoc(this._refCol, item).then((docRef) => {
                        item.id = docRef.id;
                        resolve(item);
                    }, reject);
                }
            } else {
                reject();
            }
        })
    }

    delete(id) {
        return deleteDoc(doc(this._refCol, id));
    }

    _generateQuery = (refCol, firebaseConstraintsGenerator) => {

        if (firebaseConstraintsGenerator && typeof firebaseConstraintsGenerator.get === 'function') {
             return query(refCol, ...firebaseConstraintsGenerator.get())
        } else {
            return query(refCol);
        }

        return null;
    }

    list(queryConstraints = null) {
        return new Promise((resolve, reject) => {
            const q = this._generateQuery(this._refCol, queryConstraints);
            getDocs(q).then((querySnapshot) => {
                const docs = [];
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    docs.push({
                        ...doc.data(),
                        id: doc.id
                    })
                    resolve(docs);
                });

            }, reject);
        });
    }

    onSnapshot(syncSnapshotSuccess, syncSnapshotError, queryConstraints = []) {
        const refCol = collection(this._db, this._path, this._pathSegment.join("/"))

        const q = this._generateQuery(refCol, queryConstraints);

        this._unsubscribe = onSnapshot(q, (snap) => {
            syncSnapshotSuccess(snap.docs.map(doc => {
                return {...doc.data(), id: doc.id}
            }));
        }, syncSnapshotError);
    }
}


