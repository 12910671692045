
import {
    Box, Button, TextField
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import {makeStyles} from "@mui/styles";
import {GetIcon, TypeIcon} from "../utils/Icones";

const useStyles = makeStyles((theme) => ({
    root:{
        marginTop:10,
        display:"flex",
        flexDirection:"row",
    },
    name:{
        flex:"1",
        paddingRight:10,
    },
    value:{
        flex:"1",
        paddingLeft:10,
    },
    btArea:{
        display: "flex",
        minWidth: "150px",
        justifyContent: "left",
    },
    bt:{
        display: "flex",
    },
    iconAdd:{
        color:theme.palette.primary.main,
        fontSize:"1.8rem"
    },
    iconDelete:{
        color:"red",
        fontSize:"1.8rem"
    }
}));

const VariableLine =({name,value,labelName,labelValue,iconMore,onChange,onClickAdd,iconLess,onClickLess})=>{

    const classes=useStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.name}>
                <TextField
                    label={labelName}
                    type="text"
                    value={name}
                    fullWidth
                    variant="outlined"
                    onChange={(e)=>{
                        if (typeof onChange === "function"){
                            onChange(e.target.value,value)
                        }
                    }}
                />
            </Box>
            <Box className={classes.value}>
                <TextField
                    label={labelValue}
                    type="text"
                    value={value}
                    onChange={(e)=>{
                        if (typeof onChange === "function"){
                            onChange(name,e.target.value)
                        }
                    }}
                    fullWidth
                    variant="outlined"
                />
            </Box>
            <Box className={classes.btArea}>
                {
                    iconLess && (
                        <Box className={classes.bt}>
                            <Button
                                color={"primary"}
                                onClick={()=> {
                                    if (typeof onClickLess === "function") {
                                        onClickLess();
                                    }
                                }}
                                className={classes.bt}
                            >
                                {GetIcon(TypeIcon.DELETE,classes.iconAdd)}
                            </Button>
                        </Box>
                    )
                }
                {
                    iconMore && (
                        <Box className={classes.bt}>
                            <Button
                                color={"primary"}
                                onClick={()=> {
                                    if (typeof onClickAdd === "function") {
                                        onClickAdd();
                                    }
                                }}
                                className={classes.bt}
                            >
                                {GetIcon(TypeIcon.NEW,classes.iconDelete)}
                            </Button>
                        </Box>
                    )
                }
            </Box>
        </Box>
    )
}

VariableLine.propTypes={
    name:PropTypes.string.isRequired,
    value:PropTypes.string,
    labelName:PropTypes.string.isRequired,
    labelValue:PropTypes.string.isRequired,
    onChange:PropTypes.func.isRequired,
    iconMore:PropTypes.bool,
    onClickAdd:PropTypes.bool,
    iconLess:PropTypes.bool,
    onClickLess:PropTypes.bool
}

export default VariableLine;
