import React, {Suspense, useContext, useEffect, useRef} from 'react';

import { makeStyles } from '@mui/styles';
import {
    AppBar, Box,
    CssBaseline,
    Drawer, FormControl,
    IconButton, List,
    ListItem,
    ListItemIcon,
    ListItemText, MenuItem,
    Paper, Select,
    Toolbar, Typography
} from "@mui/material";
import Loader from "../shared/Loader";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {AppContext} from "../login/Login";
import {SnackBarContext} from "../shared/MessageSnackBar";
import {useTranslation} from "react-i18next";
import {TypeSection} from "./listSection";
import {sectionListForUser} from "./utilsDashboard";
import clsx from "clsx";
import {GetIcon, TypeIcon} from "../utils/Icones";
import {capitalizeFirstLetter} from "../../common/tools";
import {localeMap} from "./utilsDate";
import DateFnsUtils from "@date-io/date-fns";
import {ServiceOrganisation} from "../../services/ServiceOrganization";
import ErrorArea from "../../common/ErrorArea";
import DialogUpdateUrlAndApiKey from "./DialogUpdateUrlAndApiKey";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        maxHeight:'100vh',
        borderRadius:0,
        height:'100%',
        width:'100%',
        overflow:"hidden",
    },
    contentIconHome:{
        backgroundColor:'transparent',
        color:'initial',
        boxShadow:'none',
    },
    contentListSection:{
        marginTop:"64px"
    },

    sectionSelected:{
        backgroundColor:"rgba(45, 123, 169, 0.20)"
    },
    list: {
        width: 250,
    },
    toolbar: {
        paddingLeft : 20,
        paddingRight: 24,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,

        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,

        }),
    },
    menuButton: {
        marginRight: 36,
    },
    title: {
        flexGrow: 1,
        marginLeft:'10px',
    },
    name: {
        textAlign: 'right',
        marginRight:'30px',
    },
    time: {
        textAlign: 'right',
        marginRight:'10px',
    },
    container: {
        flexGrow: 1,
        height: 'calc(100vh - 66px)',
        overflow: 'hidden',
        marginTop: '66px',
        width:'100%'
    },
    organization:{
        color:theme.palette.secondary.main
    },
    label: {
        color: "#FFF",
        "&.Mui-focused": {
            color: "#FFF",
        },
    },
    select: {
        "&:after": {
            borderBottomColor: "#FFF",
        },
        "& .MuiSvgIcon-root": {
            color: "#FFF",
        },
        "& .MuiInputBase-input":{
            color: "#FFF",
        },
        " & fieldset":{
            borderColor:"#FFF",
            border:"none",
        }
    },
}));

const Dashboard=(props) => {
    //récupération du contexte
    const appContextTmp = useContext(AppContext);
    const appSnackBar = useContext(SnackBarContext);
    const classes = useStyles();
    const {t,i18n} = useTranslation();
    const [loading, setLoading] = React.useState(true);
    const [loadFailed, setLoadFailed] = React.useState(false);

    const [open, setOpen] = React.useState(false);
    const [section, setSection] = React.useState(TypeSection.HOME)
    const [appContext] = React.useState(appContextTmp);

    const [sectionList] = React.useState(sectionListForUser(appContextTmp));
    //const [currentDate] = React.useState(useDate(t('docprint.date.hour12') === "true",t('docprint.date.local')));

    const serviceOrganisation=React.useRef(new ServiceOrganisation(appContextTmp.enterprise));

    const [organization,setOrganization] = React.useState(null);
    const [organizationList,setOrganizationList] = React.useState([]);
    const [boxUpdateOrganization, setBoxUpdateOrganization] = React.useState(false);

    const listOrganisation=()=>{
        serviceOrganisation.current.list().then(list=>{
            if (list && Array.isArray(list) && list.length>0){
                setOrganizationList(list);
                setOrganization(list[0]);
                setLoading(false)
            }else{
                setBoxUpdateOrganization(true);
                setLoading(false)
            }
        }).catch(()=>{
            setLoadFailed(true);
        })
    }

    useEffect(() => {
        i18n.changeLanguage(appContextTmp.user.lang);

        listOrganisation();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (loading){
        return (
            <Paper className={classes.root} >
                <Loader color={"primary"} />
            </Paper>
        )
    }

    if (loadFailed){
        return (
            <ErrorArea label={capitalizeFirstLetter(t('comee.errorLoad'))} />
        )
    }

    if (boxUpdateOrganization) {
        return (
            <DialogUpdateUrlAndApiKey
                onCancel={()=>setBoxUpdateOrganization(false)}
                onUpdate={(enterprise)=>{
                    setLoading(true);
                    appContextTmp.enterprise=_.cloneDeep(enterprise);
                    serviceOrganisation.current=new ServiceOrganisation(appContextTmp.enterprise);
                    setBoxUpdateOrganization(false);
                    listOrganisation();
                }}
            />
        )
    }

    const handleDrawerOpen = () => {

        setOpen(!open);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleModify=(value)=>{

    }

    const handleClicklOnSection = (newSection) => {

        if (newSection !== section) {
            setSection(newSection)
        }
        setOpen(false);
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpen(open)
    };

    const sectionRenderDrawer = () => (
        <Box
            className={classes.list}
            role="presentation"
            onKeyDown={toggleDrawer('left', false)}
        >
            <AppBar position="absolute" className={clsx(classes.contentIconHome)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerClose}
                    >
                        {GetIcon(TypeIcon.DASHBOARD,classes.contentIconHome)}
                    </IconButton>
                </Toolbar>
            </AppBar>
            <List className={clsx(classes.contentListSection)}>
                {
                    sectionList.map((s)=>{
                        return (
                            <ListItem button key={s.key}
                                      className={clsx(section === s.key && classes.sectionSelected)}
                                      onClick={()=>handleClicklOnSection(s.key)}>
                                <ListItemIcon>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        aria-label="open drawer"
                                    >{s.icon}
                                </IconButton>
                                </ListItemIcon>
                                <ListItemText primary={capitalizeFirstLetter(t('comee.'+s.key+'.tabName'))} />
                            </ListItem>
                        )
                    })
                }
            </List>
        </Box>
    );

    const localDate=t('comee.date.local');

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[localDate]}>
            <Paper className={classes.root}>
                <CssBaseline/>
                <AppBar position="absolute" className={clsx(classes.appBar)}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                        >
                            {
                                GetIcon(TypeIcon.DASHBOARD)
                            }
                        </IconButton>
                        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            {capitalizeFirstLetter(t('comee.'+section+'.tabName'))}
                        </Typography>
                        <Box>
                            <FormControl size={"small"}>
                                {
                                    /*
                                        <InputLabel id="dashboard-select-organization" className={classes.label}>{t('comee.dashboard.organization')}</InputLabel>
                                     */
                                }
                                <Select
                                    className={classes.select}
                                    labelId="dashboard-select-organization"
                                    id="dashboard-select-organization-select"
                                    value={organization?.id}
                                    //label={t('comee.dashboard.organization')}
                                    onChange={(e)=>setOrganization(organizationList.find(org=>org.id===e.target.value))}
                                >
                                    {
                                        organizationList.map(org=><MenuItem key={org.id} value={org.id}>{org.name}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Drawer anchor={'left'} open={open} onClose={toggleDrawer('left', false)}>
                    {
                        sectionRenderDrawer()
                    }
                </Drawer>
                <main className={classes.container}>
                    {
                        sectionList.find(s=>s.key===section).content(appContext,appSnackBar,handleModify,appContext.user.enterprises[0],organization)
                    }
                </main>
            </Paper>
        </MuiPickersUtilsProvider>
    );
}

// here app catches the suspense from page in case translations are not yet loaded
export default function App(props) {
    return (
        <Suspense fallback={<Loader />}>
            <Dashboard {...props}/>
        </Suspense>
    );
}

