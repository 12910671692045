import React, {useContext, useEffect} from 'react';
import PropTypes from "prop-types";
import {AppContext} from "../login/Login";
import {SnackBarContext} from "../shared/MessageSnackBar";
import {generateTemplateCommunicationEmpty} from "../utils/templateCommunicationUtils";
import {
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel, List, ListItem,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Typography
} from "@mui/material";

import {capitalizeFirstLetter, isValidTelephoneNumber} from "../../common/tools";
import _ from "lodash";
import Loader from "../shared/Loader";
import ErrorArea from "../../common/ErrorArea";
import clsx from "clsx";
import {ServiceTemplateCommunication} from "../../services/ServiceTemplateCommunication";
import {ServiceTemplateUser} from "../../services/ServiceTemplateUser";
import {ServiceTemplateDocument} from "../../services/ServiceTemplateDocument";
import {ServiceTemplateSms} from "../../services/ServiceTemplateSms";
import {ServiceTemplateEmail} from "../../services/ServiceTemplateEmail";
import {GetIcon, TypeIcon} from "../utils/Icones";
import { WithContext as ReactTags } from 'react-tag-input';
import "../../common/ReactTags.scss"
import Variables from "./Variables";
import AreaDeliveryCommon from "./AreaDeliveryCommon";
import InputTags from "../utils/InputTags";
import {
    isValidAdresseEmailInputTas,
    transformeDisplayInputTagsToFormatEmail,
    transformeFormatEmailDoDisplayInputTags
} from "../utils/tools";
import PQueue from "p-queue";

import {ServiceTemplateDataFile} from "../../services/ServiceTemplateDataFile";
import {makeStyles} from "@mui/styles";
import CardTemplateDocument from "./CardTemplateDocument";
import {FirebaseConstraintsGenerator, OperatorType} from "../../services/ServiceFireBase";
import DialogConfirmCloseWithoutSave from "../shared/DialogConfirmCloseWithoutSave";

const {useTranslation} = require("react-i18next");

export const CONTACT_WIDTH_FORM=700;

const useStyles = makeStyles((theme) => ({
    dialog: {
        bottom:"inherit",
        position: 'absolute',
        //top: 50
    },
    root:{
        display:"flex",
        flexDirection:"column",
    },
    title:{
        borderBottom:'1px solid rgba(0, 0, 0, 0.23)',
    },
    title2:{
        marginTop:"20px",
        color: theme.palette.primary.main,
        fontSize:18,
    },
    title3:{
        color: theme.palette.primary.main,
        fontSize:16,
        marginRight:"10px",
        minWidth:"20px"
    },
    cc:{
        color: "#787878",
        fontSize:16,
        marginLeft:"10px",
        minWidth:'50px'
    },
    adressBoxLinkCcCci:{
        margin:"auto",
        color: "#787878",
        fontSize:16,
        marginLeft:"10px",
        display:'flex',
        cursor:'pointer',
        '&>p':{
            margin: "auto 0",
        },
    },
    red:{
        color: "red",
    },
    adressBoxLinkCcCciNotEmpty:{
        color: "red",
    },
    contentTitle:{
        color: theme.palette.primary.main,
    },
    contentTitleText:{
        fontSize:'1.5rem',
    },
    content:{
        maxWidth:CONTACT_WIDTH_FORM,
        margin:'auto',
    },
    typeComIcon:{
        marginRight:"10px",
    },
    section:{
        marginTop:theme.spacing(3),
        color:theme.palette.primary.dark,
    },
    sectionBox:{
        marginTop: '24px',
        marginBottom: '17px',
    },
    row:{
        marginTop:theme.spacing(2),
    },
    btCloseTop:{
        cursor:"pointer",
        margin: "auto",
    },
    btGroup:{
        padding:"16px 20px",
        minWidth:"200px!important",
        '&:hover':{
            color:"red",
            backgroundColor:theme.palette.primary.main,
        }
    },
    btGroupSelect:{
        color:"white",
        backgroundColor:theme.palette.primary.main,
    },
    btAction:{
        marginTop:'30px',
        marginBottom:'30px',
    },
    itemArea:{
        flex:1,
        marginTop:theme.spacing(2),
        marginLeft:theme.spacing(2),
        marginRight:theme.spacing(2),

        '&:first-child':{
            //marginLeft:0,
        },

        '&:last-child':{
            //marginRight:0,
        }
    },
    advanceOption:{
        marginTop:theme.spacing(2),
        backgroundColor:'rgba(150, 150, 150, 0.13)',
    },
    add:{
        color:theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        cursor:"pointer",
        paddingLeft:'20px',
        marginTop:theme.spacing(2),
    },
    addIcon:{
        width:'30px',
        margin:'auto',
    },
    addText:{
        flex:'1',
        margin:'auto',
    },
    iconSelected:{
        marginRight : "5px",
        color:"green",
        margin:"auto"
    },
    iconExpandLessRotate: {
        transform: "rotate(90deg)"
    }
}));

const checkForm=(tc)=>{
    if (tc){
        /*
        //Si le contact est de type particulier =>le nom et le prenom doivent etre renseigné
        //si le contact est de type entreprise =>la raison social est obligatoire
        if (contact.type === "P"){
            if (!(contact.name.isEmpty()===false)){
                return false;
            }
            if (!(contact.firstName.isEmpty()===false)){
                return false;
            }
        }else{ // C => Enterprise
            if (!(contact.rs.isEmpty()===false)){
                return false;
            }
        }
        */
        return true;
    }else{
        return false;
    }
}

const DialogTemplateCommunication = (props) => {
    //chargement des contextes
    const appSnackBar = useContext(SnackBarContext);
    const appContext = useContext(AppContext);

    //chargement des styles
    const classes = useStyles();

    //chargement i18n
    const {t} = useTranslation();

    //définition des variables d'états
    const [loading,setLoading]= React.useState(true)
    const [loadFailed,setLoadFailed]= React.useState(false)
    const [tc,setTc]=React.useState(props.tc ? _.cloneDeep(props.tc) : null);
    const [tcOrigin,setTcOrigin]=React.useState(props.tc ? _.cloneDeep(props.tc) : null);

    const [displayError,setDisplayError]= React.useState(false)

    const [activeStep, setActiveStep] = React.useState(0);

    //service templateCommunication
    const [serviceTemplateCommunication]= React.useState(new ServiceTemplateCommunication(props.enterpriseId))

    const [serviceTemplateDataFile]= React.useState(new ServiceTemplateDataFile(props.enterpriseId))
    const [templatesDataFile,setTemplatesDataFile]= React.useState([])

    const [serviceTemplateDocument]= React.useState(new ServiceTemplateDocument(props.organization.id,appContext.enterprise))
    const [templatesDocument,setTemplatesDocument]= React.useState([])

    const [templatesUser,setTemplatesUser]= React.useState([])
    const [serviceTemplateUser]= React.useState(new ServiceTemplateUser(props.organization.id,appContext.enterprise))

    const [templatesEmail,setTemplatesEmail]= React.useState([])
    const [serviceTemplateEmail]= React.useState(new ServiceTemplateEmail(props.organization.id,appContext.enterprise))

    const [templatesSms,setTemplatesSms]= React.useState([])
    const [serviceTemplateSms]= React.useState(new ServiceTemplateSms(props.organization.id,appContext.enterprise))

    const [expandOptionEmail,setExpandOptionEmail]= React.useState(false)
    const [expandOptionSms,setExpandOptionSms]= React.useState(false)

    const [viewCc,setViewCc]=React.useState(!!props.tc?.communication?.delivery?.email?.addrEmailCc?.length);
    const [viewCci,setViewCci]=React.useState(!!props.tc?.communication?.delivery?.email?.addrEmailCci?.length);

    const [modify,setModify]=React.useState(false);
    const [dialogCloseConfirm,setDialogCloseConfirm]=React.useState(false);

    // Initialisation
    useEffect(() => {

        //creation de la queue
        const queue = new PQueue({concurrency: 1});
        //création du tablau de promesse
        let promiseArray = [];

        const queryConstraints=new FirebaseConstraintsGenerator();
        queryConstraints.addWhere("org",OperatorType.EQUAL,props.organization.code)

        //Ajout de chaque document dans le tableau de promesse
        promiseArray.push(() => serviceTemplateDocument.list(queryConstraints));
        promiseArray.push(() => serviceTemplateUser.list(queryConstraints));
        promiseArray.push(() => serviceTemplateEmail.list(queryConstraints));
        promiseArray.push(() => serviceTemplateSms.list(queryConstraints));
        promiseArray.push(() => serviceTemplateDataFile.list(queryConstraints));

        //Quand toutes les promesses sont résolues
        queue.addAll(promiseArray).then((arrayOfList) => {
            setTemplatesDocument(arrayOfList[0]);
            setTemplatesUser(arrayOfList[1]);
            setTemplatesEmail(arrayOfList[2]);
            setTemplatesSms(arrayOfList[3]);
            setTemplatesDataFile(arrayOfList[4])

            //Si le contact n'est pas renseigné
            if (!tc){

                //on regarde si l'identifiant est renseigné
                //Si oui, on va chercher sur le serveur le modèle de request
                if (props.id){
                    serviceTemplateCommunication.document(props.id).then(doc=>{
                            const docTmp=doc;
                            setTc(_.cloneDeep(docTmp));
                            setTcOrigin(_.cloneDeep(docTmp));
                            setLoading(false);
                        },()=>{
                            setLoading(false);
                            setLoadFailed(true);
                        }
                    )
                }else{
                    const tcTmp=generateTemplateCommunicationEmpty();
                    setTc(_.cloneDeep(tcTmp));
                    setTcOrigin(_.cloneDeep(tcTmp));
                    setLoading(false);
                }
            }else{
                setLoading(false);
            }
        }, (msg) => {
            if (msg) console.error(msg);
            setLoading(false);
            setLoadFailed(true);
        });

    },[])

    // Détection des modifications
    useEffect(()=>{
        if (tc && tcOrigin){
            if (JSON.stringify(tc)!== JSON.stringify(tcOrigin)){
                setModify(true);
            }else {
                setModify(false);
            }
        }
    },[tc]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    //flag si nouveau contact
    const newTc = props.id ? false : true;

    const stepsMail = [
        capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.title')),
        capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.contentMail.title')),
        capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.contentEmail.title')),
        capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.contentSms.title'))
    ];

    const stepsEmail = [
        capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.title')),
        capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.contentEmail.title'))
    ];

    const stepsSms = [
        capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.title')),
        capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.contentSms.title'))
    ];

    let steps=[];
    switch (tc?.communication?.typeCommunication){
        case "M":steps=stepsMail;
            break;
        case "E":steps=stepsEmail;
            break;
        case "S":steps=stepsSms;
            break;
        default:steps=stepsMail;
    }

    //évènement sur la fermeture de la fenêtre
    const handleClose = (force)=>{
        if (force || !modify){
            if (typeof props.onCancel === "function") {
                props.onCancel();
            }
        }else{
            setDialogCloseConfirm(true);
        }
    }

    //évènement sur la sauvegarde du contact
    const handleSave = ()=>{

        if (checkForm(tc)){
            //normalisation de l'objet

            const tcTmp={
                ..._.cloneDeep(tc),
                org:props.organization.code
            };

            //sauvegarde de l'objet
            serviceTemplateCommunication.update(tcTmp).then( tc =>{

                //notification
                appSnackBar.success(capitalizeFirstLetter(t('comee.home.successSave')))

                setTcOrigin(_.cloneDeep(tc))
                setModify(false);

            },(error)=>{
                console.error("Error save document: ", error);
                //notification
                appSnackBar.error(capitalizeFirstLetter(t('comee.home.errorSave')))
            })

        }else{
            appSnackBar.warning(capitalizeFirstLetter(t('comee.home.errorCheck')))
            setDisplayError(true);
        }
    }

    const getDiffusionArchivage=(typeDelivery,title)=>{
        const delivery=tc.communication.delivery.ged.find(g=>g.typeDelivery===typeDelivery);
        if (!delivery){
            return null;
        }
        return (
            <AreaDeliveryCommon title={title}
                                delivery={tc.communication.delivery.ged.find(g=>g.typeDelivery===typeDelivery)}
                                onchange={(delivery)=>{
                                    const tcTmp=_.cloneDeep(tc);
                                    const arrayGed=tcTmp.communication.delivery.ged.map(d=>{
                                        if (d.typeDelivery===typeDelivery){
                                            return delivery;
                                        }else{
                                            return d
                                        }
                                    });
                                    tcTmp.communication.delivery.ged=arrayGed;
                                    setTc(tcTmp);
                                }}
            >
                <div className={classes.itemArea}>
                    <Box>
                        {
                            tc.communication.delivery.ged.find(g=>g.typeDelivery===typeDelivery)?.reference?.length ?
                                (
                                    <Variables
                                        keyName={"name"}
                                        keyValue={"value"}
                                        labelName={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.ged.metadata.key'))}
                                        labelValue={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.ged.metadata.value'))}
                                        array={tc.communication.delivery.ged.find(g=>g.typeDelivery===typeDelivery).reference}
                                        iconLess={true}
                                        onChange={(array)=>{
                                            const tcTmp=_.cloneDeep(tc);
                                            tcTmp.communication.delivery.ged.find(g=>g.typeDelivery===typeDelivery).reference=array;
                                            setTc(tcTmp);
                                        }}
                                    />
                                ):(
                                    <Button variant="outlined" startIcon={GetIcon(TypeIcon.NEW)} onClick={()=>{
                                        const tcTmp=_.cloneDeep(tc);
                                        tcTmp.communication.delivery.ged.find(g=>g.typeDelivery===typeDelivery).reference=[{
                                            "name":"",
                                            "value":"",
                                        }];
                                        setTc(tcTmp);
                                    }} >{capitalizeFirstLetter(t('comee.bt.newMetadata'))}
                                    </Button>
                                )
                        }
                    </Box>
                </div>
            </AreaDeliveryCommon>
        )
    }

    const getContentCommon=()=>
            <Box>
                <div className={classes.itemArea}>
                    <Typography variant={"h6"} component={"h6"} className={classes.title2}>
                        {capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.identity.title'))}
                    </Typography>
                    <FormControl sx={{ marginTop:"20px", width: "100%" }}>
                        <InputLabel id="demo-multiple-chip-label">{capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.identity.idUser'))}</InputLabel>
                        <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            value={tc?.identification}
                            onChange={(event) => setTc({
                                ...tc,
                                identification:event.target.value
                            })}
                            input={<OutlinedInput id="select-multiple-chip" label={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.identity.idUser'))} />}
                        >
                            {
                                templatesUser.map((item) => (
                                    <MenuItem
                                        key={item.id}
                                        value={item.id}
                                    >
                                        {item.name+ " "+ item.firstname}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </div>
                <div className={classes.itemArea}>
                    <Typography variant={"h6"} component={"h6"} className={classes.title2}>
                        {capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.title'))}
                    </Typography>
                    <Box className={classes.row}
                         style={{display:"flex"}}
                    >
                        <ButtonGroup disableElevation
                                     color={"primary"}
                                     variant="outlined"
                                     style={{margin:"auto"}}
                        >
                            <Button
                                color={"primary"}
                                onClick={()=>setTc({
                                    ...tc,
                                    communication:{
                                        ...tc.communication,
                                        typeCommunication:'M'
                                    }
                                })}
                                className={clsx(classes.btGroup,tc.communication.typeCommunication === 'M' && classes.btGroupSelect)}
                            >
                                {GetIcon(TypeIcon.MAIL,classes.typeComIcon)}
                                {capitalizeFirstLetter(t('comee.communication.typeCommunication.M'))}</Button>
                            <Button
                                color={"primary"}
                                onClick={()=>setTc({
                                    ...tc,
                                    communication:{
                                        ...tc.communication,
                                        typeCommunication:'E'
                                    }
                                })}
                                className={clsx(classes.btGroup,tc.communication.typeCommunication === 'E' && classes.btGroupSelect)}
                            >
                                {GetIcon(TypeIcon.EMAIL,classes.typeComIcon)}
                                {capitalizeFirstLetter(t('comee.communication.typeCommunication.E'))}</Button>
                            <Button
                                onClick={()=>setTc({
                                    ...tc,
                                    communication:{
                                        ...tc.communication,
                                        typeCommunication:'S'
                                    }
                                })}
                                className={clsx(classes.btGroup,tc.communication.typeCommunication === 'S' && classes.btGroupSelect)}
                            >
                                {GetIcon(TypeIcon.SMS,classes.typeComIcon)}
                                {capitalizeFirstLetter(t('comee.communication.typeCommunication.S'))}</Button>
                        </ButtonGroup>
                    </Box>
                </div>
                <div className={classes.itemArea}>
                    <Box>
                        <TextField
                            label={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.name'))}
                            type="text"
                            value={tc.communication.name}
                            onChange={(e)=>setTc({
                                ...tc,
                                communication:{
                                    ...tc.communication,
                                    name:e.target.value
                                }
                            })}
                            fullWidth
                            variant="outlined"
                            error={displayError && tc.communication.name.isEmpty()}
                            className={classes.itemRow}
                        />
                    </Box>
                </div>
                <div className={classes.itemArea}>
                    <Box>
                        <TextField
                            label={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.idFunc'))}
                            type="text"
                            value={tc.communication.idFunc}
                            onChange={(e)=>setTc({
                                ...tc,
                                communication:{
                                    ...tc.communication,
                                    idFunc:e.target.value
                                }
                            })}
                            fullWidth
                            variant="outlined"
                            error={displayError && tc.communication.idFunc.isEmpty()}
                            className={classes.itemRow}
                        />
                    </Box>
                </div>
                <div className={classes.itemArea}>
                    <Box>
                        <TextField
                            label={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.reference'))}
                            type="text"
                            value={tc.communication.reference}
                            onChange={(e)=>setTc({
                                ...tc,
                                communication:{
                                    ...tc.communication,
                                    reference:e.target.value
                                }
                            })}
                            fullWidth
                            variant="outlined"
                            error={displayError && tc.communication.reference.isEmpty()}
                            className={classes.itemRow}
                        />
                    </Box>
                </div>
                <div className={classes.itemArea}>
                    <Box>
                        <Typography variant={"h6"} component={"h6"} className={classes.title2}>
                            {capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.modeProcessing'))}
                        </Typography>
                        <Box className={classes.row}
                             style={{display:"flex"}}
                        >
                            <ButtonGroup disableElevation
                                         color={"primary"}
                                         variant="outlined"
                                         style={{margin:"auto"}}
                            >
                                <Button
                                    color={"primary"}
                                    onClick={()=>setTc({
                                        ...tc,
                                        communication:{
                                            ...tc.communication,
                                            modeProcessing:'I'
                                        }
                                    })}
                                    className={clsx(classes.btGroup,tc.communication.modeProcessing === 'I' && classes.btGroupSelect)}
                                >{capitalizeFirstLetter(t('comee.communication.modeProcessing.I'))}</Button>
                                <Button
                                    color={"primary"}
                                    onClick={()=>setTc({
                                        ...tc,
                                        communication:{
                                            ...tc.communication,
                                            modeProcessing:'T'
                                        }
                                    })}
                                    className={clsx(classes.btGroup,tc.communication.modeProcessing === 'T' && classes.btGroupSelect)}
                                >{capitalizeFirstLetter(t('comee.communication.modeProcessing.T'))}</Button>
                            </ButtonGroup>
                        </Box>
                    </Box>
                </div>
                {
                    tc.communication.typeCommunication !== 'S' && (
                        <div className={classes.itemArea}>
                            <Box>
                                <Typography variant={"h6"} component={"h6"} className={classes.title2}>
                                    {capitalizeFirstLetter(t('comee.home.attachment.title'))}
                                </Typography>
                                <FormGroup>
                                    <FormControlLabel defaultChecked={tc.communication.input.inputDesktop.available}
                                                      control={<Checkbox
                                                          defaultChecked={tc.communication.input.inputDesktop.available}
                                                          onClick={(e)=>{
                                                              const tcTmp=_.cloneDeep(tc);
                                                              tcTmp.communication.input.inputDesktop.available=!tcTmp.communication.input.inputDesktop.available;
                                                              setTc(tcTmp);
                                                          }}/>}
                                                      label={capitalizeFirstLetter(t('comee.home.attachment.addDesktop'))}

                                    />
                                    <FormControlLabel defaultChecked={tc.communication.input.inputTemplate.available}
                                                      control={<Checkbox
                                                          defaultChecked={tc.communication.input.inputTemplate.available}
                                                          onClick={(e)=>{
                                                              const tcTmp=_.cloneDeep(tc);
                                                              tcTmp.communication.input.inputTemplate.available=!tcTmp.communication.input.inputTemplate.available;
                                                              setTc(tcTmp);
                                                          }}
                                                      />}
                                                      label={capitalizeFirstLetter(t('comee.home.attachment.addRefComee'))}

                                    />
                                    <FormControlLabel defaultChecked={tc.communication.input.inputGed.available}
                                                      control={<Checkbox
                                                          defaultChecked={tc.communication.input.inputGed.available}
                                                          onClick={(e)=>{
                                                              const tcTmp=_.cloneDeep(tc);
                                                              tcTmp.communication.input.inputGed.available=!tcTmp.communication.input.inputGed.available;
                                                              setTc(tcTmp);
                                                          }}
                                                      />}
                                                      label={capitalizeFirstLetter(t('comee.home.attachment.addGed'))}

                                    />
                                </FormGroup>
                                {
                                    tc.communication.input.inputGed.available && (
                                        <Box>
                                            {
                                                tc.communication.input.inputGed.metadata.length ?
                                                    <Variables
                                                        keyName={"name"}
                                                        keyValue={"value"}
                                                        labelName={capitalizeFirstLetter(t('comee.home.attachment.metadata.key'))}
                                                        labelValue={capitalizeFirstLetter(t('comee.home.attachment.metadata.value'))}
                                                        array={tc.communication.input.inputGed.metadata}
                                                        disabledKey={false}
                                                        iconLess={true}
                                                        onChange={(array)=>{
                                                            const tcTmp=_.cloneDeep(tc);
                                                            tcTmp.communication.input.inputGed.metadata=array;
                                                            setTc(tcTmp);
                                                        }}
                                                    />:
                                                    <Button variant="outlined" startIcon={GetIcon(TypeIcon.NEW)} onClick={()=>{
                                                        const tcTmp=_.cloneDeep(tc);
                                                        tcTmp.communication.input.inputGed.metadata=[{
                                                            "name":"",
                                                            "value":"",
                                                        }];
                                                        setTc(tcTmp);
                                                    }}>
                                                        {capitalizeFirstLetter(t('comee.bt.new'))}
                                                    </Button>
                                            }
                                        </Box>
                                    )
                                }
                            </Box>
                        </div>
                    )
                }
            </Box>

    const getContentMail=()=>
        <Box>
            <div className={classes.itemArea}>
                <Box>
                    <Typography variant={"h6"} component={"h6"} className={classes.title2}>
                        {capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.contentMail.content'))}
                    </Typography>
                    <FormControl sx={{ marginTop:"20px", width: "100%" }}>
                        <InputLabel id="demo-simple-select-content-mail-label">{capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.contentMail.select'))}</InputLabel>
                        <Select
                            fullWidth
                            labelId="demo-simple-select-content-mail"
                            id="demo-simple-select-content-mail"
                            value={0}
                            label={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.contentMail.select'))}
                            onChange={(event) => {
                                if (event.target.value){
                                    const tcTmp=_.cloneDeep(tc);
                                    if (tcTmp.communication.content.contentMail){
                                        tcTmp.communication.content.contentMail.push({
                                            "id":event.target.value,
                                            "buffer":""
                                        })
                                    }else{
                                        tcTmp.communication.content.contentMail=[{
                                            "id":event.target.value,
                                            "buffer":""
                                        }]
                                    }
                                    setTc(tcTmp);
                                }
                            }}
                        >
                            <MenuItem key={0} value="">
                                <em>{capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.contentMail.selectReturn'))}</em>
                            </MenuItem>
                            {
                                templatesDocument.map((doc) => (
                                    <MenuItem key={doc.id} value={doc.id}>{doc.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    {
                        tc.communication.content?.contentMail && (
                            <List>
                                {
                                    tc.communication.content.contentMail.map((template,idx)=>(
                                        <ListItem key={template.id} style={{paddingLeft:"0",paddingRight:"0"}}>
                                            <CardTemplateDocument
                                                onDelete={()=>{
                                                    const tcTmp = _.cloneDeep(tc);
                                                    tcTmp.communication.content.contentMail.splice(idx, 1);
                                                    setTc(tcTmp);
                                                }}
                                                onChange={(templateChanged)=>{
                                                    const tcTmp = _.cloneDeep(tc);
                                                    tcTmp.communication.content.contentMail[idx]=templateChanged;
                                                    setTc(tcTmp);
                                                }}
                                                templateDocument={template}
                                                templatesDocument={templatesDocument}
                                                templatesDataFile={templatesDataFile}
                                                />
                                        </ListItem>
                                    ))
                                }
                            </List>
                        )
                    }
                </Box>
            </div>
            {
                tc.communication.content?.contentMail?.length>0 && (
                    <div className={classes.itemArea}>
                        <Typography variant={"h6"} component={"h6"} className={classes.title2}>
                            {capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.title'))}
                        </Typography>
                        <AreaDeliveryCommon
                            title={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.local.title'))}
                            delivery={tc.communication.delivery.localPrint}
                            onchange={(delivery) => {
                                const tcTmp = _.cloneDeep(tc);
                                tcTmp.communication.delivery.localPrint = delivery;
                                setTc(tcTmp);
                            }}
                        >
                            <div className={classes.itemArea}>
                                <FormGroup>
                                    <FormControlLabel defaultChecked={tc.communication.delivery.localPrint.byImprServer}
                                                      control={<Checkbox
                                                          defaultChecked={tc.communication.delivery.localPrint.byImprServer}
                                                          onClick={() => {
                                                              const tcTmp = _.cloneDeep(tc);
                                                              tcTmp.communication.delivery.localPrint.byImprServer = !tc.communication.delivery.localPrint.byImprServer;
                                                              setTc(tcTmp);
                                                          }}/>}
                                                      label={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.local.byImprServer'))}

                                    />
                                </FormGroup>
                            </div>
                        </AreaDeliveryCommon>
                        {
                            getDiffusionArchivage("LOCALPRINT", capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.local.ged.title')))
                        }
                        <AreaDeliveryCommon
                            title={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.central.title'))}
                            delivery={tc.communication.delivery.centralPrint}
                            onchange={(delivery) => {
                                const tcTmp = _.cloneDeep(tc);
                                tcTmp.communication.delivery.centralPrint = delivery;
                                setTc(tcTmp);
                            }}
                        >
                            <div className={classes.itemArea}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel>
                                        {capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.central.postage.title'))}
                                    </InputLabel>
                                    <Select
                                        //native
                                        label={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.central.postage.title'))}
                                        disabled={props.readOnly === true ? true : false}
                                        value={tc.communication.delivery.centralPrint.postage}
                                        onChange={(e) => {
                                            const tcTmp = _.cloneDeep(tc);
                                            tcTmp.communication.delivery.centralPrint.postage = e.target.value;
                                            setTc(tcTmp);
                                        }}
                                        displayEmpty
                                        fullWidth
                                    >
                                        <MenuItem key={'AN'}
                                                  value={'AN'}>{capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.central.postage.AN'))}</MenuItem>
                                        <MenuItem key={'AP'}
                                                  value={'AP'}>{capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.central.postage.AP'))}</MenuItem>
                                        <MenuItem key={'AR'}
                                                  value={'AR'}>{capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.central.postage.AR'))}</MenuItem>
                                        <MenuItem key={'ARAR'}
                                                  value={'ARAR'}>{capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.central.postage.ARAR'))}</MenuItem>
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={classes.itemArea}>
                                <FormGroup>
                                    <FormControlLabel defaultChecked={tc.communication.delivery.centralPrint.changePostage}
                                                      control={<Checkbox
                                                          defaultChecked={tc.communication.delivery.centralPrint.changePostage}
                                                          onClick={() => {
                                                              const tcTmp = _.cloneDeep(tc);
                                                              tcTmp.communication.delivery.centralPrint.changePostage = !tc.communication.delivery.centralPrint.changePostage;
                                                              setTc(tcTmp);
                                                          }}/>}
                                                      label={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.central.postage.changePostage'))}

                                    />
                                </FormGroup>
                            </div>
                        </AreaDeliveryCommon>
                        {
                            getDiffusionArchivage("CENTRALPRINT", capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.central.ged.title')))
                        }
                        <AreaDeliveryCommon
                            title={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.fax.title'))}
                            delivery={tc.communication.delivery.fax}
                            onchange={(delivery) => {
                                const tcTmp = _.cloneDeep(tc);
                                tcTmp.communication.delivery.fax = delivery;
                                setTc(tcTmp);
                            }}
                        >
                            <div className={classes.itemArea}>
                                <Box className={classes.adressBox}>
                                    <ReactTags
                                        //readOnly={props.readOnly}
                                        tags={
                                            tc.communication.delivery.fax.number?.map((number) => {
                                                return {id: number, text: number}
                                            })
                                        }
                                        placeholder={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.fax.addNumber'))}
                                        allowDragDrop={false}
                                        handleDelete={(i) => {
                                            const tcTmp = _.cloneDeep(tc);
                                            tcTmp.communication.delivery.fax.number.splice(i, 1);
                                            setTc(tcTmp);
                                        }}
                                        handleAddition={(tag) => {
                                            if (!isValidTelephoneNumber(tag.text)) {
                                                return false;
                                            }
                                            const tcTmp = _.cloneDeep(tc);
                                            tcTmp.communication.delivery.fax.number.push(tag.text);
                                            setTc(tcTmp);
                                        }}
                                    />
                                </Box>
                            </div>
                        </AreaDeliveryCommon>
                        {
                            getDiffusionArchivage("FAX", capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.fax.ged.title')))
                        }
                        <AreaDeliveryCommon
                            title={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.generic.title'))}
                            delivery={tc.communication.delivery.generic}
                            onchange={(delivery) => {
                                const tcTmp = _.cloneDeep(tc);
                                tcTmp.communication.delivery.generic = delivery;
                                setTc(tcTmp);
                            }}
                        >
                            <div className={classes.itemArea}>
                                <TextField
                                    label={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.generic.fileName'))}
                                    type="text"
                                    value={tc.communication.delivery.generic.fileName}
                                    onChange={(e) => {
                                        const tcTmp = _.cloneDeep(tc);
                                        tcTmp.communication.delivery.generic.fileName = e.target.value;
                                        setTc(tcTmp);
                                    }}
                                    fullWidth
                                    variant="outlined"
                                    className={classes.itemRow}
                                />
                            </div>
                        </AreaDeliveryCommon>
                        {
                            getDiffusionArchivage("GENERIC", capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.generic.ged.title')))
                        }
                    </div>
                )
            }
        </Box>

    const CheckBoxWithTitle=(item,itemParent,title,isChecked)=>{
        return (
            <div className={classes.itemArea}>
                <FormControlLabel defaultChecked={isChecked}
                                  control={<Checkbox
                                      defaultChecked={isChecked}
                                      onClick={(e)=>{
                                          const tcTmp=_.cloneDeep(tc);
                                          tcTmp.communication.content[itemParent][item]=!tcTmp.communication.content[itemParent][item];
                                          setTc(tcTmp);
                                      }}
                                  />}
                                  label={title}
                />
            </div>
        )
    }

    const getContentEmail=()=>
            <Box>
                <div className={classes.itemArea}>
                    <Box>
                        <Typography variant={"h6"} component={"h6"} className={classes.title2}>
                            {capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.contentEmail.content'))}
                        </Typography>
                        <FormControl sx={{ marginTop:"20px", width: "100%" }}>
                            <InputLabel id="demo-multiple-checkbox-content-email">{capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.contentEmail.select'))}</InputLabel>
                            <Select
                                fullWidth
                                labelId="demo-multiple-checkbox-content-email"
                                id="demo-multiple-content-email"
                                value={tc.communication.content?.contentEmail ? tc.communication.content?.contentEmail?.defaultTemplate : ""}
                                label={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.contentEmail.select'))}
                                onChange={(event) => {
                                    serviceTemplateEmail.id(event.target.value).then(template=>{
                                        const tcTmp=_.cloneDeep(tc);

                                        if (event.target.value) {
                                            tcTmp.communication.content.contentEmail = {
                                                defaultTemplate: event.target.value,
                                                editBody: true,
                                                editSubject: true,
                                                changeTemplate: true,
                                                addAttachment: true,
                                                forward: false,
                                                split: true,
                                                responseByDocId: "",
                                                whiteMark: null,
                                                variable: (
                                                    template.variables && template.variables.length ?
                                                        template.variables.map(variable => {
                                                            return {
                                                                name: variable.name,
                                                                value: variable.value,
                                                            }
                                                        })
                                                        : []
                                                )
                                            }
                                            setTc(tcTmp);
                                        }
                                        else {
                                            delete tcTmp.communication.content.contentEmail;
                                            setTc(tcTmp);
                                        }
                                    })
                                }}
                            >
                                <MenuItem key={0} value="">
                                    <em>None</em>
                                </MenuItem>
                                {
                                    templatesEmail.map((email) => (
                                        <MenuItem key={email.id} value={email.id}>{email.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    {
                        tc.communication.content?.contentEmail?.defaultTemplate && (
                            <Box>
                                <Typography variant={"h6"} component={"h6"} className={classes.title2}>
                                    {capitalizeFirstLetter(t('comee.repository.variables.title'))}
                                </Typography>
                                <Box>
                                    {
                                        tc.communication.content.contentEmail.variable.length ?
                                            (
                                                <Variables
                                                    keyName={"name"}
                                                    keyValue={"value"}
                                                    labelName={capitalizeFirstLetter(t('comee.repository.variables.key'))}
                                                    labelValue={capitalizeFirstLetter(t('comee.repository.variables.value'))}
                                                    array={tc.communication.content?.contentEmail.variable}
                                                    iconLess={true}
                                                    onChange={(array)=>{
                                                        const tcTmp=_.cloneDeep(tc);
                                                        tcTmp.communication.content.contentEmail.variable=array;
                                                        setTc(tcTmp);
                                                    }}
                                                />
                                            ):(
                                                <Button variant="outlined" startIcon={GetIcon(TypeIcon.NEW)} onClick={()=>{
                                                        const tcTmp=_.cloneDeep(tc);
                                                        tcTmp.communication.content.contentEmail.variable=[{
                                                            "name":"",
                                                            "value":"",
                                                        }];
                                                        setTc(tcTmp);
                                                    }} >{capitalizeFirstLetter(t('comee.bt.newVar'))}
                                                </Button>
                                            )
                                    }
                                </Box>
                                <ListItem key={"myCode"}
                                          button
                                          onClick={()=>setExpandOptionEmail(!expandOptionEmail)}
                                          className={classes.advanceOption}
                                >
                                    <ListItemText primary={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.advanceOption'))}/>
                                    {expandOptionEmail ? GetIcon(TypeIcon.EXPANDMORE) : GetIcon(TypeIcon.EXPANDLESS,classes.iconExpandLessRotate) }
                                </ListItem>
                                <Collapse in={expandOptionEmail} timeout={300} >
                                    {
                                        ["editSubject","editBody","changeTemplate","addAttachment","forward","split"].map(item=>
                                            <div className={classes.itemArea}>
                                                <FormControlLabel defaultChecked={tc.communication.content.contentEmail[item]}
                                                                  control={<Checkbox
                                                                      defaultChecked={tc.communication.content.contentEmail[item]}
                                                                      onClick={(e)=>{
                                                                          const tcTmp=_.cloneDeep(tc);
                                                                          tcTmp.communication.content.contentEmail[item]=!tcTmp.communication.content.contentEmail[item];
                                                                          setTc(tcTmp);
                                                                      }}
                                                                  />}
                                                                  label={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.contentEmail.'+item))}

                                                />
                                            </div>)
                                    }
                                    <div className={classes.itemArea}>
                                        <TextField
                                            label={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.contentEmail.responseByDocId'))}
                                            type="text"
                                            value={tc.communication.content.contentEmail.responseByDocId}
                                            onClick={(e)=>{
                                                const tcTmp=_.cloneDeep(tc);
                                                tcTmp.communication.content.contentEmail.responseByDocId=e.target.value;
                                                setTc(tcTmp);
                                            }}
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </div>
                                </Collapse>
                            </Box>
                        )
                    }
                </div>
                <div className={classes.itemArea}>

                </div>
                {
                    tc.communication.content?.contentEmail?.defaultTemplate && (
                        <div className={classes.itemArea}>
                            <Typography variant={"h6"} component={"h6"} className={classes.title2}>
                                {capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.title'))}
                            </Typography>
                            <AreaDeliveryCommon title={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.email.title'))}
                                                delivery={tc.communication.delivery.email}
                                                onchange={(delivery)=>{
                                                    const tcTmp=_.cloneDeep(tc);
                                                    tcTmp.communication.delivery.email=delivery;
                                                    setTc(tcTmp);
                                                }}
                            >
                                <div className={classes.itemArea}>
                                    <Box display={"flex"} flexDirection={"row"}>
                                        <Box margin={"auto"}>
                                            <Typography variant={"h6"} component={"h6"} className={classes.title3}>
                                                {capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.email.to'))}
                                            </Typography>
                                        </Box>
                                        <Box flex={"1"}>
                                            <InputTags
                                                id={"demo-multiple-chip-email"}
                                                labelTag={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.email.addAdr'))}
                                                value={transformeFormatEmailDoDisplayInputTags(tc.communication.delivery.email.addrEmail)}
                                                onChange={(email)=>{
                                                    //console.log
                                                    const tcTmp=_.cloneDeep(tc);
                                                    tcTmp.communication.delivery.email.addrEmail=transformeDisplayInputTagsToFormatEmail(email);
                                                    setTc(tcTmp);
                                                }}
                                                validator={isValidAdresseEmailInputTas}
                                            />
                                        </Box>
                                        <Box className={classes.adressBoxLinkCcCci} onClick={()=>setViewCc(!viewCc)}>
                                            <Typography component={"span"} className={clsx(tc?.communication?.delivery?.email?.addrEmailCc?.length>0 && classes.adressBoxLinkCcCciNotEmpty)}>
                                                {capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.email.cc'))}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.adressBoxLinkCcCci} onClick={()=>setViewCci(!viewCci)}>
                                            <Typography component={"span"} className={clsx(tc?.communication?.delivery?.email?.addrEmailCci?.length>0 && classes.adressBoxLinkCcCciNotEmpty)}>
                                                {capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.email.cci'))}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </div>
                                {
                                    viewCc && (
                                        <div className={classes.itemArea}>
                                            <Box display={"flex"} flexDirection={"row"}>
                                                <Box margin={"auto"}>
                                                    <Typography variant={"h6"} component={"h6"} className={classes.title3}>
                                                        {capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.email.cc'))}
                                                    </Typography>
                                                </Box>
                                                <Box flex={"1"}>
                                                    <InputTags
                                                        id={"demo-multiple-chip-email"}
                                                        labelTag={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.email.addAdr'))}
                                                        value={transformeFormatEmailDoDisplayInputTags(tc.communication.delivery.email.addrEmailCc)}
                                                        onChange={(email)=>{
                                                            //console.log
                                                            const tcTmp=_.cloneDeep(tc);
                                                            tcTmp.communication.delivery.email.addrEmailCc=transformeDisplayInputTagsToFormatEmail(email);
                                                            setTc(tcTmp);
                                                        }}
                                                        validator={isValidAdresseEmailInputTas}
                                                    />
                                                </Box>
                                            </Box>
                                        </div>
                                    )
                                }
                                {
                                    viewCci && (
                                        <div className={classes.itemArea}>
                                            <Box display={"flex"} flexDirection={"row"}>
                                                <Box margin={"auto"}>
                                                    <Typography variant={"h6"} component={"h6"} className={classes.title3}>
                                                        {capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.email.cci'))}
                                                    </Typography>
                                                </Box>
                                                <Box flex={"1"}>
                                                    <InputTags
                                                        id={"demo-multiple-chip-email"}
                                                        labelTag={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.email.addAdr'))}
                                                        value={transformeFormatEmailDoDisplayInputTags(tc.communication.delivery.email.addrEmailCci)}
                                                        onChange={(email)=>{
                                                            //console.log
                                                            const tcTmp=_.cloneDeep(tc);
                                                            tcTmp.communication.delivery.email.addrEmailCci=transformeDisplayInputTagsToFormatEmail(email);
                                                            setTc(tcTmp);
                                                        }}
                                                        validator={isValidAdresseEmailInputTas}
                                                    />
                                                </Box>
                                            </Box>
                                        </div>
                                    )
                                }

                            </AreaDeliveryCommon>
                            {
                                getDiffusionArchivage("EMAIL",capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.email.ged.title')))
                            }
                        </div>
                    )
                }
            </Box>

    const getContentSms=()=>
        <Box>
            <div className={classes.itemArea}>
                <Box>
                    <Typography variant={"h6"} component={"h6"} className={classes.title2}>
                        {capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.contentSms.content'))}
                    </Typography>
                    <FormControl sx={{ marginTop:"20px", width: "100%" }}>
                        <InputLabel id="demo-multiple-checkbox-content-sms">{capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.contentSms.select'))}</InputLabel>
                        <Select
                            fullWidth
                            labelId="demo-multiple-checkbox-content-sms"
                            id="demo-multiple-content-sms"
                            value={tc.communication.content?.contentSms?.defaultTemplate ? tc.communication.content?.contentSms.defaultTemplate : ""}
                            label={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.contentSms.select'))}
                            onChange={(e) => {
                                const tcTmp=_.cloneDeep(tc);

                                if (e.target.value){
                                    serviceTemplateSms.id(e.target.value).then(template=>{
                                        tcTmp.communication.content.contentSms={
                                            "defaultTemplate":e.target.value,
                                            "type":"M",
                                            "from":"",
                                            "changeType":true,
                                            "changeFrom":true,
                                            "changeLabel":true,
                                            "editBody":true,
                                            "changeTemplate":true,
                                            "variable":(
                                                template.variables && template.variables.length ?
                                                    template.variables.map(variable => {
                                                        return {
                                                            name: variable.name,
                                                            value: variable.value,
                                                        }
                                                    })
                                                    : []
                                            )
                                        }
                                        setTc(tcTmp);
                                    })
                                }else{
                                    delete tcTmp.communication.content.contentSms;
                                    setTc(tcTmp);
                                }

                            }}
                        >
                            <MenuItem key={0} value="">
                                <em>None</em>
                            </MenuItem>
                            {
                                templatesSms.map((doc) => (
                                    <MenuItem key={doc.id} value={doc.id}>{doc.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Box>
                {
                    tc.communication.content?.contentSms?.defaultTemplate && (
                        <Box>
                            <Typography variant={"h6"} component={"h6"} className={classes.title2}>
                                {capitalizeFirstLetter(t('comee.repository.variables.title'))}
                            </Typography>
                            <Box>
                                {
                                    tc.communication.content.contentSms.variable.length ?
                                        (
                                            <Variables
                                                keyName={"name"}
                                                keyValue={"value"}
                                                labelName={capitalizeFirstLetter(t('comee.repository.variables.key'))}
                                                labelValue={capitalizeFirstLetter(t('comee.repository.variables.value'))}
                                                array={tc.communication.content?.contentSms.variable}
                                                iconLess={true}
                                                onChange={(array)=>{
                                                    const tcTmp=_.cloneDeep(tc);
                                                    tcTmp.communication.content.contentSms.variable=array;
                                                    setTc(tcTmp);
                                                }}
                                            />
                                        ):(
                                            <Button variant="outlined" startIcon={GetIcon(TypeIcon.NEW)} onClick={()=>{
                                                const tcTmp=_.cloneDeep(tc);
                                                tcTmp.communication.content.contentSms.variable=[{
                                                    "name":"",
                                                    "value":"",
                                                }];
                                                setTc(tcTmp);
                                            }} >{capitalizeFirstLetter(t('comee.bt.newVar'))}
                                            </Button>
                                        )
                                }
                            </Box>
                            <ListItem key={"myCode"}
                                      button
                                      onClick={()=>setExpandOptionSms(!expandOptionSms)}
                                      className={classes.advanceOption}
                            >
                                <ListItemText primary={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.advanceOption'))}/>
                                {expandOptionSms ? GetIcon(TypeIcon.EXPANDMORE) : GetIcon(TypeIcon.EXPANDLESS,classes.iconExpandLessRotate) }
                            </ListItem>
                            <Collapse in={expandOptionSms} timeout={300} >
                                {
                                    CheckBoxWithTitle("editBody","contentSms",capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.contentSms.editBody')),tc.communication.content.contentSms.editBody)
                                }
                                {
                                    CheckBoxWithTitle("changeTemplate","contentSms",capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.contentSms.changeTemplate')),tc.communication.content.contentSms.changeTemplate)
                                }
                                {
                                    <div className={classes.itemArea}>
                                        <FormControl sx={{ marginTop:"20px", width: "100%" }}>
                                            <InputLabel id="demo-multiple-chip-label">{capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.contentSms.type.title'))}</InputLabel>
                                            <Select
                                                labelId="demo-multiple-chip-label"
                                                id="demo-multiple-chip"
                                                value={tc?.communication.content?.contentSms?.type}
                                                onChange={(e)=>{
                                                    const tcTmp=_.cloneDeep(tc);
                                                    tcTmp.communication.content.contentSms.type=e.target.value;
                                                    setTc(tcTmp);
                                                }}
                                                input={<OutlinedInput id="select-multiple-chip" label={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.contentSms.type.title'))} />}
                                            >
                                                <MenuItem key={"A"} value={"A"} >{capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.contentSms.type.alert'))}</MenuItem>
                                                <MenuItem key={"M"} value={"M"} >{capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.contentSms.type.market'))}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                }
                                {
                                    CheckBoxWithTitle("changeType","contentSms",capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.contentSms.changeType')),tc.communication.content.contentSms.changeType)
                                }
                                {
                                    <div className={classes.itemArea}>
                                        <TextField
                                            label={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.contentSms.from'))}
                                            type="text"
                                            value={tc?.communication.content?.contentSms?.from}
                                            onChange={(e)=>{
                                                const tcTmp=_.cloneDeep(tc);
                                                tcTmp.communication.content.contentSms.from=e.target.value;
                                                setTc(tcTmp);
                                            }}
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </div>
                                }
                                {
                                    CheckBoxWithTitle("changeFrom","contentSms",capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.contentSms.changeFrom')),tc.communication.content.contentSms.changeFrom)
                                }
                                {
                                    CheckBoxWithTitle("changeLabel","contentSms",capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.communication.contentSms.changeLabel')),tc.communication.content.contentSms.changeLabel)
                                }
                            </Collapse>
                        </Box>
                    )
                }
            </div>
            {
                tc.communication.content?.contentSms?.defaultTemplate && (
                    <div className={classes.itemArea}>
                        <Typography variant={"h6"} component={"h6"} className={classes.title2}>
                            {capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.title'))}
                        </Typography>

                        <AreaDeliveryCommon title={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.sms.title'))}
                                            delivery={tc.communication.delivery.sms}
                                            onchange={(delivery)=>{
                                                const tcTmp=_.cloneDeep(tc);
                                                tcTmp.communication.delivery.sms=delivery;
                                                setTc(tcTmp);
                                            }}
                        >
                            <div className={classes.itemArea}>
                                    <InputTags
                                        id={"demo-multiple-chip-number"}
                                        labelTag={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.sms.addNumber'))}
                                        value={tc.communication.delivery.sms.number}
                                        onChange={(_number)=>{
                                            const tcTmp=_.cloneDeep(tc);
                                            tcTmp.communication.delivery.sms.number=_number;
                                            setTc(tcTmp);
                                        }}
                                        validator={isValidTelephoneNumber}
                                    />
                                    {
                                        /*
                                        <ReactTags
                                        //readOnly={props.readOnly}
                                        tags={
                                            tc.communication.delivery.sms.number?.map((number) => {
                                                return {id: number, text: number}
                                            })
                                        }
                                        placeholder={capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.sms.addNumber'))}
                                        allowDragDrop={false}
                                        handleDelete={(i) => {
                                            const tcTmp = _.cloneDeep(tc);
                                            tcTmp.communication.delivery.sms.number.splice(i, 1);
                                            setTc(tcTmp);
                                        }}
                                        handleAddition={(tag) => {
                                            if (!isValidTelephoneNumber(tag.text)) {
                                                return false;
                                            }
                                            const tcTmp = _.cloneDeep(tc);
                                            tcTmp.communication.delivery.sms.number.push(tag.text);
                                            setTc(tcTmp);
                                        }}
                                    />
                                         */
                                    }
                            </div>
                        </AreaDeliveryCommon>
                        {
                            getDiffusionArchivage("SMS",capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.stepper.delivery.sms.ged.title')))
                        }
                    </div>
                )
            }

        </Box>

    const getContentForm=(step)=>{

        switch (tc.communication.typeCommunication){
            case "M":
                switch (step){
                    case 0: return getContentCommon();
                    case 1: return getContentMail();
                    case 2: return getContentEmail();
                    case 3: return getContentSms();
                    default : throw new Error("step not defined")
                }
            case "E":
                switch (step){
                    case 0: return getContentCommon();
                    case 1: return getContentEmail();
                    default : throw new Error("step not defined")
                }
            case "S":
                switch (step){
                    case 0: return getContentCommon();
                    case 1: return getContentSms();
                    default : throw new Error("step not defined")
                }
            default : throw new Error("tc.communication.typeCommunication not defined")
        }

}

    const getContent=()=>{
        if (tc===null || loading){
            return (
                <Loader color={"primary"}/>
            )
        }

        if (loadFailed ){
            return (
                <ErrorArea label={capitalizeFirstLetter(t('comee.home.errorLoadOne'))} />
            )
        }

        return (
            <Box>
                {
                    activeStep === steps.length ? (
                        <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1 }} style={{marginTop:"50px"}}>
                                {capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.allComplete'))}
                            </Typography>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Box sx={{ mt: 2, mb: 1 }}>
                                {
                                    getContentForm(activeStep)
                                }
                            </Box>
                        </React.Fragment>
                    )
                }
            </Box>
        )
    }

    return (
        <Dialog
            className={classes.root}
                open={props.open}
                onClose={()=>handleClose(false)}
                fullScreen
        >
            <DialogTitle className={classes.contentTitle}>
                {newTc ? capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.create')) :  capitalizeFirstLetter(t('comee.home.boxTemplateCommunication.edit'))}
                <Stepper activeStep={activeStep} style={{marginTop:"30px",marginBottom:"30px"}}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </DialogTitle>
            <DialogContent>
                {
                    getContent()
                }
            </DialogContent>
            <DialogActions>
                <Box width={"100%"} display={"flex"} flexDirection={"column"}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >{capitalizeFirstLetter(t('comee.bt.back'))}</Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleNext}
                                disabled={activeStep === steps.length - 1}
                        >
                            {
                                capitalizeFirstLetter(t('comee.bt.next'))
                            }
                        </Button>
                    </Box>
                    <Box className={classes.contentTitle} flexDirection={"row"} justifyContent={"flex-end"} display={"flex"} flex={"1"}>
                        <Box flex={"1"}></Box>
                        <Button variant="contained"
                                disableElevation
                                onClick={()=>handleClose(false)}
                                className={classes.btAction}
                                startIcon={GetIcon(TypeIcon.ARROW_BACK)}
                        >
                            {capitalizeFirstLetter(t('comee.bt.exit'))}
                        </Button>
                        {
                            loading === false && loadFailed === false && (
                                <Button onClick={handleSave}
                                        disabled={!modify}
                                        variant="contained"
                                        disableElevation
                                        color="primary"
                                        style={{marginLeft:"16px"}}
                                        className={classes.btAction}
                                        startIcon={GetIcon(TypeIcon.SAVE,clsx(modify && classes.red))}
                                >
                                    {capitalizeFirstLetter(t('comee.bt.apply'))}
                                </Button>
                            )
                        }
                    </Box>
                </Box>
            </DialogActions>
            {
                dialogCloseConfirm && (
                    <DialogConfirmCloseWithoutSave
                        title={capitalizeFirstLetter(t('comee.dialogConfirmCloseWithoutSave.warning'))}
                        msg1={capitalizeFirstLetter(t('comee.dialogConfirmCloseWithoutSave.modify'))}
                        msg2={capitalizeFirstLetter(t('comee.dialogConfirmCloseWithoutSave.modify2'))}
                        msg2={capitalizeFirstLetter(t('comee.dialogConfirmCloseWithoutSave.modify3'))}

                        onClose={()=>{
                            setModify(false);
                            setDialogCloseConfirm(false);
                            handleClose(true);
                        }}
                        onCancel={()=>{
                            setDialogCloseConfirm(false);
                        }}

                    />
                )
            }
        </Dialog>
    )
}

DialogTemplateCommunication.propTypes = {
    enterpriseId:PropTypes.string.isRequired,
    organization:PropTypes.object.isRequired,
    open:PropTypes.bool,
    tc:PropTypes.object,
    onCancel:PropTypes.func.isRequired,
}

export default DialogTemplateCommunication;
