import {ServiceFirebase} from "./ServiceFireBase";

export const ENTERPRISES_COLLECTION="enterprises"

export class ServiceEnterprise extends ServiceFirebase{
    constructor() {
        super([ENTERPRISES_COLLECTION]);
    }
}

