import './App.scss';

import React, { Suspense} from "react";

import Login from './login/Login'
import Dashboard from './dashboard/Dashboard'

import { ThemeProvider } from '@mui/material/styles';

import Loader from "./shared/Loader";
import {Box, createTheme} from "@mui/material";
import MessageSnackBar from "./shared/MessageSnackBar";

const theme = createTheme({
    palette: {
        type: "light",
        background:{
            light:'white',
            main: 'white',
            dark:'black',
        },
        primary: {
            main: '#1976d2',
            light:'#63a4ff',
            dark:'#004ba0',
            contrastText:'#fff',
            toolbar:'#3d639c',

        },
        secondary: {
            main: '#f7f7f7',
            light: '#ffffff',
            dark:'#c4c4c4',
            toolbar:'#63a4ff',
            // dark: will be calculated from palette.secondary.main,
            contrastText:'#6a6060',
        },
    },
    background: {
        paper:'#fff',
        default:'#fafafa'
    },
    status: {
        success:'#67d464',
        danger: '#e53e3e',
        warn:'#f5921a',
        info:'#3780d5',
    },
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
});

function App() {
    return (
        <ThemeProvider theme={theme} >
            <Box display={"flex"} flex={"1"} style={{maxHeight:'100%',maxWidth:'100%',backgroundColor:theme.palette.primary.main}}>
                <Suspense fallback={<Loader />}>
                    <Login>
                        <MessageSnackBar>
                            <Dashboard />
                        </MessageSnackBar>
                    </Login>
                </Suspense>
            </Box>
        </ThemeProvider>
    );
}

export default App;
