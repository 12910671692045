import {getFunctions, httpsCallable} from "firebase/functions";

export class ServiceOrganisation {

    apiKey="";
    url="";
    constructor({url,apiKey}) {
        this.url=url;
        this.apiKey=apiKey;
    }

    list=()=>{
        return new Promise((resolve, reject) => {

            const functions = getFunctions();
            functions.region = "europe-west3";
            const serviceOrganizationList = httpsCallable(functions, 'serviceOrganizationList');
            const data = {
                "url":this.url,
                "apiKey":this.apiKey
            };

            serviceOrganizationList(data)
                .then((response) => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject();
                    }
                }).catch((error) => {
                    error && console.error(error);
                    reject(error);
                });
        });
    }

    id=(organizationId)=>{
        return new Promise((resolve, reject) => {

            const functions = getFunctions();
            functions.region = "europe-west3";
            const serviceOrganizationId = httpsCallable(functions, 'serviceOrganizationId');
            const data = {
                "url":this.url,
                "apiKey":this.apiKey,
                "organizationId":organizationId
            };

            serviceOrganizationId(data)
                .then((response) => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject();
                    }
                }).catch((error) => {
                    error && console.error(error);
                    reject(error);
                });
        });
    }
}
