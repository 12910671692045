import React, {useContext} from 'react';
import PropTypes from "prop-types";
import {
    AppBar,
    Box,
    Dialog,
    DialogContent, DialogTitle, IconButton, Toolbar, Typography
} from "@mui/material";

import {capitalizeFirstLetter} from "../../common/tools";
import {GetIcon, TypeIcon} from "../utils/Icones";
import ReactJson from 'react-json-view'
import {SnackBarContext} from "../shared/MessageSnackBar";

const {makeStyles} = require("@mui/styles");
const {useTranslation} = require("react-i18next");

const useStyles = makeStyles((theme) => ({
    root:{
        display:"flex",
        flexDirection:"column",
    },
    dialogPaper: {
        minHeight: '90vh',
        maxHeight: '90vh',
    },
    content: {
        height: '300px',
    },
    bt: {
        marginRight: '30px',
    },
}));

const DialogPreview = (props) => {

    const appSnackBar = useContext(SnackBarContext);

    //chargement des styles
    const classes = useStyles();

    //chargement i18n
    const {t} = useTranslation();

    const handleClose =()=>{
        if (typeof props.onClose === "function"){
            props.onClose();
        }
    }

    return (
        <Dialog open={true}
                fullWidth={true}
                maxWidth={false}
                onClose={handleClose}
                classes={{ paper: classes.dialogPaper }}

        >

            <DialogTitle>
                <AppBar sx={{ position: 'relative' }} color={"secondary"}>
                    <Toolbar>
                        {
                                GetIcon(TypeIcon.PREVIEW)
                        }
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            { capitalizeFirstLetter(t('comee.home.boxTemplateCommunicationPreview.title'))}
                        </Typography>
                        <Box onClick={handleClose}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="close"
                            >
                                {GetIcon(TypeIcon.CLOSE)}
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>


            </DialogTitle>
            <DialogContent className={classes.content}>
                <Box>
                    <ReactJson
                        name={null}
                        src={props.json}
                        iconStyle={"triangle"}
                        indentWidth={6}
                        collapseStringsAfterLength={50}
                        groupArraysAfterLength={10}
                        enableClipboard={(copy)=>{
                            try {
                                const src=JSON.stringify(copy.src);
                                navigator.clipboard.writeText(src).then(()=>{
                                    appSnackBar.success(capitalizeFirstLetter(t('comee.home.boxTemplateCommunicationPreview.clipboardCopySuccess')))
                                });
                            }catch (e) {
                                appSnackBar.error(capitalizeFirstLetter(t('comee.home.boxTemplateCommunicationPreview.clipboardCopyError')))
                            }
                        }}
                        onEdit={false}
                        onAdd={false}
                        onDelete={false}
                    />
                </Box>
            </DialogContent>
        </Dialog>
    )
}



DialogPreview.propTypes = {
    json:PropTypes.object.isRequired,
    onClose:PropTypes.func.isRequired,
}

export default DialogPreview;
