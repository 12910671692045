import React from 'react';
import PropTypes from "prop-types";
import VariableLine from "./VariableLine";

const Variables = (props)=>{
    return (
        props.array.map((item,idx)=>
            <VariableLine
                name={item[props.keyName]}
                value={item[props.keyValue]}
                labelName={props.labelName.replace("{INDEX}",String(idx+1).toString())}
                labelValue={props.labelValue.replace("{INDEX}",(idx+1).toString())}
                disabledKey={props.disabledKey === true}
                iconMore={idx+1 === props.array.length}
                iconLess={props.iconLess}
                onClickAdd={()=>{
                    const item={
                        [props.keyName]:"",
                        [props.keyValue]:""
                    };

                    if (typeof props.onChange === "function"){
                        props.onChange(props.array.concat(item))
                    }
                }}
                onClickLess={()=>{
                    if (typeof props.onChange === "function"){
                        props.onChange(props.array.filter((item,index)=>index!==idx))
                    }
                }}
                onChange={(name,value)=>{
                    if (typeof props.onChange === "function"){
                        props.onChange(props.array.map((item,index)=>{
                            if (index===idx){
                                item[props.keyName]=name;
                                item[props.keyValue]=value;
                            }
                            return item;
                        }))
                    }
                }}

            />
        )
    )
}

Variables.propTypes={
    array:PropTypes.array.isRequired,
    onChange:PropTypes.func.isRequired,
    keyName:PropTypes.string.isRequired,
    labelName:PropTypes.string,
    keyValue:PropTypes.string.isRequired,
    labelValue:PropTypes.string,
    iconLess:PropTypes.bool,
}

export default Variables;
