import {Box, Stack, TextField, Typography} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import {Cancel} from "@mui/icons-material";
import PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";

const useStylesTags = makeStyles((theme) => ({
    tag: {
        background: "#D3D3D3",
        height: "37px",
        padding: "0.4rem",
        margin: "0 0.5rem 0 0",
        color: theme.palette.primary.main,
    },
    text:{
        whiteSpace: "nowrap",
        display:"inline-flex"
    }
}));

const Tags = ({ data, handleDelete }) => {
    const classes = useStylesTags();
    return (
        <Box
            className={classes.tag}
        >
            <Stack direction='row' gap={1} display={"flex"}>
                <Typography component={"span"} className={classes.text}>{data}</Typography>
                <Cancel
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                        handleDelete(data);
                    }}
                />
            </Stack>
        </Box>
    );
};
Tags.propTypes={
    data:PropTypes.string.isRequired,
    handleDelete:PropTypes.func.isRequired,
}

const InputTags = (props) =>{
    const [tags, setTags] = useState(props.value || []);
    const tagRef = useRef();

    useEffect(() => {
        if (typeof props.onChange === "function"){
            props.onChange(tags);
        }
    }, [tags]);


    const handleDelete = (value) => {
        const newtags = tags.filter((val) => val !== value);
        setTags(newtags);
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();

        if (typeof props.validator === "function"){
            if (props.validator(tagRef.current.value)){
                setTags([...tags, tagRef.current.value]);
                tagRef.current.value = "";
            }
        }else{
            setTags([...tags, tagRef.current.value]);
            tagRef.current.value = "";
        }
    };

    return (
        <Box id={props.id}
             sx={{
                 flex:"1",
                 width:"100%"
             }}>

            <form onSubmit={handleOnSubmit} style={{
                width:"100%",
                flex:"1",
                display: 'flex',
                flexDirection:"row",
                flexWrap: 'wrap',
                alignContent: 'flex-start',
                overflowX:'auto',
            }}>
                <TextField
                    inputRef={tagRef}
                    fullWidth
                    variant='standard'
                    size='small'
                    style={{
                        //width:"100%",
                        minWidth:"200px"
                    }}
                    sx={{ margin: "1rem 0" }}
                    margin='none'
                    placeholder={tags.length < 5 ? props.labelTag : ""}
                    InputProps={{
                        startAdornment: (
                            <Box sx={{ margin: "0 0.2rem 0 0", display: "flex", alignItems: 'flex-start'}}>
                                {tags.map((data, index) => {
                                    return (
                                        <Tags data={data} handleDelete={handleDelete} key={index} />
                                    );
                                })}
                            </Box>
                        ),
                    }}
                />
            </form>
        </Box>
    );
}

InputTags.propTypes={
    id:PropTypes.string,
    labelTag:PropTypes.string.isRequired,
    value:PropTypes.arrayOf(PropTypes.string),
    onChange:PropTypes.func.isRequired,
    validator:PropTypes.func,
}

export default InputTags;
