import PropTypes from "prop-types";
import React from "react";
import {Grid, IconButton} from "@mui/material";
import clsx from "clsx";
import {GetIcon, TypeIcon} from "../utils/Icones";
import {cardTemplateStyles} from "../../assets/jss/cardTemplate";


const CardTemplateDataFile=(props)=>{
    const classes = cardTemplateStyles();

    const [hover,setHover]=React.useState(false);

    return (
        <Grid container
              onMouseEnter={()=>setHover(true)}
              onMouseLeave={()=>setHover(false)}
              direction="row"
              className={classes.root}
              onDoubleClick={()=>{
                  if (typeof props.onEdit === "function"){
                    props.onEdit(props.template.id)
                }
              }}
        >
            <Grid item xs={2} sm={1} className={classes.colState}>
                {
                    GetIcon(TypeIcon.CONCEPTION,classes.edit)
                }
            </Grid>
            <Grid item xs={6} sm={9} className={clsx(classes.col,classes.noWrap)}>
                <span>
                    {
                        props.template.name
                    }
                </span>
            </Grid>
            <Grid item xs={2} sm={1} className={classes.colEdit}>
                {
                    hover && (
                        <IconButton
                            edge="start" color="inherit"
                            className={clsx(classes.button)}
                            onClick={()=>{
                                if (typeof props.onEdit === "function"){
                                    props.onEdit(props.template.id)
                                }
                            }}
                        >
                            {GetIcon(TypeIcon.EDIT,classes.edit)}
                        </IconButton>
                    )
                }
            </Grid>
            <Grid item xs={2} sm={1} className={classes.colDelete}>
                {
                    hover && (
                        <IconButton
                            edge="start" color="inherit"
                            className={clsx(classes.button)}
                            onClick={()=>{
                                if (typeof props.onDelete === "function"){
                                    props.onDelete(props.template.id)
                                }
                            }}
                        >
                            {GetIcon(TypeIcon.DELETE,classes.delete)}
                        </IconButton>
                    )
                }
            </Grid>
        </Grid>
    )
}

CardTemplateDataFile.propTypes={
    template:PropTypes.object.isRequired,
    onDelete:PropTypes.func.isRequired,
    onEdit:PropTypes.func.isRequired,
}

export default CardTemplateDataFile;
