import React from "react";
import PropTypes from "prop-types";
import {Grid } from "@mui/material";
import clsx from "clsx";
import {GetIcon, TypeIcon} from "../utils/Icones";
import {cardTemplateStyles} from "../../assets/jss/cardTemplate";

const getIconFromType=(typeTemplate)=>{
    switch (typeTemplate){
        case "document" : return TypeIcon.DOCUMENT;
        case "user"     : return TypeIcon.USER;
        case "email"    : return TypeIcon.EMAIL;
        case "sms"      : return TypeIcon.SMS;
        default         : throw new Error("typeTemplate not defined")

    }
}

const getTextFromType=(typeTemplate,template)=>{
    switch (typeTemplate){
        case "document" : return template.name;
        case "user"     : return template.name+" "+template.firstname+" ["+template.login+"]";
        case "email"    : return template.name;
        case "sms"      : return template.name;
        default         : throw new Error("typeTemplate not defined")

    }
}

const CardTemplate=(props)=>{

    const classes = cardTemplateStyles();

    return (
        <Grid
              container
              direction="row"
              className={classes.root}
        >
            <Grid item xs={2}  sm={1} className={classes.colState}>
                {
                    GetIcon(getIconFromType(props.typeTemplate),classes.edit)
                }
            </Grid>
            <Grid item xs={10} sm={11} className={clsx(classes.col,classes.noWrap)}>
                <span>
                    {
                        getTextFromType(props.typeTemplate,props.template)
                    }
                </span>
            </Grid>
        </Grid>
    )
}

CardTemplate.propTypes={
    typeTemplate:PropTypes.string.isRequired,
    template:PropTypes.object.isRequired,
}

export default CardTemplate;
