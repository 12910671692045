
import React, {Component} from "react";
import {Alert, Box, Snackbar} from "@mui/material";

export const SnackBarContext = React.createContext({
    info:(msg)=>{console.log(msg)},
    error:(msg)=>{console.log(msg)},
    warning:(msg)=>{console.log(msg)},
    success:(msg)=>{console.log(msg)},
});

export const TypeSnackBarMessage = Object.freeze({
    INFO: "info",
    WARNING: "warning",
    ERROR: "error",
    SUCCESS: "success"
});
/*
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
*/
export default class MessageSnackBar extends Component{
    constructor(props) {
        super(props);

        this.state = {
            open:false,
            msg:false,
            during:5000,
            snackBar:{
                info:this.info,
                error:this.error,
                warning:this.warning,
                success:this.success,
            }
        };

        this.handleCloseSnackbarMessageMessage=this.handleCloseSnackbarMessageMessage.bind(this);
    }

    handleCloseSnackbarMessageMessage = (event, reason)=>{
        if (reason === 'clickaway') {
            return;
        }
        this.setState({
            open:false,
            type:TypeSnackBarMessage.SUCCESS,
            msg:false,
            during:5000,
        });
    }

    info=(msg)=>{
        this._messageSnackBar(TypeSnackBarMessage.INFO,msg);
    }
    error=(msg)=>{
        this._messageSnackBar(TypeSnackBarMessage.ERROR,msg);
    }
    warning=(msg)=>{
        this._messageSnackBar(TypeSnackBarMessage.WARNING,msg);
    }
    success=(msg)=>{
        this._messageSnackBar(TypeSnackBarMessage.SUCCESS,msg);
    }

    _messageSnackBar=(type,msg)=>{
        this.setState({
            open:true,
            type:type,
            msg:msg
        })
    }

    render() {
        return(
            <Box style={{ flex:'1',maxWidth:'100%'}}>
                <Snackbar
                    open={(this.state.open)}
                    autoHideDuration={this.state.during}
                    onClose={this.handleCloseSnackbarMessageMessage}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert onClose={this.handleCloseSnackbarMessageMessage} severity={this.state.type}>
                        {this.state.msg}
                    </Alert>
                </Snackbar>
                <SnackBarContext.Provider value={this.state.snackBar}>
                    <Box style={{height:"100%"}}>{this.props.children}</Box>
                </SnackBarContext.Provider>
            </Box>
        )
    }
}
