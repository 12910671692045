

export const isValidEmailAddress=(emailAddress) =>{

    // eslint-disable-next-line no-control-regex
    const pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return pattern.test(emailAddress);
}

export const isValidAdresseEmailInputTas=(emailAddress)=>{
    const pattern=/^([A-Za-z'àáâãäåçèéêëìíîïòóôõöùúûüÿÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÒÓÔÕÖÙÚÛÜŸ -]*) <([a-zA-Z0-9àáâãäåçèéêëìíîïòóôõöùúûüÿÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÒÓÔÕÖÙÚÛÜŸ.@_]*)>$/g;
    if (pattern.test(emailAddress)){
        return true;
    }

    return isValidEmailAddress(emailAddress);
}

export const isValidTelephoneNumber=(number) =>{
    return (typeof number !== "undefined") && number !== null && number !== '' ? /^(0|\+33)[1-9]([-. ]?[0-9]{2}){4}$/.test(number.trim()) : false;
}

export const transformeFormatEmailDoDisplayInputTags=(list)=>{
    if (list && Array.isArray(list) && list.length>0){
        return list.map(item=>{
            if (item.name){
                return item.name+" <"+item.email+">";
            }else{
                return item.email;
            }
        });
    }
    return [];
}


export const transformeDisplayInputTagsToFormatEmail=(list)=>{
    if (list && Array.isArray(list) && list.length>0){

        //pour chaque élément de la liste
        return list.map(item=>{
            const pattern=/^([A-Za-z'àáâãäåçèéêëìíîïòóôõöùúûüÿÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÒÓÔÕÖÙÚÛÜŸ -]*) <([a-zA-Z0-9àáâãäåçèéêëìíîïòóôõöùúûüÿÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÒÓÔÕÖÙÚÛÜŸ.@_]*)>$/g;

            //Si l'item est du type 'Nom PRENOM <adresse email>'
            if (pattern.test(item)){

                //const tags=pattern.exec(item);
                const tags=item.split(" <");

                return {
                    name:tags[0],
                    email:tags[1].replace(">","")
                }
            }else{
                return {
                    name:"",
                    email:item
                }
            }
        })
    }

    return [];
}
