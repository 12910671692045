import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {capitalizeFirstLetter} from "../../common/tools";
import Templates from "./Templates";
import {ServiceTemplateUser} from "../../services/ServiceTemplateUser";
import {ServiceTemplateDocument} from "../../services/ServiceTemplateDocument";
import {ServiceTemplateEmail} from "../../services/ServiceTemplateEmail";
import {ServiceTemplateSms} from "../../services/ServiceTemplateSms";
import DataFiles from "./DataFiles";

const useStyles = makeStyles((theme) => ({
    root: {
        display:"flex",
        flexDirection:"column",
        width:'100%',
        maxWidth:'100%',
        height: '100%',
        maxHeight: '100%',
        overflow:"hidden",
    },
    tabPanel:{
        height: 'calc(100% - 49px)',
        overflow: 'auto',
    }
}));

const Repository=(props)=>{
    const appContext = props.appContext;

    const classes=useStyles();
    const {t} = useTranslation();

    const [value, setValue] = React.useState('document');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return(
        <Box className={classes.root}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="tab section">
                        <Tab label={capitalizeFirstLetter(t('comee.repository.document.title'))} value="document" />
                        <Tab label={capitalizeFirstLetter(t('comee.repository.dataFile.title'))} value="dataFile" />
                        <Tab label={capitalizeFirstLetter(t('comee.repository.email.title'))} value="email" />
                        <Tab label={capitalizeFirstLetter(t('comee.repository.sms.title'))} value="sms" />
                        <Tab label={capitalizeFirstLetter(t('comee.repository.user.title'))} value="user" />
                    </TabList>
                </Box>
                <TabPanel value="document" className={classes.tabPanel}>
                    <Templates appContext={props.appContext}
                               organization={props.organization}
                               filterTemplate={(templates,filter)=>{
                                   if (filter && templates && Array.isArray(templates)){
                                       return templates.filter(items=> {
                                           if (items.name?.toLowerCase().includes(filter.toLowerCase())) {
                                               return true;
                                           }
                                           return false;
                                       })

                                   }else{
                                       return templates;
                                   }
                               }}
                               serviceTemplate={new ServiceTemplateDocument(props.organization.id,appContext.enterprise)}
                               typeTemplate={"document"}
                    />
                </TabPanel>
                <TabPanel value="dataFile" className={classes.tabPanel}>
                    <DataFiles enterpriseId={props.enterpriseId} organization={props.organization}/>
                </TabPanel>
                <TabPanel value="email" className={classes.tabPanel}>
                    <Templates appContext={props.appContext}
                               organization={props.organization}
                               filterTemplate={(templates,filter)=>{
                                   if (filter && templates && Array.isArray(templates)){
                                       return templates.filter(items=> {
                                           if (items.name?.toLowerCase().includes(filter.toLowerCase())) {
                                               return true;
                                           }
                                           return false;
                                       })

                                   }else{
                                       return templates;
                                   }
                               }}
                               serviceTemplate={new ServiceTemplateEmail(props.organization.id,appContext.enterprise)}
                               typeTemplate={"email"}
                    />
                </TabPanel>
                <TabPanel value="sms" className={classes.tabPanel}>
                    <Templates appContext={props.appContext}
                               organization={props.organization}
                               filterTemplate={(templates,filter)=>{
                                   if (filter && templates && Array.isArray(templates)){
                                       return templates.filter(items=> {
                                           if (items.name?.toLowerCase().includes(filter.toLowerCase())) {
                                               return true;
                                           }
                                           return false;
                                       })

                                   }else{
                                       return templates;
                                   }
                               }}
                               serviceTemplate={new ServiceTemplateSms(props.organization.id,appContext.enterprise)}
                               typeTemplate={"sms"}
                    />
                </TabPanel>
                <TabPanel value="user" className={classes.tabPanel}>
                    <Templates appContext={props.appContext}
                               organization={props.organization}
                               filterTemplate={(templates,filter)=>{
                                   if (filter && templates && Array.isArray(templates)){
                                       return templates.filter(items=> {
                                           if (items.name?.toLowerCase().includes(filter.toLowerCase())) {
                                               return true;
                                           }
                                           return false;
                                       })

                                   }else{
                                       return templates;
                                   }
                               }}
                               serviceTemplate={new ServiceTemplateUser(props.organization.id,appContext.enterprise)}
                               typeTemplate={"user"}
                    />
                </TabPanel>

            </TabContext>
        </Box>
    )
}

Repository.propTypes = {
    organization:PropTypes.object.isRequired
}

export default Repository;
