import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import ErrorArea from "../../common/ErrorArea";
import {capitalizeFirstLetter} from "../../common/tools";
import {Box, Grid, Paper, TextField} from "@mui/material";
import EmptyArea from "../../common/EmptyArea";
import {listTemplateStyles} from "../../assets/jss/listTemplate";
import Loader from "../shared/Loader";
import CardTemplate from "./CardTemplate";
import NoEditTemplateInfo from "./NoEditTemplateInfo";
import {FirebaseConstraintsGenerator, OperatorType} from "../../services/ServiceFireBase";

const Templates=(props)=>{
    const classes=listTemplateStyles();

    const {t} = useTranslation();

    const [templates,setTemplates]= React.useState([])
    const [filter,setFilter]= React.useState('')

    const [loading,setLoading]= React.useState(true)
    const [loadFailed,setLoadFailed]= React.useState(false)

    useEffect(() => {
        const queryConstraints=new FirebaseConstraintsGenerator();
        queryConstraints.addWhere("org",OperatorType.EQUAL,props.organization.code)

        props.serviceTemplate.list(queryConstraints).then(list=>{
            setTemplates(list);
            setLoadFailed(false);
            setLoading(false);
        }).catch(()=>{
            setLoading(false);
            setLoadFailed(true);
        });

    }, [props.organization]);




    if (loading){
        return (
            <Loader color={"primary"}/>
        )
    }

    if (loadFailed ){
        return (
            <ErrorArea label={capitalizeFirstLetter(t('comee.repository.'+props.typeTemplate+'.loading'))} />
        )
    }

    let templatesWork=templates;
    if (typeof props.filterTemplate === "function"){
        templatesWork=props.filterTemplate(templates,filter);
    }

    return (
        <Paper className={classes.root}>
            <Box padding={"10px 22px 10px 13px"}>
                <NoEditTemplateInfo typeTemplate={props.typeTemplate}/>
            </Box>
            <div className={classes.lineFilter}>
                <TextField
                    label= {capitalizeFirstLetter(t('comee.repository.'+props.typeTemplate+'.searchPlaceHolder'))}
                    margin="dense"
                    value={filter}
                    onChange={(e)=>setFilter(e.target.value)}
                    className={classes.sectionSearch}
                    fullWidth
                />
            </div>

            <div className={classes.contentHome}>
                {
                    templatesWork?.length ?
                        <Grid
                            container
                            direction="row"
                            spacing={1}
                        >
                            {
                                templatesWork.map(template=><CardTemplate key={template.id} template={template} typeTemplate={props.typeTemplate}/>)
                            }
                        </Grid>
                        :
                        <EmptyArea label={capitalizeFirstLetter(t('comee.repository.document.empty'))} />
                }
            </div>
        </Paper>
    )
}

Templates.propTypes = {
    appContext:PropTypes.object.isRequired,
    organization:PropTypes.object.isRequired,
    filterTemplate:PropTypes.func.isRequired,
    typeTemplate:PropTypes.string.isRequired,
    serviceTemplate:PropTypes.object.isRequired,
}

export default Templates;
