import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import {capitalizeFirstLetter} from "../../common/tools";
import {Typography} from "@mui/material";
import {GetIcon, TypeIcon} from "../utils/Icones";
import PropTypes from "prop-types";

export const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow:"1px 1px 3px lightgrey",
        display:"flex",
        flexDirection:"row"
    },
    iconArea:{
        width: "80px",
        height: "100%",
        margin: "auto",
        textAlign: "center",
    },
    icon: {
        fontSize:"2rem",
        color:theme.status.warn
    },
    msgArea:{
        flex:1,
        padding:"10px"
    },
}));

const NoEditTemplateInfo = (props) => {

    const classes = useStyles();

    const {t} = useTranslation();

    return (
        <Box className={classes.root}>
            <Box className={classes.iconArea}>
                {
                    GetIcon(TypeIcon.WARNING,classes.icon)
                }
            </Box>
            <Box className={classes.msgArea}>
                <Typography>
                    {capitalizeFirstLetter(t("comee.repository.NoEditTemplateInfo.msg1_"+ props.typeTemplate))}
                </Typography>
                <Typography>
                    {capitalizeFirstLetter(t("comee.repository.NoEditTemplateInfo.msg2"))}
                </Typography>
            </Box>
        </Box>
    )
}

NoEditTemplateInfo.propTypes = {
    typeTemplate:PropTypes.string.isRequired,
}
export default NoEditTemplateInfo;
