import {completeSectionList, TypeSection} from "./listSection";

export function getSectionFromCode(key){

    const resultArray=completeSectionList.filter(s=>s.key===key);
    if (resultArray && resultArray.length>0){
        return resultArray[0];
    }

   return null;
}
export const sectionListForUser=function(appContext){
    let sectionList=[];

    sectionList.push(getSectionFromCode(TypeSection.HOME));

    sectionList.push(getSectionFromCode(TypeSection.REPOSITORY));

    // SECTION ADMIN
    if (appContext?.user?.profile?.admin){
        sectionList.push(getSectionFromCode(TypeSection.ADMIN));
    }

    return sectionList;
}
