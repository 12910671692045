import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import {AppContext} from "../login/Login";
import {
    AppBar,
    Box,
    Dialog,
    DialogContent, DialogTitle, IconButton, Toolbar, Typography
} from "@mui/material";

import {capitalizeFirstLetter} from "../../common/tools";
import Loader from "../shared/Loader";
import ErrorArea from "../../common/ErrorArea";
import Iframe from "react-iframe";
import {GetIcon, TypeIcon} from "../utils/Icones";
import Avatar from '@mui/material/Avatar';

const {makeStyles} = require("@mui/styles");
const {useContext} = require("react");
const {useTranslation} = require("react-i18next");

const useStyles = makeStyles((theme) => ({
    root:{
        display:"flex",
        flexDirection:"column",
    },
    dialogPaper: {
        minHeight: '90vh',
        maxHeight: '90vh',
    },
    content: {
        height: '300px',
    },
    iframe:{
        boxShadow:'0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    bt: {
        marginRight: '30px',
    },
}));

const DialogCommunication = (props) => {
    //chargement des contextes
    const appContext = useContext(AppContext);

    //chargement des styles
    const classes = useStyles();

    //chargement i18n
    const {t} = useTranslation();

    //définition des variables d'états
    const [loading,setLoading]= React.useState(true)
    const [loadFailed,setLoadFailed]= React.useState(false)
    const [dialogLoaded,setDialogLoaded]= React.useState(true)

    const [url,setUrl]=React.useState("");

    //initialisation
    useEffect(() => {

        //création de l'url d'appel de l'iframe
        const urlTmp=appContext.enterprise.url+
            "/docprint-light-web/EntryDebug?"+"org="+
            props.organization.code+"&user="+props.user+
            (props.id ? "&id="+props.id : "");

        setUrl(urlTmp)
        setLoading(false);
    },[])

    const getContent=()=>{
        if (loading){
            return (
                <Loader color={"primary"}/>
            )
        }

        if (loadFailed ){
            return (
                <ErrorArea label={capitalizeFirstLetter(t('comee.home.errorLoadOne'))} />
            )
        }

        return (
                <Iframe
                        url={url}
                        frameBorder={0}
                        width="100%"
                        height="100%"
                        display="block"
                        position="relative"
                        className={classes.iframe}
                        onLoad={(iframe)=>{
                            /*
                            try {
                                if (iframe && iframe.currentTarget) {
                                    const href=iframe.currentTarget.contentWindow.location.href;
                                }
                            }
                            catch(err){
                                setLoadFailed(true);
                            }*/
                        }}

                />
        )
    }

    const handleClose = () =>{
        if (typeof props.onClose === "function"){
            props.onClose();
        }
    }

    const handleOpenInNew=()=>{
        handleClose();
        window.open(url)
    }
    return (
        <Dialog open={true}
                fullWidth={true}
                maxWidth={false}
                onClose={handleClose}
                classes={{ paper: classes.dialogPaper }}
                onLoad={()=>setDialogLoaded(true)}

        >

            <DialogTitle>
                <AppBar sx={{ position: 'relative' }} color={"secondary"}>
                    <Toolbar>
                        <Avatar alt={t('comee.comee-communication')} src="/images/favicon_comee.png" />
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            { capitalizeFirstLetter(t('comee.home.communication.title'))}
                        </Typography>
                        <Box onClick={handleOpenInNew} className={classes.bt}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="close"
                            >
                                {GetIcon(TypeIcon.OPEN_IN_NEW)}
                            </IconButton>
                        </Box>
                        <Box onClick={handleClose}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="close"
                            >
                                {GetIcon(TypeIcon.CLOSE)}
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>


            </DialogTitle>
            <DialogContent className={classes.content}>
                {
                    dialogLoaded &&  getContent()
                }
            </DialogContent>
        </Dialog>
    )

}



DialogCommunication.propTypes = {
    user:PropTypes.string.isRequired,
    organization:PropTypes.object.isRequired,
    id:PropTypes.string,
    type:PropTypes.string,
    onClose:PropTypes.func.isRequired,
}

export default DialogCommunication;
