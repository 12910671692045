import React, {useContext, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import ErrorArea from "../../common/ErrorArea";
import {capitalizeFirstLetter} from "../../common/tools";
import {Button, Grid, Paper, TextField, Tooltip, Zoom} from "@mui/material";
import EmptyArea from "../../common/EmptyArea";
import CardTemplateDataFile from "./CardTemplateDataFile";
import DialogTemplateDataFile from "./DialogTemplateDataFile";
import DialogConfirmDelete from "../shared/DialogConfirmDelete";
import {SnackBarContext} from "../shared/MessageSnackBar";
import {listTemplateStyles} from "../../assets/jss/listTemplate";
import Loader from "../shared/Loader";
import {ServiceTemplateDataFile} from "../../services/ServiceTemplateDataFile";
import {FirebaseConstraintsGenerator, OperatorType, TypeOperateor} from "../../services/ServiceFireBase";


const filterTemplate=(templates,filter)=>{
    if (filter && templates && Array.isArray(templates)){
        return templates.filter(items=> {
            if (items.name?.toLowerCase().includes(filter.toLowerCase())) {
                return true;
            }
            return false;
        })

    }else{
        return templates;
    }
}

const DataFiles=(props)=>{

    const appSnackBar = useContext(SnackBarContext);

    const classes=listTemplateStyles();

    const {t} = useTranslation();

    const [boxNew,setBoxNew]= React.useState(false)
    const [templates,setTemplates]= React.useState([])
    const [templateEdit,setTemplateEdit]= React.useState(null)
    const [filter,setFilter]= React.useState('')

    const [loading,setLoading]= React.useState(true)
    const [loadFailed,setLoadFailed]= React.useState(false)

    const [deleteId,setDeleteId]= React.useState(null)

    //service contact
    const [serviceTemplate]= React.useState(new ServiceTemplateDataFile(props.enterpriseId))

    const init=useRef(0);

    useEffect(() => {

        // Mise en place du filtre lié a l'organisation
        const queryConstraints=new FirebaseConstraintsGenerator();
        queryConstraints.addWhere("org",OperatorType.EQUAL,props.organization.code)


        // Link les méthodes de synchronisation des modèles de communication
        serviceTemplate.onSnapshot(syncSnapshotSuccess,syncSnapshotError,queryConstraints);

        // Suppression de la souscription sur la synchronisation des contacts
        return () => serviceTemplate.destructor();

    }, []);

    useEffect(async () => {
        if (init.current){
            await serviceTemplate.destructor();

            const queryConstraints=new FirebaseConstraintsGenerator();
            queryConstraints.addWhere("org",OperatorType.EQUAL,props.organization.code)

            //link les méthode de syncronisation des template communication
            serviceTemplate.onSnapshot(syncSnapshotSuccess,syncSnapshotError,queryConstraints);
        }
        init.current++;

    },[props.organization]);

    const syncSnapshotSuccess=(docs)=>{
        setTemplates(docs)
        setLoading(false);
    }
    const syncSnapshotError=()=>{
        setLoadFailed(true)
    }

    if (loading){
        return (
            <Loader color={"primary"}/>
        )
    }

    if (loadFailed ){
        return (
            <ErrorArea label={capitalizeFirstLetter(t('comee.repository.dataFile.loading'))} />
        )
    }

    const templatesWork=filterTemplate(templates,filter);

    return (
        <Paper className={classes.root}>
            <div className={classes.lineFilter}>
                <TextField
                    label= {capitalizeFirstLetter(t('comee.repository.dataFile.searchPlaceHolder'))}
                    margin="dense"
                    value={filter}
                    onChange={(e)=>setFilter(e.target.value)}
                    className={classes.sectionSearch}
                    fullWidth
                />
                <Tooltip
                    key={"bt_new"}
                    title={capitalizeFirstLetter(t('comee.bt.new'))}
                    TransitionComponent={Zoom} arrow>
                    <Button variant="contained" color="primary" disableElevation onClick={()=>setBoxNew(true)} className={classes.btNew}>
                        {capitalizeFirstLetter(t('comee.bt.new'))}
                    </Button>
                </Tooltip>
            </div>
            <div className={classes.contentHome}>
                {
                    templatesWork?.length ?
                        <Grid
                            container
                            direction="row"
                            spacing={1}
                        >
                            {
                                templatesWork.map(tc=><CardTemplateDataFile
                                    key={tc.id}
                                    template={tc}
                                    onDelete={(templateId)=>{
                                        setDeleteId(templateId);
                                    }}
                                    onEdit={(templateId)=>{
                                        setTemplateEdit(templates.find(item=>item.id===templateId))
                                        setBoxNew(true);
                                    }}
                                    />)
                            }
                        </Grid>
                        :
                        <EmptyArea label={capitalizeFirstLetter(t('comee.repository.document.empty'))} />
                }
            </div>
            {
                boxNew && (
                    <DialogTemplateDataFile
                        organization={props.organization}
                        id={templateEdit?.id}
                        template={templateEdit}
                        enterpriseId={props.enterpriseId}
                        open={boxNew}
                        onCancel={()=>{
                            setBoxNew(false)
                            setTemplateEdit(null)
                        }}
                    />
                )
            }
            {
                deleteId!==null && (
                    <DialogConfirmDelete
                        title={capitalizeFirstLetter(t('comee.repository.dataFile.boxDelete.title'))}
                        msg1={capitalizeFirstLetter(t('comee.repository.dataFile.boxDelete.msg1')).replace("{name}",templates.find(g=>g.id===deleteId)?.name)}
                        msg2={capitalizeFirstLetter(t('comee.repository.dataFile.boxDelete.msg2'))}
                        onCancel={()=>setDeleteId(null)}
                        onDelete={()=>{
                            serviceTemplate.delete(deleteId).then(()=>{
                                appSnackBar.success(capitalizeFirstLetter(t('comee.repository.dataFile.boxDelete.success')))
                                setDeleteId(null)
                            },()=>{
                                appSnackBar.error(capitalizeFirstLetter(t('comee.repository.dataFile.boxDelete.failed')))
                                setDeleteId(null)
                            })
                        }}
                    />
                )
            }
        </Paper>
    )
}

DataFiles.propTypes = {
    organization:PropTypes.object.isRequired,
    enterpriseId:PropTypes.string.isRequired,
}

export default DataFiles;
