import React from 'react';

import Home from '../home/Home'
import Repository from "../repository/Repository";
import Admin from '../admin/Admin'

import {GetIcon, TypeIcon} from "../utils/Icones";


export const TypeSection = Object.freeze({
    HOME: "home",
    REPOSITORY: "repository",
    ADMIN: "admin",
});

export const completeSectionList=[{
        key:TypeSection.HOME,
        icon:GetIcon(TypeIcon.HOME),
        content:(appContext,appSnackBar,handleModify,enterpriseId,organization)=> <Home appContext={appContext} appSnackBar={appSnackBar} handleModify={handleModify} enterpriseId={enterpriseId} organization={organization} />
    },{
        key:TypeSection.REPOSITORY,
        icon:GetIcon(TypeIcon.SETTINGS),
        content:(appContext,appSnackBar,handleModify,enterpriseId,organization)=> <Repository appContext={appContext} appSnackBar={appSnackBar} handleModify={handleModify} enterpriseId={enterpriseId} organization={organization} />
    },{
        key:TypeSection.ADMIN,
        icon:GetIcon(TypeIcon.ADMIN),
    content:(appContext,appSnackBar,handleModify,enterpriseId,organization)=> <Admin appContext={appContext} appSnackBar={appSnackBar} handleModify={handleModify} enterpriseId={enterpriseId} organization={organization} />
    }
];

