import {Box, Button, FormControlLabel, Paper, Switch, TextField} from "@mui/material";
import React, {useContext, useEffect} from "react";
import {SnackBarContext} from "../shared/MessageSnackBar";
import {capitalizeFirstLetter} from "../../common/tools";
import {ServiceEnterprise} from "../../services/ServiceEnterprise";
import ErrorArea from "../../common/ErrorArea";
import Loader from "../shared/Loader";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {AppContext} from "../login/Login";
import _ from "lodash";

export const useStyles = makeStyles((theme) => ({
    root: {
        display:"flex",
        flexDirection:"column",
        width:'100%',
        maxWidth:'100%',
        height: '100%',
        maxHeight: '100%',
        overflow:"hidden",
        borderRadius:"0",
        boxShadow:"none",
    },
    itemArea:{
        flex:1,
        marginTop:theme.spacing(2),
        marginLeft:theme.spacing(2),
        marginRight:theme.spacing(2),

        '&:first-child':{
            //marginLeft:0,
        },

        '&:last-child':{
            //marginRight:0,
            marginBottom:theme.spacing(2),
        }
    },
    btArea:{
        paddingLeft:'20px',
        marginTop:theme.spacing(2),
    },
    btEdit:{
        color:theme.palette.secondary.main,
        flexDirection: 'column',
        justifyContent: 'left',
        cursor:"pointer",
        paddingLeft:'20px',
        marginTop:theme.spacing(2),
        marginLeft:theme.spacing(2),

    },
    itemRow:{
        marginTop:theme.spacing(3),
    }
}));


const Admin=(props)=>{

    const classes=useStyles();
    const {t} = useTranslation();

    const appSnackBar = useContext(SnackBarContext);
    const appContext = useContext(AppContext);

    const [serviceEnterprise]= React.useState(new ServiceEnterprise())
    const [enterprise,setEnterprise] = React.useState(null);
    const [enterpriseBackup,setEnterpriseBackup] = React.useState(null);

    const [loading,setLoading]= React.useState(true)
    const [loadFailed,setLoadFailed]= React.useState(false)
    const [displayError] = React.useState(true)
    const [readOnly,setReadOnly] = React.useState(true)
    const [modify,setModify] = React.useState(false)

    useEffect(() => {
        serviceEnterprise.document(props.enterpriseId).then((ent)=>{
            setEnterpriseBackup(ent)
            setEnterprise(ent);
            setLoading(false);
        },()=>{
            setLoading(false);
            setLoadFailed(true);
        })
    },[])

    useEffect(() => {
        if (enterprise && enterpriseBackup){
            setModify(JSON.stringify(enterprise) !== JSON.stringify(enterpriseBackup));
        }

    },[enterprise])

    if (loading){
        return (
            <Loader color={"primary"}/>
        )
    }

    if (loadFailed){
        return (
            <ErrorArea label={capitalizeFirstLetter(t('comee.admin.errorLoad'))} />
        )
    }

    const handleAction=(action)=>{
        switch (action){
            case "edit":
                setReadOnly(false)
                break;
            case "cancel":
                setReadOnly(true)
                break;
            case "apply":
                serviceEnterprise.update(enterprise).then((ent)=>{
                    setEnterpriseBackup(ent);
                    setEnterprise(ent);
                    setReadOnly(true);
                    appSnackBar.success(capitalizeFirstLetter(t('comee.admin.saveSuccess')))

                    //mise a jour par référence de l'objet appContext
                    appContext.enterprise=_.cloneDeep(ent);
                },()=>{
                    appSnackBar.error(capitalizeFirstLetter(t('comee.admin.saveFailed')))
                })
                break;
            default: throw new Error("action not defined")
        }
    }

    return(
        <Paper className={classes.root}>
            <Box>
                {
                    readOnly ? (
                        <div className={classes.btArea}>
                            <Button variant="contained" color="primary" disableElevation onClick={()=>handleAction("edit")} className={classes.btEdit}>
                                {capitalizeFirstLetter(t('comee.bt.edit'))}
                            </Button>
                        </div>
                    ):(
                        <div className={classes.btArea}>
                            <Button variant="contained" color="primary" disableElevation onClick={()=>handleAction("cancel")} className={classes.btEdit}>
                                {capitalizeFirstLetter(t('comee.bt.cancel'))}
                            </Button>
                            <Button variant="contained"
                                    disabled={!modify}
                                    color="primary" disableElevation onClick={()=>handleAction("apply")} className={classes.btEdit}>
                                {capitalizeFirstLetter(t('comee.bt.apply'))}
                            </Button>
                        </div>

                    )
                }

            </Box>
            <Box>
                <div className={classes.itemArea}>
                    <Box>
                        <TextField
                            disabled={readOnly}
                            label={capitalizeFirstLetter(t('comee.admin.url'))}
                            type="text"
                            value={enterprise.url}
                            onChange={(e)=>setEnterprise({
                                ...enterprise,
                                url:e.target.value
                            })}
                            fullWidth
                            variant="outlined"
                            error={displayError && enterprise.url === ""}
                            className={classes.itemRow}
                        />
                    </Box>
                </div>
                <div className={classes.itemArea}>
                    <Box>
                        <TextField
                            disabled={readOnly}
                            label={capitalizeFirstLetter(t('comee.dialogTestApp.apiKey'))}
                            type="text"
                            value={enterprise.apiKey}
                            onChange={(e)=>setEnterprise({
                                ...enterprise,
                                apiKey:e.target.value
                            })}
                            fullWidth
                            variant="outlined"
                            error={displayError && enterprise.apiKey === ""}
                            className={classes.itemRow}
                        />
                    </Box>
                </div>
                <div className={classes.itemArea}>
                    <FormControlLabel
                        disabled={readOnly}
                        control={
                            <Switch
                                checked={enterprise.openCommunicaionAfterCreated === true}
                                onChange={(e)=>setEnterprise({
                                    ...enterprise,
                                    openCommunicaionAfterCreated:enterprise.openCommunicaionAfterCreated !== true
                                })}
                                name="openCommunicaionAfterCreated" />
                        }
                        label={capitalizeFirstLetter(t("comee.admin.openCommunicaionAfterCreated"))}
                    />
                </div>
            </Box>

        </Paper>
    )
}

Admin.propTypes = {
    enterpriseId:PropTypes.string.isRequired,
}

export default Admin;
