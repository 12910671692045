
export const generateTemplateCommunicationEmpty=()=>{

    const tc= {
        "identification": {
            "idUser": "",
            "organization": "",
            "name": "",
            "firstName": "",
            "emailAdr": "",
            "groupe": []
        },
        "communication": {
            "idFunc": "",
            "typeCommunication": "M",
            "modeProcessing": "I",
            "name": "",
            "composer": "",
            "reference": "",
            "input": {
                "inputDesktop": {
                    "available": true,
                    "typeFile": ["pdf", "doc"]
                },
                "inputTemplate": {
                    "available": true,
                },
                "inputGed": {
                    "available": true,
                    "metadata": [
                        {
                            "name": "numSinistre",
                            "value": "1"
                        }
                    ]
                }
            },
            "content": {},
            "delivery": {
                "centralPrint": {
                    "mode": "ENABLED",
                    "enabled": false,
                    "deliveryName": "CENTRAL_PRINT_1",
                    "postage": "AN",
                    "delay": null,
                    "dateSend": null
                },
                "localPrint": {
                    "mode": "ENABLED",
                    "enabled": false,
                    "deliveryName": "LOCAL_PRINT_1",
                    "byImprServer": false,
                    "delay": null,
                    "dateSend": null
                },
                "sms": {
                    "mode": "ENABLED",
                    "enabled": false,
                    "deliveryName": "SMS_1",
                    "number": [],
                    "delay": null,
                    "dateSend": null
                },
                "fax": {
                    "mode": "ENABLED",
                    "enabled": false,
                    "deliveryName": "SMS_1",
                    "number": [],
                    "delay": null,
                    "dateSend": null
                },
                "email": {
                    "mode": "ENABLED",
                    "enabled": false,
                    "deliveryName": "EMAIL_1",
                    "addrEmail": [],
                    "ccDisplay": false,
                    "addrEmailCc": [],
                    "cciDisplay": false,
                    "addrEmailCci": [],
                    "from": "",
                    "fromName": "",
                    "delay": null,
                    "dateSend": null
                },
                "generic": {
                    "mode": "ENABLED",
                    "enabled": false,
                    "deliveryName": "GENERIC_1",
                    "fileName": "delivery-generic.pdf",
                    "delay": null,
                    "dateSend": null
                },
                "ged": [
                    {
                        "mode": "ENABLED",
                        "enabled": false,
                        "deliveryName": "GED_CENTRAL_PRINT_1",
                        "typeDelivery": "CENTRALPRINT",
                        "reference": [{
                            "name": "nom variable Central Print 1",
                            "value": "valeur variable Central Print 1",
                        }, {
                            "name": "nom variable Central Print 2",
                            "value": "valeur variable Central Print 2",
                        }],
                        "delay": null,
                        "dateSend": null
                    }, {
                        "mode": "ENABLED",
                        "enabled": false,
                        "deliveryName": "GED_LOCAL_PRINT_1",
                        "typeDelivery": "LOCALPRINT",
                        "reference": [{
                            "name": "nom variable Local Print 1",
                            "value": "valeur variable Local Print 1",
                        }, {
                            "name": "nom variable Local Print 2",
                            "value": "valeur variable Local Print 2",
                        }],
                        "delay": null,
                        "dateSend": null
                    }, {
                        "mode": "ENABLED",
                        "enabled": false,
                        "deliveryName": "GED_SMS_1",
                        "typeDelivery": "SMS",
                        "reference": [{
                            "name": "nom variable SMS 1",
                            "value": "valeur variable SMS 1",
                        }, {
                            "name": "nom variable SMS 2",
                            "value": "valeur variable SMS 2",
                        }],
                        "delay": null,
                        "dateSend": null
                    }, {
                        "mode": "ENABLED",
                        "enabled": false,
                        "deliveryName": "GED_FAX_1",
                        "typeDelivery": "FAX",
                        "reference": [{
                            "name": "nom variable FAX 1",
                            "value": "valeur variable FAX 1",
                        }, {
                            "name": "nom variable FAX 2",
                            "value": "valeur variable FAX 2",
                        }],
                        "delay": null,
                        "dateSend": null
                    },{
                        "mode": "ENABLED",
                        "enabled": false,
                        "deliveryName": "GED_EMAIL_1",
                        "typeDelivery": "EMAIL",
                        "reference": [{
                            "name": "nom variable EMAIL 1",
                            "value": "valeur variable EMAIL 1",
                        }, {
                            "name": "nom variable EMAIL 2",
                            "value": "valeur variable EMAIL 2",
                        }],
                        "delay": null,
                        "dateSend": null
                    },{
                        "mode": "ENABLED",
                        "enabled": false,
                        "deliveryName": "GED_GENERIC_1",
                        "typeDelivery": "GENERIC",
                        "reference": [{
                            "name": "nom variable GENERIC 1",
                            "value": "valeur variable GENERIC 1",
                        }, {
                            "name": "nom variable GENERIC 2",
                            "value": "valeur variable GENERIC 2",
                        }],
                        "delay": null,
                        "dateSend": null
                    }, {
                        "mode": "ENABLED",
                        "enabled": false,
                        "deliveryName": "GED_GED_1",
                        "typeDelivery": "GED",
                        "reference": [{
                            "name": "nom variable GED 1",
                            "value": "valeur variable GED 1",
                        }, {
                            "name": "nom variable GED 2",
                            "value": "valeur variable GED 2",
                        }],
                        "delay": null,
                        "dateSend": null
                    }],
            }
        }
    };

    return tc;
}
