import React from 'react';

import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import LocalPrintPrintIcon from "@mui/icons-material/PrintOutlined";
import CentralPrintIcon from "@mui/icons-material/DomainOutlined";
import EmailIcon from "@mui/icons-material/AlternateEmailOutlined";
import SmsIcon from "@mui/icons-material/SmsOutlined";
import FaxIcon from "@mui/icons-material/LocalPrintshopOutlined";
import GedIcon from "@mui/icons-material/ArchiveOutlined";
import GenericIcon from "@mui/icons-material/ArchiveOutlined";
import SignIcon from "@mui/icons-material/ArchiveOutlined";
import ExpandMore from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLess from "@mui/icons-material/ExpandLessOutlined";

import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import AttachFileIcon from '@mui/icons-material/DesktopMacOutlined';
import DocprintIcon from '@mui/icons-material/AppsOutlined';
//import DocprintIcon from '../../../images/docprint.svg';
//import { ReactComponent as DocprintIcon } from '../../../images/docprint.svg';
import UnarchiveIcon from '@mui/icons-material/UnarchiveOutlined';

import MailIcon from "@mui/icons-material/MailOutlined";
import DoneIcon from '@mui/icons-material/DoneOutlined';
//import DeleteIcon from '@mui/icons-material/Delete';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

//import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteForeverIcon from '@mui/icons-material/DeleteOutlined';

import SettingsIcon from '@mui/icons-material/SettingsOutlined';

import WarningIcon from '@mui/icons-material/WarningOutlined';
import TransitIcon from '@mui/icons-material/DoubleArrowOutlined';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUserOutlined';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmptyOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import BookmarkIcon from '@mui/icons-material/BookmarkOutlined';
import PdfIcon from '@mui/icons-material/PictureAsPdfOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowRightIcon from '@mui/icons-material/ArrowForwardOutlined';
//import GeneralIcon from '@mui/icons-material/AddCircleOutline';
import DeliveryIcon from '@mui/icons-material/CallSplitOutlined';
import AdvancedIcon from '@mui/icons-material/AddCircleOutlined';
import SaveIcon from "@mui/icons-material/SaveOutlined";
import NewIcon from '@mui/icons-material/AddCircleOutlined';
import ExecIcon from '@mui/icons-material/PlayArrowOutlined';
import LockIcon from '@mui/icons-material/LockOutlined';
import PurgeIcon from '@mui/icons-material/LocalLaundryServiceOutlined';
import CategoryIcon from '@mui/icons-material/GroupWorkOutlined';
import UserIcon from '@mui/icons-material/PersonOutlined';
import GroupIcon from '@mui/icons-material/GroupOutlined';

//import WatermarkIcon from '@mui/icons-material/FontDownload';
import WatermarkIcon from '@mui/icons-material/FontDownloadOutlined';

import BackgroundIcon from '@mui/icons-material/CropOriginalOutlined';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import CancelIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import OpenIcon from '@mui/icons-material/LaunchOutlined';
import CloneIcon from '@mui/icons-material/FileCopyOutlined';
import RightIcon from '@mui/icons-material/AccessibilityOutlined';
import GroupAddIcon from '@mui/icons-material/GroupAddOutlined';
import ClearAllIcon from '@mui/icons-material/ClearAllOutlined';
import AddFilterIcon from '@mui/icons-material/QueueOutlined';
import FilterListIcon from '@mui/icons-material/FilterListOutlined';
import CheckBoxNotCheckedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxCheckedIcon from '@mui/icons-material/CheckBoxOutlined';
//import SimplexIcon from '@mui/icons-material/Description';
import SimplexIcon from '@mui/icons-material/DescriptionOutlined';
//import DraftIcon from '@mui/icons-material/PictureAsPdf';
import DraftIcon from '@mui/icons-material/PictureAsPdfOutlined';

//import WizardIcon from '@mui/icons-material/FormatAlignCenter';
import WizardIcon from '@mui/icons-material/BallotOutlined';

import VerticalSplitIcon from '@mui/icons-material/VerticalSplitOutlined';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplitOutlined';
import ListIcon from '@mui/icons-material/ReorderOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';

import StarBorderIcon from '@mui/icons-material/StarBorderOutlined';
import StarIcon from '@mui/icons-material/StarOutlined';

import ViewListIcon from '@mui/icons-material/ViewListOutlined';

import ScheduleIcon from '@mui/icons-material/ScheduleOutlined';
import CloudDownloadIcon from '@mui/icons-material/CloudDownloadOutlined';
import DisconnectNewIcon from '@mui/icons-material/PowerSettingsNewOutlined';

import SendIcon from "@mui/icons-material/SendOutlined";
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircleOutlined';

import UnfoldLessIcon from "@mui/icons-material/UnfoldLessOutlined";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMoreOutlined";

import RefuseIcon from '@mui/icons-material/BlockOutlined';
import BlockIcon from '@mui/icons-material/ReplayOutlined';

import WorkingIcon from '@mui/icons-material/LoopOutlined';
import HistoryIcon from '@mui/icons-material/HistoryOutlined';
import LiveIcon from '@mui/icons-material/LiveTvOutlined';

//import AddressAddIcon from '@mui/icons-material/Contacts';
import AddressAddIcon from '@mui/icons-material/ImportContactsOutlined';

//import AddressPageIcon from '@mui/icons-material/PostAdd';
import AddressPageIcon from '@mui/icons-material/PostAddOutlined';

//import AddressAreaIcon from '@mui/icons-material/Pages';
import AddressAreaIcon from '@mui/icons-material/FormatShapesOutlined';
//import FormatShapesOutlinedIcon from '@mui/icons-material/FormatShapesOutlined';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';

import MergeTypeIcon from '@mui/icons-material/MergeTypeOutlined';
import CallSplitIcon from '@mui/icons-material/CallSplitOutlined';
import ErrorIcon from '@mui/icons-material/ErrorOutlined';

import HomeIcon from "@mui/icons-material/HomeOutlined";
import CodeIcon from "@mui/icons-material/CodeOutlined";

import TreeviewArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TreeviewArrowRightIcon from '@mui/icons-material/ArrowRight';

import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import {
    IconFlagFR,
    IconFlagUK,
    IconFlagDE,

} from 'material-ui-flags';

import ImportExportIcon from '@mui/icons-material/ImportExport';
import ImportIcon from '@mui/icons-material/ArrowBackOutlined';
import ExportIcon from '@mui/icons-material/ArrowForwardOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';

export const TypeIcon = Object.freeze({
    HOME                    : "home",
    CONCEPTION              : "studio",
    FILTER_LIST             : "filter_list",
    DASHBOARD               : "dashboard",
    NEW                     : "new",
    EXEC                    : "exec",
    LOCK                    : "lock",
    OPEN                    : "open",
    CLONE                   : "clone",
    PURGE                   : "purge",
    CATEGORY                : "category",
    USER                    : "user",
    GROUP                   : "group",
    GROUP_ADD               : "group_add",
    MAIL                    : "mail",
    EMAIL                   : "email",
    LOCALPRINT              : "local",
    CENTRALPRINT            : "cenral",
    SMS                     : "sms",
    FAX                     : "fax",
    GED                     : "ged",
    GENERIC                 : "generic",
    SIGN                    : "sign",
    DELETE                  : "delete",
    CLEAR_ALL               : "clear_all",
    CANCEL                  : "cancel",
    CLOSE                   : "close",
    EXPANDLESS              : "expandless",
    EXPANDMORE              : "expandmore",
    DONE                    : "done",
    TRANSIT                 : "transit",
    CHECK_USER              : "checkuser",
    WAITING_FOR_VALIDATION  : "waitforvalidation",
    WAITING_FOR_SIGN        : "waitforsign",
    EDIT                    : "edit",
    BOOKMARK                : "bookmark",
    WARNING                 : "warning",
    PDF                     : "pdf",
    SETTINGS                : "settings",
    ARROW_BACK              : "arrowback",
    ARROW_RIGHT             : "arrowright",
    GENERAL                 : "general",
    DELIVERY                : "delivery",
    ADVANCED                : "advanced",
    SAVE                    : "save",
    WATERMARK               : "watermark",
    BACKGROUND              : "background",
    RIGHT                   : "right",
    ADD_FILTER_ICON         : "add_filter_icon",
    CHECKBOX_NOT_CHECKED    : "checkbox_not_checked",
    CHECKBOX_CHECKED        : "checkbox_checked",

    ATTACHMENT              : "attachment",
    ATTACHMENT_DESKTOP      : "attachment_desktop",
    DOCPRINT                : "docprint",
    UNARCHIVE               : "unarchive",
    SIMPLEX                 : "simplex",
    DRAFT                   : "draft",
    WIZARD                  : "wizard",
    DELETE_2                : "delete2",

    SPLIT_VERTICAL          : "split-vertical",
    SPLIT_HORIZONTAL        : "split-horizontal",
    LIST                    : "list",

    INFO                    : "info",
    STAR                    : "start",
    STAR_BORDER             : "start-border",

    LIST_CORBEILLE          : "liste-corbeille",

    SCHEDULE                : "scheduleIcon",
    CLOUD_DOWNLOAD          : "cloud-download",

    COUNTRY_FR              : "country-fr",
    COUNTRY_UK              : "country-uk",
    COUNTRY_DE              : "country-de",
    DISCONNECT              : "disconnect",

    SEND                    : "send",
    SEND_TO_VALID           : "send-to-valid",

    FULLSCREEN              : "fullscreen",
    WINDOW                  : "window",

    REFUSE                  : "refuse",
    BLOCK                   : "block",

    WORKING                 : "working",
    LIVE                    : "live",
    HISTORY                 : "history",

    ADDRESS_ADD             : "address-add",
    ADDRESS_PAGE            : "address-page",
    ADDRESS_AREA            : "address-area",
    MERGE                   : "merge",
    SPLIT                   : "split",
    ERROR                   : "error",

    TIMELINE                : "timeline",

    TREEVIEW_ARROW_DOWN     : "treeview-arrow-down",
    TREEVIEW_ARROW_RIGHT    : "treeview-arrow-right",

    PREVIEW                 : "preview",
    IMPORT_EXPORT           : "import-export",
    IMPORT                  : "import",
    EXPORT                  : "export",
    OPEN_IN_NEW             : "open-in-new",
    FILE_PRESENT            : "file-present",
    DOCUMENT                : "document",
    ADMIN                   : "admin",
});

export const GetIcon=(typeIcon,className)=>{
    switch (typeIcon){
        case TypeIcon.HOME          : return (<HomeIcon className={className} />);
        case TypeIcon.CONCEPTION    : return (<CodeIcon className={className} />);
        case TypeIcon.FILTER_LIST   : return (<FilterListIcon className={className} />);
        case TypeIcon.DASHBOARD     : return (<DashboardIcon className={className} />);
        case TypeIcon.NEW           : return (<NewIcon className={className} />);
        case TypeIcon.EXEC          : return (<ExecIcon className={className} />);
        case TypeIcon.LOCK          : return (<LockIcon className={className} />);
        case TypeIcon.OPEN          : return (<OpenIcon className={className} />);
        case TypeIcon.CLONE         : return (<CloneIcon className={className} />);
        case TypeIcon.PURGE         : return (<PurgeIcon className={className} />);
        case TypeIcon.CATEGORY      : return (<CategoryIcon className={className} />);
        case TypeIcon.USER          : return (<UserIcon className={className} />);
        case TypeIcon.GROUP         : return (<GroupIcon className={className} />);
        case TypeIcon.GROUP_ADD     : return (<GroupAddIcon className={className} />);
        case TypeIcon.MAIL          : return (<MailIcon className={className} />);
        case TypeIcon.EMAIL         : return (<EmailIcon className={className} />);
        case TypeIcon.LOCALPRINT    : return (<LocalPrintPrintIcon className={className} />);
        case TypeIcon.CENTRALPRINT  : return (<CentralPrintIcon className={className} />);
        case TypeIcon.SMS           : return (<SmsIcon className={className} />);
        case TypeIcon.FAX           : return (<FaxIcon className={className} />);
        case TypeIcon.GED           : return (<GedIcon className={className} />);
        case TypeIcon.GENERIC       : return (<GenericIcon className={className} />);
        case TypeIcon.SIGN          : return (<SignIcon className={className} />);
        case TypeIcon.DELETE        : return (<DeleteIcon className={className} />);
        case TypeIcon.CLEAR_ALL     : return (<ClearAllIcon className={className} />);
        case TypeIcon.CANCEL        : return (<CancelIcon className={className} />);
        case TypeIcon.CLOSE         : return (<CloseIcon className={className} />);
        case TypeIcon.EXPANDLESS    : return (<ExpandLess className={className} />);
        case TypeIcon.EXPANDMORE    : return (<ExpandMore className={className} />);
        case TypeIcon.DONE          : return (<DoneIcon className={className} />);

        case TypeIcon.TRANSIT       : return (<TransitIcon className={className} />);
        case TypeIcon.CHECK_USER    : return (<VerifiedUserIcon className={className} />);
        case TypeIcon.WAITING_FOR_VALIDATION
                                    : return (<HourglassEmptyIcon className={className} />);
        case TypeIcon.WAITING_FOR_SIGN
                                    : return (<HourglassEmptyIcon className={className} />);
        case TypeIcon.EDIT          : return (<EditIcon className={className} />);
        case TypeIcon.BOOKMARK      : return (<BookmarkIcon className={className} />);
        case TypeIcon.WARNING       : return (<WarningIcon className={className} />);
        case TypeIcon.PDF           : return (<PdfIcon className={className} />);
        case TypeIcon.SETTINGS      : return (<SettingsIcon className={className} />);
        case TypeIcon.ARROW_BACK    : return (<ArrowBackIcon className={className} />);
        case TypeIcon.ARROW_RIGHT   : return (<ArrowRightIcon className={className} />);

        //case TypeIcon.GENERAL       : return (<GeneralIcon className={className} />);
        case TypeIcon.DELIVERY      : return (<DeliveryIcon className={className} />);
        case TypeIcon.ADVANCED      : return (<AdvancedIcon className={className} />);
        case TypeIcon.SAVE          : return (<SaveIcon className={className} />);
        case TypeIcon.WATERMARK     : return (<WatermarkIcon className={className} />);
        case TypeIcon.BACKGROUND    : return (<BackgroundIcon className={className} />);
        case TypeIcon.RIGHT         : return (<RightIcon className={className} />);
        case TypeIcon.ADD_FILTER_ICON: return (<AddFilterIcon className={className} />);
        case TypeIcon.CHECKBOX_NOT_CHECKED: return (<CheckBoxNotCheckedIcon className={className} />);
        case TypeIcon.CHECKBOX_CHECKED    : return (<CheckBoxCheckedIcon className={className} />);


        case TypeIcon.ATTACHMENT    : return (<AttachFileOutlinedIcon className={className} />);
        case TypeIcon.ATTACHMENT_DESKTOP  : return (<AttachFileIcon className={className} />);
        case TypeIcon.DOCPRINT      : return (<DocprintIcon className={className} />);
        case TypeIcon.UNARCHIVE     : return (<UnarchiveIcon className={className} />);
        case TypeIcon.SIMPLEX       : return (<SimplexIcon className={className} />);
        case TypeIcon.DRAFT         : return (<DraftIcon className={className} />);
        case TypeIcon.WIZARD        : return (<WizardIcon className={className} />);
        case TypeIcon.DELETE_2      : return (<DeleteForeverIcon className={className} />);

        case TypeIcon.SPLIT_VERTICAL    : return (<VerticalSplitIcon className={className} />);
        case TypeIcon.SPLIT_HORIZONTAL  : return (<HorizontalSplitIcon className={className} />);
        case TypeIcon.LIST          : return (<ListIcon className={className} />);

        case TypeIcon.INFO          : return (<InfoIcon className={className} />);
        case TypeIcon.STAR          : return (<StarIcon className={className} />);
        case TypeIcon.STAR_BORDER   : return (<StarBorderIcon className={className} />);

        case TypeIcon.LIST_CORBEILLE: return (<ViewListIcon className={className} />);
        case TypeIcon.SCHEDULE      : return (<ScheduleIcon className={className} />);
        case TypeIcon.CLOUD_DOWNLOAD: return (<CloudDownloadIcon className={className} />);

        case TypeIcon.COUNTRY_FR    : return (<IconFlagFR />);
        case TypeIcon.COUNTRY_UK    : return (<IconFlagUK />);
        case TypeIcon.COUNTRY_DE    : return (<IconFlagDE />);

        case TypeIcon.DISCONNECT    : return (<DisconnectNewIcon className={className} />);


        case TypeIcon.SEND          : return (<SendIcon className={className} />);
        case TypeIcon.SEND_TO_VALID : return (<SupervisedUserCircleIcon className={className} />);

        case TypeIcon.FULLSCREEN    : return (<UnfoldMoreIcon className={className} />);
        case TypeIcon.WINDOW        : return (<UnfoldLessIcon className={className} />);

        case TypeIcon.BLOCK         : return (<BlockIcon className={className} />);
        case TypeIcon.REFUSE        : return (<RefuseIcon className={className} />);

        case TypeIcon.WORKING       : return (<WorkingIcon className={className} />);
        case TypeIcon.LIVE          : return (<LiveIcon className={className} />);
        case TypeIcon.HISTORY       : return (<HistoryIcon className={className} />);
        case TypeIcon.ADDRESS_ADD   : return (<AddressAddIcon className={className} />);
        case TypeIcon.ADDRESS_PAGE  : return (<AddressPageIcon className={className} />);
        case TypeIcon.ADDRESS_AREA  : return (<AddressAreaIcon className={className} />);

        case TypeIcon.MERGE         : return (<MergeTypeIcon className={className} />);
        case TypeIcon.SPLIT         : return (<CallSplitIcon className={className} />);
        case TypeIcon.ERROR         : return (<ErrorIcon className={className} />);

        case TypeIcon.TREEVIEW_ARROW_DOWN  : return (<TreeviewArrowDropDownIcon className={className} />);
        case TypeIcon.TREEVIEW_ARROW_RIGHT : return (<TreeviewArrowRightIcon className={className} />);
        case TypeIcon.TIMELINE      : return (<TimelineOutlinedIcon className={className} />);
        case TypeIcon.PREVIEW       : return (<VisibilityOutlinedIcon className={className} />);
        case TypeIcon.IMPORT_EXPORT : return (<ImportExportIcon className={className} />);
        case TypeIcon.IMPORT        : return (<ImportIcon className={className} />);
        case TypeIcon.EXPORT        : return (<ExportIcon className={className} />);
        case TypeIcon.OPEN_IN_NEW   : return (<OpenInNewOutlinedIcon className={className} />);
        case TypeIcon.FILE_PRESENT  : return (<FilePresentOutlinedIcon className={className} />);
        case TypeIcon.DOCUMENT      : return (<MailOutlineOutlinedIcon className={className} />);
        case TypeIcon.ADMIN         : return (<AdminPanelSettingsOutlinedIcon className={className} />);

        default                     : return '';
    }
}
/*
export const getIconOnly=(typeIcon)=>{
    switch (typeIcon){
        case TypeIcon.HOME          : return HomeIcon;
        case TypeIcon.CONCEPTION    : return HomeIcon;
        case TypeIcon.FILTER_LIST   : return FilterListIcon;
        case TypeIcon.DASHBOARD     : return DashboardIcon;
        case TypeIcon.NEW           : return NewIcon;
        case TypeIcon.EXEC          : return ExecIcon;
        case TypeIcon.LOCK          : return LockIcon;
        case TypeIcon.OPEN          : return OpenIcon;
        case TypeIcon.CLONE         : return CloneIcon;
        case TypeIcon.PURGE         : return PurgeIcon;
        case TypeIcon.CATEGORY      : return CategoryIcon;
        case TypeIcon.USER          : return UserIcon;
        case TypeIcon.GROUP         : return GroupIcon;
        case TypeIcon.GROUP_ADD     : return GroupAddIcon;
        case TypeIcon.MAIL          : return MailIcon;
        case TypeIcon.EMAIL         : return EmailIcon;
        case TypeIcon.LOCALPRINT    : return LocalPrintPrintIcon;
        case TypeIcon.CENTRALPRINT  : return CentralPrintIcon;
        case TypeIcon.SMS           : return SmsIcon;
        case TypeIcon.FAX           : return FaxIcon;
        case TypeIcon.GED           : return GedIcon;
        case TypeIcon.GENERIC       : return GenericIcon;
        case TypeIcon.SIGN          : return SignIcon;
        case TypeIcon.DELETE        : return DeleteIcon;
        case TypeIcon.CLEAR_ALL     : return ClearAllIcon;
        case TypeIcon.CANCEL        : return CancelIcon;
        case TypeIcon.CLOSE         : return CloseIcon;
        case TypeIcon.EXPANDLESS    : return ExpandLess;
        case TypeIcon.EXPANDMORE    : return ExpandMore;
        case TypeIcon.DONE          : return DoneIcon;

        case TypeIcon.TRANSIT       : return TransitIcon;
        case TypeIcon.CHECK_USER    : return VerifiedUserIcon;
        case TypeIcon.WAITING_FOR_VALIDATION : return HourglassEmptyIcon;
        case TypeIcon.WAITING_FOR_SIGN       : return HourglassEmptyIcon;
        case TypeIcon.EDIT          : return EditIcon;
        case TypeIcon.BOOKMARK      : return BookmarkIcon;
        case TypeIcon.WARNING       : return WarningIcon;
        case TypeIcon.PDF           : return PdfIcon;
        case TypeIcon.SETTINGS      : return SettingsIcon;
        case TypeIcon.ARROW_BACK    : return ArrowBackIcon;
        case TypeIcon.ARROW_RIGHT   : return ArrowRightIcon;

        //case TypeIcon.GENERAL       : return GeneralIcon;
        case TypeIcon.DELIVERY      : return DeliveryIcon;
        case TypeIcon.ADVANCED      : return AdvancedIcon;
        case TypeIcon.SAVE          : return SaveIcon;
        case TypeIcon.WATERMARK     : return WatermarkIcon;
        case TypeIcon.BACKGROUND    : return BackgroundIcon;
        case TypeIcon.RIGHT         : return RightIcon;
        case TypeIcon.ADD_FILTER_ICON: return AddFilterIcon;
        case TypeIcon.CHECKBOX_NOT_CHECKED: return CheckBoxNotCheckedIcon;
        case TypeIcon.CHECKBOX_CHECKED    : return CheckBoxCheckedIcon;

        case TypeIcon.ATTACHMENT    : return AttachFileOutlinedIcon;
        case TypeIcon.ATTACHMENT_DESKTOP  : return AttachFileIcon;
        case TypeIcon.DOCPRINT      : return DocprintIcon;
        case TypeIcon.UNARCHIVE     : return UnarchiveIcon;
        case TypeIcon.SIMPLEX       : return SimplexIcon;
        case TypeIcon.DRAFT         : return DraftIcon;
        case TypeIcon.WIZARD        : return WizardIcon;
        case TypeIcon.DELETE_2      : return DeleteForeverIcon;

        case TypeIcon.SPLIT_VERTICAL    : return VerticalSplitIcon;
        case TypeIcon.SPLIT_HORIZONTAL  : return HorizontalSplitIcon;
        case TypeIcon.LIST          : return ListIcon;

        case TypeIcon.INFO          : return InfoIcon;

        case TypeIcon.STAR          : return StarIcon;
        case TypeIcon.STAR_BORDER   : return StarBorderIcon;

        case TypeIcon.LIST_CORBEILLE: return ViewListIcon;
        case TypeIcon.SCHEDULE      : return ScheduleIcon;
        case TypeIcon.CLOUD_DOWNLOAD: return CloudDownloadIcon;

        case TypeIcon.COUNTRY_FR    : return IconFlagFR;
        case TypeIcon.COUNTRY_UK    : return IconFlagUK;
        case TypeIcon.COUNTRY_DE    : return IconFlagDE;

        case TypeIcon.DISCONNECT    : return DisconnectNewIcon;

        case TypeIcon.SEND          : return SendIcon;
        case TypeIcon.SEND_TO_VALID : return SendIcon;

        case TypeIcon.FULLSCREEN    : return UnfoldMoreIcon;
        case TypeIcon.WINDOW        : return UnfoldLessIcon;

        case TypeIcon.BLOCK         : return BlockIcon;
        case TypeIcon.REFUSE        : return RefuseIcon;

        case TypeIcon.WORKING       : return WorkingIcon;
        case TypeIcon.LIVE          : return LiveIcon;
        case TypeIcon.HISTORY       : return HistoryIcon;

        case TypeIcon.ADDRESS_ADD   : return AddressAddIcon;
        case TypeIcon.ADDRESS_PAGE  : return AddressPageIcon;
        case TypeIcon.ADDRESS_AREA  : return AddressAreaIcon;

        case TypeIcon.MERGE         : return MergeTypeIcon;
        case TypeIcon.SPLIT         : return CallSplitIcon;
        case TypeIcon.ERROR         : return ErrorIcon;

        case TypeIcon.TREEVIEW_ARROW_DOWN  : return TreeviewArrowDropDownIcon;
        case TypeIcon.TREEVIEW_ARROW_RIGHT : return TreeviewArrowRightIcon;
        case TypeIcon.TIMELINE      : return TimelineOutlinedIcon;
        case TypeIcon.PREVIEW       : return VisibilityOutlinedIcon;
        case TypeIcon.IMPORT_EXPORT : return ImportExportIcon;
        case TypeIcon.IMPORT        : return ImportIcon;
        case TypeIcon.EXPORT        : return ExportIcon;

        default                     : return null;
    }
}
*/
