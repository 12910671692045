import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField, Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {capitalizeFirstLetter, isValidEmailAddress, isValidPassword} from "../../common/tools";
import PropTypes from "prop-types";
import { makeStyles } from '@mui/styles'
import {ServiceAccounts} from "../../services/ServiceAccounts";
import Loader from "../shared/Loader";
import {stateConnection} from "../../services/ServiceTest";

const useStyles = makeStyles((theme) => ({
    title:{
        color:theme.palette.primary.main,
    },
    msgErrorArea:{
        width:'100%',
        textAlign:"right",
        padding: '8px 24px',
    },
    msgErrorText:{
        color:"red",
        cursor:"pointer",
    }
}));

const DialogTestApp=(props)=>{

    const classes = useStyles();
    const {t} = useTranslation();

    const [msgError,setMsgError] = React.useState('');
    const [email,setEmail] = React.useState('');
    const [passwd,setPasswd] = React.useState('');
    const [url,setUrl] = React.useState('');

    const [apiKey,setApiKey] = React.useState('');
    const [loading,setLoading] = React.useState(false);

    const handleClose = () => {
        if (typeof props.onCancel === "function"){
            props.onCancel();
        }
    };

    const handleContinue = ()=>{

        setLoading(true);

        stateConnection(url,apiKey)
            .then(()=>{
                setMsgError("");
                const serviceAccounts=new ServiceAccounts();
                serviceAccounts.createContext(email,passwd,url,apiKey).then((result)=>{
                    setLoading(false);

                    if (result.code==="0"){

                        if (typeof props.onSubscribe === "function" &&
                            result?.data?.user &&
                            result?.data?.enterprise
                        ){
                            props.onSubscribe(email,passwd);
                        }
                    }else{
                        console.error(result)
                        switch (result?.code){
                            case "auth/email-already-exists":
                            case "auth/email-already-in-use":
                                setMsgError(capitalizeFirstLetter(t('comee.dialogTestApp.alreadyUseError')))
                                break;
                            case "auth/weak-password":
                                setMsgError(capitalizeFirstLetter(t('comee.dialogTestApp.weakPassword')))
                                break;
                            default:
                                setMsgError(capitalizeFirstLetter(t('comee.dialogTestApp.error')))
                        }
                    }
                },(e)=>{
                    setLoading(false);
                    e && console.error(e);
                    setMsgError(capitalizeFirstLetter(t('comee.dialogTestApp.error')))
                })

            })
            .catch(()=>{
                setLoading(false);
                setMsgError(capitalizeFirstLetter(t('comee.dialogTestApp.stateConnectionError')));
            })

    }

    const handleKeyPress=(e)=>{
        if (e.key === 'Enter') {
            handleContinue();
        }
    }

    return (
        <div>
            <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle>
                    <Typography component={"h6"} variant={"h6"} className={classes.title}>
                        {
                            loading
                                ?
                                    capitalizeFirstLetter(t('comee.dialogTestApp.createAccounting'))
                                :
                                    capitalizeFirstLetter(t('comee.dialogTestApp.createAccount'))
                        }
                    </Typography>
                   </DialogTitle>
                    <DialogContent>
                        {
                            loading ? <Loader color={"primary"}/> :
                                <React.Fragment>
                                    <TextField
                                        autoFocus
                                        required
                                        margin="dense"
                                        onChange={(e)=>setEmail(e.target.value)}
                                        label= {capitalizeFirstLetter(t('comee.dialogTestApp.email'))}
                                        value={email}
                                        type="email"
                                        fullWidth
                                    />
                                    <TextField
                                        margin="dense"
                                        required
                                        fullWidth
                                        label={capitalizeFirstLetter(t('comee.login.password'))}
                                        value={passwd}
                                        type="password"
                                        autoComplete="current-password"
                                        onChange={(e)=>setPasswd(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                    />
                                    <TextField
                                        required
                                        margin="dense"
                                        onChange={(e)=>setUrl(e.target.value)}
                                        label= {capitalizeFirstLetter(t('comee.dialogTestApp.url'))}
                                        value={url}
                                        type="url"
                                        fullWidth
                                    />
                                    <TextField
                                        required
                                        margin="dense"
                                        onChange={(e)=>setApiKey(e.target.value)}
                                        label= {capitalizeFirstLetter(t('comee.dialogTestApp.apiKey'))}
                                        value={apiKey}
                                        type="url"
                                        fullWidth
                                    />
                                    {
                                        msgError && (
                                            <div className={classes.msgErrorArea}>
                                                <span className={classes.msgErrorText}>{msgError}</span>
                                            </div>
                                        )
                                    }
                                </React.Fragment>
                        }

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}
                                color="primary"
                                disabled={loading}
                        >
                            {capitalizeFirstLetter(t('comee.bt.cancel'))}
                        </Button>
                        <Button onClick={handleContinue}
                                color="primary"
                                disabled={loading || !(isValidEmailAddress(email) && isValidPassword(passwd) && url.trim()!=="" && apiKey.trim()!==null)}
                        >
                            {capitalizeFirstLetter(t('comee.bt.createAccount'))}
                        </Button>
                    </DialogActions>
            </Dialog>
        </div>
    );
}

DialogTestApp.propTypes = {
    onCancel:PropTypes.func.isRequired,
    onSubscribe:PropTypes.func.isRequired
}

export default DialogTestApp;
