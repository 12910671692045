import React, {useEffect} from 'react';
import {Button, Container, CssBaseline, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import { getAuth, signInWithEmailAndPassword,signOut } from "firebase/auth";
import {ServiceAccounts} from "../../services/ServiceAccounts";
import {ServiceEnterprise} from "../../services/ServiceEnterprise";
import {capitalizeFirstLetter} from "../../common/tools";
import DialogTestApp from "./DialogTestApp";
import { makeStyles } from '@mui/styles';
import Box from "@mui/material/Box";
import DialogResetPassword from "./DialogResetPassword";

const useStyles = makeStyles((theme) => ({
    root:{
        margin:"auto",
        flex:'1',
    },
    title:{
        margin:"auto",
        marginTop:"20px",
        textAlign:"center"
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#F5F5F5',
        borderRadius: '10px',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.3)',
        padding: '0px 40px 40px',
    },
    media: {
        width: '160px',
        height: 'auto',
        marginTop:'35px'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    error:{
        color:"red",
    },
    msgTestArea:{
        width:'100%',
        textAlign:"right",
    },
    msgTest:{
        color:theme.palette.primary.main,
        cursor:"pointer",
    },
    passwordLost:{
        textDecoration:"underline",
        cursor:"pointer",
        width: "100%",
        textAlign: "right",
    }
}));

export const AppContext = React.createContext({
    user:{},
    enterprise:{}
});

const Login = (props)=>{

    const classes = useStyles();
    const {t} = useTranslation();

    const [login,setLogin] = React.useState('');
    const [passwd,setPasswd] = React.useState('');
    const [message,setMessage] = React.useState('');
    const [appContext,setAppContext] = React.useState(null);

    const [openDialogTestApp,setOpenDialogTestApp] = React.useState(false);
    const [openDialogResetPassword,setOpenDialogResetPassword] = React.useState(false);

    const [auth] = React.useState(getAuth());

    useEffect(  () => {
        signOut(auth).then(()=>{

        });
    },[])

    useEffect(() => {
        setMessage('');
    },[login,passwd])

    const signIn=()=>{

        if (login && passwd){
            signInWithEmailAndPassword(auth,login,passwd).then((userAuth)=>{

                const serviceAccount=new ServiceAccounts();

                serviceAccount.document(userAuth.user.uid).then(userAcount=>{

                    //On regarde si l'utilisateur est affecté a une ou plusieurs entreprise
                    if (userAcount.enterprises?.length){

                        if (userAcount.enterprises.length===1){
                            const serviceEnterprise=new ServiceEnterprise();
                            serviceEnterprise.document(userAcount.enterprises[0]).then(enterprise=>{
                                setAppContext({
                                    user:userAcount,
                                    enterprise:enterprise,
                                })
                            },(e)=>{
                                console.log(e);
                                setMessage(capitalizeFirstLetter(t('comee.login.error')))
                            })
                        }else{
                            setMessage(capitalizeFirstLetter(t('comee.login.error')))
                        }
                    }
                },(e)=>{
                    console.log(e);
                    setMessage(capitalizeFirstLetter(t('comee.login.error')))
                });
                //console.log(userAuth);
            },(e)=>{
                e && console.error(e);
                setMessage(capitalizeFirstLetter(t('comee.login.error')))
            })
        }else{
            setMessage(capitalizeFirstLetter(t('comee.login.error')))
        }
    }

    const handleKeyPress=(e)=>{
        if (e.key === 'Enter') {
            signIn();
        }
    }

    if (appContext){
        //console.log(appContext);

        return (
            <AppContext.Provider value={appContext}>
                {props.children}
            </AppContext.Provider>
        );
    }
    return (
        <Container component="main" maxWidth="xs" className={classes.root}>
            <CssBaseline />
            <div className={classes.paper}>
                <Typography color={"primary"}
                            component={"h1"}
                            variant={"h4"}
                            className={classes.title}>
                    {capitalizeFirstLetter(t('comee.name'))}
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        //defaultValue={login}
                        label={capitalizeFirstLetter(t('comee.login.login'))}
                        type="text"
                        autoFocus
                        onChange={(e)=>setLogin(e.target.value)}
                        value={login}
                        onKeyPress={handleKeyPress}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        //defaultValue={passwd}
                        label={capitalizeFirstLetter(t('comee.login.password'))}
                        type="password"
                        autoComplete="current-password"
                        onChange={(e)=>setPasswd(e.target.value)}
                        value={passwd}
                        onKeyPress={handleKeyPress}
                    />
                    <p className={classes.error}>{message}</p>
                    <Box
                        width={"100%"}
                        onClick={()=>setOpenDialogResetPassword(true)}>
                        <Typography
                            className={classes.passwordLost}
                            component={"p"}
                        >{capitalizeFirstLetter(t('comee.login.passwordlost'))}
                        </Typography>
                    </Box>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={signIn}
                    >
                        {t('comee.login.toConnect')}
                    </Button>
                    <div className={classes.msgTestArea}>
                        <span className={classes.msgTest} onClick={()=>setOpenDialogTestApp(true)}>
                        {capitalizeFirstLetter(t('comee.login.msgTest'))}
                    </span>
                    </div>
                    {
                        openDialogTestApp && (
                            <DialogTestApp onCancel={()=>setOpenDialogTestApp(false)}
                                           onSubscribe={(_login,_passwd)=>{
                                               setOpenDialogTestApp(false);
                                               setLogin(_login);
                                               setPasswd(_passwd);
                                           }}/>
                        )
                    }
                </form>
            </div>
            {
                openDialogResetPassword && (
                    <DialogResetPassword onCancel={()=>setOpenDialogResetPassword(false)}
                                         onReset={()=>setOpenDialogResetPassword(false)}
                    />
                )
            }
        </Container>
    )
}

export default Login;
