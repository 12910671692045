import React, {useEffect, useRef} from 'react';
import {Button, Grid, Paper, TextField, Tooltip, Zoom} from "@mui/material";
import {SnackBarContext} from "../shared/MessageSnackBar";
import {useContext} from "react";
import {capitalizeFirstLetter} from "../../common/tools";
import {useTranslation} from "react-i18next";
import {ServiceTemplateCommunication} from "../../services/ServiceTemplateCommunication";
import PropTypes from "prop-types";
import ErrorArea from "../../common/ErrorArea";
import CardTemplateCommunication from "./CardTemplateCommunication";
import EmptyArea from "../../common/EmptyArea";
import DialogTemplateCommunication from "./DialogTemplateCommunication";

import DialogConfirmDelete from "../shared/DialogConfirmDelete";
import {listTemplateStyles} from "../../assets/jss/listTemplate";
import Loader from "../shared/Loader";
import DialogCommunication from "./DialogCommunication";
import _ from "lodash";
import {createCommunicationJSON} from "../../services/ServiceCreateCommunication";
import DialogPreview from "./DialogPreview";
import DialogError from "../shared/DialogError";
import {ServiceTemplateDocument} from "../../services/ServiceTemplateDocument";
import {ServiceTemplateUser} from "../../services/ServiceTemplateUser";
import {ServiceTemplateEmail} from "../../services/ServiceTemplateEmail";
import {ServiceTemplateSms} from "../../services/ServiceTemplateSms";
import PQueue from "p-queue";
import {ServiceTemplateDataFile} from "../../services/ServiceTemplateDataFile";
import {
    FirebaseConstraintsGenerator,
    OperatorType,
} from "../../services/ServiceFireBase";

const filterTemplateCommunication=(templateCommunication,filter)=>{
    if (filter && templateCommunication && Array.isArray(templateCommunication)){
        return templateCommunication.filter(items=> {
            if (items?.communication?.name?.toLowerCase().includes(filter.toLowerCase())) {
                return true;
            }
            return false;
        })

    }else{
        return templateCommunication;
    }
}

const Home=(props)=>{

    const appSnackBar = useContext(SnackBarContext);
    const appContext = props.appContext;

    const classes=listTemplateStyles();

    const {t} = useTranslation();

    const [boxNew,setBoxNew]= React.useState(false)

    const [deleteId,setDeleteId]= React.useState(null)

    const [filter,setFilter]= React.useState('')
    const [templatesCommunicationEdit,setTemplateCommunicationEdit]= React.useState(null)

    const [loading,setLoading]= React.useState(true)
    const [loadFailed,setLoadFailed]= React.useState(false)
    const [communicationCreatingId,setCommunicationCreatingId]= React.useState("")

    const [openDialogCommunication,setOpenDialogCommunication] =  React.useState(null)

    // template communication
    const [serviceTemplateCommunication]= React.useState(new ServiceTemplateCommunication(props.enterpriseId))
    const [templatesCommunication,setTemplatesCommunication]= React.useState([])

    // template document
    const [serviceTemplateDocument]= React.useState(new ServiceTemplateDocument(props.organization.id,appContext.enterprise));

    // template user
    const [serviceTemplateUser]= React.useState(new ServiceTemplateUser(props.organization.id,appContext.enterprise));

    // template email
    const [serviceTemplateEmail]= React.useState(new ServiceTemplateEmail(props.organization.id,appContext.enterprise));

    // template dataFile
    const [serviceTemplateDataFile]= React.useState(new ServiceTemplateDataFile(props.enterpriseId));


    // template user
    const [serviceTemplateSms]= React.useState(new ServiceTemplateSms(props.organization.id,appContext.enterprise));

    const [boxPreview,setBoxPreview]=React.useState("")

    const [boxError,setBoxError]=React.useState(null)
    const init=useRef(0);

    useEffect(() => {

        // Mise en place du filtre lié a l'organisation
        const queryConstraints=new FirebaseConstraintsGenerator();
        queryConstraints.addWhere("org",OperatorType.EQUAL,props.organization.code)

        //link les méthode de syncronisation des template communication
        serviceTemplateCommunication.onSnapshot(syncSnapshotCommunicationSuccess,syncSnapshotCommunicationError,queryConstraints);

        //suppression de la souscription sur la synchronisation des contacts
        return () => serviceTemplateCommunication.destructor();

    }, []);


    useEffect(async () => {
        if (init.current){
            await serviceTemplateCommunication.destructor();

            const queryConstraints=new FirebaseConstraintsGenerator();
            queryConstraints.addWhere("org",OperatorType.EQUAL,props.organization.code)

            //link les méthode de syncronisation des template communication
            serviceTemplateCommunication.onSnapshot(syncSnapshotCommunicationSuccess,syncSnapshotCommunicationError,queryConstraints);
        }
        init.current++;

    },[props.organization]);

    const syncSnapshotCommunicationSuccess=(docs)=>{
        setTemplatesCommunication(docs)
        setLoading(false);
    }
    const syncSnapshotCommunicationError=()=>{
        setLoadFailed(true)
    }

    if (loadFailed){
        return (
            <ErrorArea label={capitalizeFirstLetter(t('comee.home.errorLoad'))} />
        )
    }

    const templateCommunicationWork=filterTemplateCommunication(templatesCommunication,filter);

    /**
     *  function de création du json de création de la requête
     */
    const createJsonRequest=(tcId)=>{
        return new Promise((resolve, reject) => {

            //on travail sur une copie profonde de l'objet afin de ne pas toucher a l'objet initial
            //on pourrait supprimer des id ou autres données qui sont nécessaires si on vient à modifier la requête
            const tcTmp=_.cloneDeep(templateCommunicationWork.find(tc=>tc.id===tcId));

            //creation de la queue
            const queue = new PQueue({concurrency: 5});

            //création du tablau de promesse
            let promiseArray = [];

            /**
             * Récupération des ressources
             * On fait des tests pour ne charger que le strict minimum
             */
            if (tcTmp.identification){
                promiseArray.push(() => serviceTemplateUser.id(tcTmp.identification));
            }

            //MAIL
            if (tcTmp.communication?.content?.contentMail){
                tcTmp.communication?.content?.contentMail.forEach(td=>{
                    if (td.id){
                        promiseArray.push(() => serviceTemplateDocument.id(td.id));
                    }
                    if (td.buffer){
                        promiseArray.push(() => serviceTemplateDataFile.document(td.buffer));
                    }
                })
            }

            //Email
            if (tcTmp.communication?.content?.contentEmail?.defaultTemplate){
                promiseArray.push(() => serviceTemplateEmail.id(tcTmp.communication.content.contentEmail.defaultTemplate));
            }

            //SMS
            if (tcTmp.communication?.content?.contentSms?.defaultTemplate){
                promiseArray.push(() => serviceTemplateSms.id(tcTmp.communication.content.contentSms.defaultTemplate));
            }

            // Quand toutes les promesses sont résolues et que toutes les ressources ont été récupérées.
            queue.addAll(promiseArray).then((arrayOfList) => {

                /**
                 * Attention : On fait les mêmes tests que pour charger les ressources (ci-dessus, cf. tableau de
                 * promesse promiseArray) afin de récupérer les éléments dans l'ordre
                 */
                let idx=0;
                if (tcTmp.identification){
                    const tu = arrayOfList[idx++];
                    tcTmp.identification={
                        idUser:tu.login,
                        name:tu.name,
                        firstName:tu.firstname,
                        emailAdr:tu.email,
                        groupe:[],
                        organization:props.organization.code
                    }
                    if (tu.groups && Array.isArray(tu.groups) && tu.groups.length){
                        tcTmp.identification.groupe=tu.groups.map(g=>g.code)
                    }
                }

                // Suppression de l'organisation qui est présent a la racine de l'objet
                // ce champs sert à filtrer les template de communication sur la base du code organisation
                if (tcTmp.hasOwnProperty("org")){
                    delete tcTmp.org;
                }

                // Surcharge du type de communication afin que le code sit compatible avec Comee
                switch (tcTmp.communication?.typeCommunication){
                    case "M":
                        tcTmp.communication.typeCommunication="MAIL";
                        break;
                    case "E":
                        tcTmp.communication.typeCommunication="EMAIL";
                        break;
                    case "S":
                        tcTmp.communication.typeCommunication="SMS";
                        break;
                    case "F":
                        tcTmp.communication.typeCommunication="FAX";
                        break;
                    default:throw new Error("tcTmp.communication.typeCommunication not defined")
                }

                //MAIL
                if (tcTmp.communication?.content?.contentMail){
                    const contentMailBackup=_.cloneDeep(tcTmp.communication.content.contentMail);
                    delete tcTmp.communication.content.contentMail;
                    tcTmp.communication.content.contentMail={
                        "template":[],
                        "duplex":false,
                        "color":true,
                        "displayMode":"D3",
                        "mailAddress":null,
                        "pageAddress":null,
                        "maskAddress":false,
                        "watermark":null,
                        "background":null
                    }
                    contentMailBackup.forEach(td=>{
                        let tdServer=null;
                        if (td.id){
                            tdServer = arrayOfList[idx++];
                        }
                        let tdfServer=null;
                        if (td.buffer){
                            tdfServer = arrayOfList[idx++];
                        }
                        if (td && tdfServer){
                            tcTmp.communication.content.contentMail.template.push({
                                "name":tdServer.name,
                                "templateName":tdServer.code,
                                "streamBuffer":tdfServer.buffer,
                            })
                        }
                    })
                }

                //gestion du modèle d'émail
                if (tcTmp.communication?.content?.contentEmail?.defaultTemplate){
                    const contentEmailBackup=_.cloneDeep(tcTmp.communication?.content?.contentEmail);
                    delete tcTmp.communication.content.contentEmail;
                    const templateEmail=arrayOfList[idx++];
                    tcTmp.communication.content.contentEmail={
                        "editBody":contentEmailBackup.editBody,
                        "editSubject":contentEmailBackup.editSubject,
                        "defaultTemplate":templateEmail.code,
                        "changeTemplate":contentEmailBackup.changeTemplate,
                        "addAttachment":contentEmailBackup.addAttachment,
                        "forward":contentEmailBackup.forward,
                        "split":contentEmailBackup.split,
                        "whiteMark":null,
                        "variable":contentEmailBackup.variable
                    }
                }

                //gestion du modèle de SMS
                if (tcTmp.communication?.content?.contentSms?.defaultTemplate){
                    const contentSmsBackup=_.cloneDeep(tcTmp.communication?.content?.contentSms);
                    delete tcTmp.communication.content.contentSms;
                    const templateSms=arrayOfList[idx++];
                    tcTmp.communication.content.contentSms={
                        "type":contentSmsBackup.type,
                        "changeType":true,
                        "from":contentSmsBackup.from,
                        "changeFrom":contentSmsBackup.changeFrom,
                        "changeLabel":contentSmsBackup.changeLabel,
                        "editBody":contentSmsBackup.editBody,
                        "defaultTemplate":templateSms.code,
                        "changeTemplate":contentSmsBackup.changeTemplate,
                        "whiteMark":null,
                        "variable":contentSmsBackup.variable
                    }
                }

                //suppression de l'id
                delete tcTmp.id;

                /**
                 * Suppression des noeuds inutiles
                 * l'objectif est de nettoyer la requête au maximum afin qu'elle soit la plus légère possible
                 */

                let allTypeDelivery=[]
                switch (tcTmp.communication.typeCommunication){
                    case "MAIL" :

                        allTypeDelivery=[];

                        if (!tcTmp.communication?.content?.contentEmail || tcTmp.communication?.content?.contentEmail?.defaultTemplate===""){
                            delete tcTmp.communication?.content?.contentEmail;
                            delete tcTmp.communication?.delivery?.email;
                            allTypeDelivery.push("EMAIL")
                        }

                        if (!tcTmp.communication?.content?.contentSms || tcTmp.communication?.content?.contentSms?.defaultTemplate===""){
                            delete tcTmp.communication?.content?.contentSms;
                            delete tcTmp.communication?.delivery?.sms;
                            allTypeDelivery.push("SMS")
                        }

                        if (allTypeDelivery.length>0){
                            tcTmp.communication.delivery.ged=tcTmp.communication?.delivery?.ged.filter(g=>allTypeDelivery.includes(g.typeDelivery)===false)
                        }

                        break;
                    case "EMAIL":
                        allTypeDelivery=[
                            "CENTRALPRINT",
                            "LOCALPRINT",
                            "SMS",
                            "FAX",
                            //"EMAIL",
                            "GENERIC",
                            "GED"
                        ];
                        //suppression des contents
                        delete tcTmp.communication?.content?.contentMail;
                        delete tcTmp.communication?.content?.contentSms;

                        //suppression des diffusions
                        delete tcTmp.communication?.delivery?.localPrint;
                        delete tcTmp.communication?.delivery?.generic;
                        delete tcTmp.communication?.delivery?.centralPrint;
                        delete tcTmp.communication?.delivery?.fax;
                        delete tcTmp.communication?.delivery?.sms;
                        tcTmp.communication.delivery.ged=tcTmp.communication?.delivery?.ged.filter(g=>allTypeDelivery.includes(g.typeDelivery)===false)

                        break;
                    case "FAX"  :
                        break;
                    case "SMS"  :
                        allTypeDelivery=[
                            "CENTRALPRINT",
                            "LOCALPRINT",
                            //"SMS",
                            "FAX",
                            "EMAIL",
                            "GENERIC",
                            "GED"
                        ];
                        //suppression des contents
                        delete tcTmp.communication?.content?.contentMail;
                        delete tcTmp.communication?.content?.contentEmail;

                        //suppression de l'input
                        delete tcTmp.communication?.input;

                        //suppression des diffusions
                        delete tcTmp.communication?.delivery?.localPrint;
                        delete tcTmp.communication?.delivery?.generic;
                        delete tcTmp.communication?.delivery?.centralPrint;
                        delete tcTmp.communication?.delivery?.fax;
                        delete tcTmp.communication?.delivery?.email;
                        tcTmp.communication.delivery.ged=tcTmp.communication?.delivery?.ged.filter(g=>allTypeDelivery.includes(g.typeDelivery)===false)

                        break;
                    default     : throw new Error("tcTmp.communication.typeCommunication not defined")
                }

                resolve(tcTmp)

            }, (msg) => {
                if (msg) console.error(msg);
                reject(capitalizeFirstLetter(t('comee.repository.user.errorLoad')));
            });
        });
    }

    const createCommunication=(tcId)=>{

        setCommunicationCreatingId(tcId)

        createJsonRequest(tcId).then((dataRequest)=>{

            createCommunicationJSON(appContext.enterprise.url,dataRequest).then(dataResponse=>{

                setCommunicationCreatingId("");

                if (appContext.enterprise.openCommunicaionAfterCreated){
                    setOpenDialogCommunication({
                        org:dataRequest.identification.org,
                        user:dataRequest.identification.idUser,
                        id:dataResponse.id,
                        type:dataRequest.communication.typeCommunication
                    });
                }else{
                    appSnackBar.success(capitalizeFirstLetter(t("comee.home.successCreationCommunication").replace('{NAME}',dataRequest.communication.name)))
                }
            },(error)=>{
                appSnackBar.error(capitalizeFirstLetter(t("comee.home.errorCreateCommunicationTitle")))
                setCommunicationCreatingId("");
                setBoxError(error)
            })
        },(msg)=>{
            appSnackBar.error(capitalizeFirstLetter(t("comee.home.errorCreateCommunicationTitle")))
            setCommunicationCreatingId("");
            appSnackBar.error(msg)
        })
    }

    if (loading){
        return (
            <Loader color={"primary"}/>
        )
    }

    if (loadFailed ){
        return (
            <ErrorArea label={capitalizeFirstLetter(t('comee.home.loading'))} />
        )
    }

    return(
        <Paper className={classes.root}>
            <div className={classes.lineFilter}>
                <TextField
                    label= {capitalizeFirstLetter(t('comee.home.filters.searchPlaceHolder'))}
                    margin="dense"
                    value={filter}
                    onChange={(e)=>setFilter(e.target.value)}
                    className={classes.sectionSearch}
                    fullWidth
                />
                <Tooltip
                    key={"bt_new"}
                    title={capitalizeFirstLetter(t('comee.bt.new'))}
                    TransitionComponent={Zoom} arrow>
                    <Button variant="contained" color="primary" disableElevation onClick={()=>setBoxNew(true)} className={classes.btNew}>
                        {capitalizeFirstLetter(t('comee.bt.new'))}
                    </Button>
                </Tooltip>
            </div>
            <div className={classes.contentHome}>
                {
                    templateCommunicationWork?.length ?
                        <Grid
                            container
                            direction="row"
                            spacing={1}
                        >
                            {
                                templateCommunicationWork.map(tc=><CardTemplateCommunication
                                    key={tc.id}
                                    templateCommunication={tc}
                                    onDelete={(tcId)=>{
                                        setDeleteId(tcId);
                                    }}
                                    onEdit={(tcId)=>{
                                        setTemplateCommunicationEdit(templatesCommunication.find(item=>item.id===tcId))
                                        setBoxNew(tcId);
                                    }}
                                    onPreview={(tcId)=>{
                                        createJsonRequest(tcId).then(json=>{
                                            setBoxPreview(json);
                                        });
                                    }}
                                    onClone={(tcId)=>{
                                        const tcSource=templatesCommunication.find(tc=>tc.id===tcId);
                                        //on clone complètement l'objet
                                        const tcClone=_.cloneDeep(tcSource);
                                        //on supprime le champs id
                                        delete tcClone.id;
                                        serviceTemplateCommunication.update(tcClone).then(tcNewClone=>{
                                            appSnackBar.success(capitalizeFirstLetter(t('comee.home.successClone').replace('{NAME}',tc.communication.name)))
                                        },()=>{
                                            appSnackBar.error(capitalizeFirstLetter(t('comee.home.errorClone').replace('{NAME}',tc.communication.name)))
                                        })
                                    }}
                                    executing={communicationCreatingId === tc.id}
                                    disable={communicationCreatingId !== ""}
                                    onExec={(tcId)=>createCommunication(tcId)}/>)
                            }
                        </Grid>
                        :
                        <EmptyArea label={capitalizeFirstLetter(t('comee.home.empty'))} />
                }
            </div>
            {
                boxNew && (
                    <DialogTemplateCommunication
                        id={templatesCommunicationEdit?.id}
                        tc={templatesCommunicationEdit}
                        enterpriseId={props.enterpriseId}
                        organization={props.organization}
                        open={boxNew}
                        onCancel={()=>{
                            setBoxNew(false)
                            setTemplateCommunicationEdit(null)
                        }}
                    />
                )
            }
            {
                deleteId && (
                    <DialogConfirmDelete
                        title={capitalizeFirstLetter(t('comee.home.boxDelete.title'))}
                        msg1={capitalizeFirstLetter(t('comee.home.boxDelete.msg1')).replace("{name}",templatesCommunication.find(g=>g.id===deleteId)?.communication.name)}
                        msg2={capitalizeFirstLetter(t('comee.home.boxDelete.msg2'))}
                        onCancel={()=>setDeleteId(null)}
                        onDelete={()=>{
                            serviceTemplateCommunication.delete(deleteId).then(()=>{
                                appSnackBar.success(capitalizeFirstLetter(t('comee.home.boxDelete.success')))
                                setDeleteId(null)
                            },()=>{
                                appSnackBar.error(capitalizeFirstLetter(t('comee.home.boxDelete.failed')))
                                setDeleteId(null)
                            })
                        }}
                    />
                )
            }
            {
                openDialogCommunication !== null && (
                    <DialogCommunication
                        organization={props.organization}
                        user={openDialogCommunication.user}
                        id={openDialogCommunication.id}
                        type={openDialogCommunication.type}
                        onClose={()=>{
                            setOpenDialogCommunication(null);
                        }}
                    />
                )
            }
            {
                boxPreview && (
                    <DialogPreview
                        json={boxPreview}
                        onClose={()=>setBoxPreview("")}
                    />
                )
            }
            {
                boxError && (
                    <DialogError
                        title={ capitalizeFirstLetter(t('comee.home.errorCreateCommunicationTitle'))}
                        msg1={
                            capitalizeFirstLetter(t('comee.home.errorCreateCommunication'))
                                .replace('{NUM_ERROR}',boxError.returnCode)
                                .replace('{MSG}',boxError.message)
                        }
                        onClose={()=>setBoxError(null)}
                    />
                )
            }
        </Paper>
    )
}

Home.propTypes = {
    enterpriseId:PropTypes.string.isRequired,
    organization:PropTypes.object.isRequired,
}

export default Home;
