import {Box, CardMedia, Typography} from "@mui/material";
import {capitalizeFirstLetter} from "./tools";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import PropTypes from "prop-types";


const useStyles = makeStyles((theme) => ({
    root: {
        margin:'auto',
        display: 'flex',
        height: '100%',
        width: '100%',
        textAlign: 'center',
        flex: '1',
    },
    card:{
        boxShadow:'none',
        backgroundColor:'transparent',
        margin: 'auto',
        width: '100%',
    },
    cover:{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        height: '200px',
    },
    text:{
        color:theme.palette.primary.main,
    },

}));

const EmptyArea=(props)=>{
    const classes=useStyles();
    const {t} = useTranslation();
    return (
        <Box className={classes.root}>
            <Box className={classes.card}>
                <CardMedia
                    className={classes.cover}
                    image="/images/logo-docprint.png"
                    title={capitalizeFirstLetter(t('comee.name'))}
                />
                {
                    props.label && (
                        <Typography component='p' className={classes.text}>{capitalizeFirstLetter(props.label)}</Typography>
                    )
                }
            </Box>
        </Box>
    )
}

EmptyArea.propTypes = {
    label:PropTypes.string,
};

export default EmptyArea;
