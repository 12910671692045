import React from "react";

import PropTypes from 'prop-types';
import {Box, CircularProgress} from "@mui/material";

const Loader=(props) => {

    const color=props && props.color ?  props.color : "secondary";

    return (
            <Box
                height={"100%"}
                width={"100%"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <CircularProgress color={color} />
            </Box>
    )
};

Loader.propTypes = {
    color:PropTypes.string,
}

export default Loader;
