import {getFunctions, httpsCallable} from "firebase/functions";

export class ServiceTemplateGeneric {

    apiKey="";
    url="";
    organizationId="";
    serviceListName="";
    serviceIdName=""
    constructor(organizationId,{url,apiKey,id},serviceListName,serviceIdName) {
        this.url=url;
        this.apiKey=apiKey;
        this.organizationId=organizationId;
        this.serviceListName=serviceListName;
        this.serviceIdName=serviceIdName;
    }

    list=()=>{
        return new Promise((resolve, reject) => {

            const functions = getFunctions();
            functions.region = "europe-west3";
            const serviceList = httpsCallable(functions, this.serviceListName);
            const data = {
                "url":this.url,
                "apiKey":this.apiKey,
                "organizationId":this.organizationId
            };

            serviceList(data)
                .then((response) => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject();
                    }
                }).catch((error) => {
                error && console.error(error);
                reject(error);
            });
        });
    }

    id=(id)=>{
        return new Promise((resolve, reject) => {

            const functions = getFunctions();
            functions.region = "europe-west3";
            const serviceId = httpsCallable(functions, this.serviceIdName);
            const data = {
                "url":this.url,
                "apiKey":this.apiKey,
                "organizationId":this.organizationId,
                "id":id
            };

            serviceId(data)
                .then((response) => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject();
                    }
                }).catch((error) => {
                error && console.error(error);
                reject(error);
            });
        });
    }
}
