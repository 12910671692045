// core components


import {makeStyles} from "@mui/styles";

export const listTemplateStyles = makeStyles((theme) => ({
    root: {
        display:"flex",
        flexDirection:"column",
        width:'100%',
        maxWidth:'100%',
        height: '100%',
        maxHeight: '100%',
        overflow:"hidden",
        borderRadius:"0",
        boxShadow:"none",
    },
    lineFilter:{
        height:'80px',
        flexDirection:"row",
        display:'flex',
        padding:'10px 11px 0 0px',
    },
    contentHome:{
        flex:1,
        //backgroundColor:'green',
        flexDirection:"column",
        display:'flex',
        padding:'20px',
        overflow:"auto",
    },
    sectionSearch:{
        flex:1,
        margin:"0px 12px"
    },
    btNew:{
        height: '40px',
        marginTop: '7px',
        textTransform:"initial"
    }
}));
