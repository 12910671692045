import React from 'react';
import {CircularProgress, Grid, IconButton} from "@mui/material";
import {GetIcon, TypeIcon} from "../utils/Icones";
import PropTypes from "prop-types";
import clsx from "clsx";
import {cardTemplateStyles} from "../../assets/jss/cardTemplate";

const getIconFromTypeTemplate=(typeCommunication)=>{
    switch (typeCommunication){
        case "E": return GetIcon(TypeIcon.EMAIL);
        case "M": return GetIcon(TypeIcon.MAIL);
        case "S": return GetIcon(TypeIcon.SMS);
        default : throw new Error("typeCommunication not defined")
    }
}

const CardTemplateCommunication=(props)=>{

    const classes = cardTemplateStyles();

    const [hover,setHover]=React.useState(false);

    return (
        <Grid container
              onMouseEnter={()=>setHover(true)}
              onMouseLeave={()=>setHover(false)}
              direction="row"
              className={classes.root}
              onDoubleClick={()=>{
                  if (props.disable !== true){
                      if (typeof props.onEdit === "function"){
                          props.onEdit(props.templateCommunication.id)
                      }
                  }
              }}
        >
            <Grid item xs={6}  sm={1} className={classes.colState}>
                {
                    hover && (
                        <IconButton
                            edge="start" color="inherit"
                            className={clsx(classes.button)}
                            onClick={()=>{
                                if (typeof props.onPreview === "function"){
                                    props.onPreview(props.templateCommunication.id)
                                }
                            }}
                        >
                            {GetIcon(TypeIcon.PREVIEW,clsx(classes.preview))}
                        </IconButton>
                    )
                }
            </Grid>
            <Grid item xs={6}  sm={1} className={classes.colState}>
                {
                    getIconFromTypeTemplate(props.templateCommunication.communication.typeCommunication)
                }
            </Grid>
            <Grid item xs={12} sm={6} className={clsx(classes.col,classes.noWrap)}>
                <span>
                    {
                        props.templateCommunication.communication.name
                    }
                </span>
            </Grid>
            <Grid item xs={12} sm={1} className={classes.colEdit}>
                {
                    hover && (
                        <IconButton
                            disabled={props.disable}
                            edge="start" color="inherit"
                            className={clsx(classes.button)}
                            onClick={()=>{
                                if (typeof props.onEdit === "function"){
                                    props.onEdit(props.templateCommunication.id)
                                }
                            }}
                        >
                            {GetIcon(TypeIcon.EDIT,clsx(classes.edit, props.disable && classes.btDisable))}
                        </IconButton>
                    )
                }
            </Grid>
            <Grid item xs={12} sm={1} className={classes.colClone}>
                {
                    hover && (
                        <IconButton
                            disabled={props.disable}
                            edge="start" color="inherit"
                            className={clsx(classes.button)}
                            onClick={()=>{
                                if (typeof props.onClone === "function"){
                                    props.onClone(props.templateCommunication.id)
                                }
                            }}
                        >
                            {GetIcon(TypeIcon.CLONE,clsx(classes.clone, props.disable && classes.btDisable))}
                        </IconButton>
                    )
                }
            </Grid>
            <Grid item xs={12} sm={1} className={classes.colDelete}>
                {
                    hover && (
                        <IconButton
                            disabled={props.disable}
                            edge="start" color="inherit"
                            className={clsx(classes.button)}
                            onClick={()=>{
                                if (typeof props.onDelete === "function"){
                                    props.onDelete(props.templateCommunication.id)
                                }
                            }}
                        >
                            {GetIcon(TypeIcon.DELETE,clsx(classes.delete, props.disable && classes.btDisable))}
                        </IconButton>
                    )
                }
            </Grid>
            <Grid item xs={12} sm={1} className={classes.colDelete}>
                {
                    props.executing ?
                        (
                            <CircularProgress color="primary" />
                        ):
                        hover && (
                            <IconButton
                                edge="start" color="inherit"
                                className={clsx(classes.button)}
                                onClick={()=>{
                                    if (typeof props.onExec === "function"){
                                        props.onExec(props.templateCommunication.id)
                                    }
                                }}
                            >
                                {GetIcon(TypeIcon.EXEC,classes.edit)}
                            </IconButton>
                        )
                }
            </Grid>
        </Grid>
    )
}

CardTemplateCommunication.propTypes = {
    disable:PropTypes.bool,
    executing:PropTypes.bool,
    templateCommunication:PropTypes.shape({
        id:PropTypes.string,
        name:PropTypes.string,
        type:PropTypes.string
    }).isRequired,
    onDelete:PropTypes.func.isRequired,
    onEdit:PropTypes.func.isRequired,
    onExec:PropTypes.func.isRequired,
    onPreview:PropTypes.func.isRequired,
    onClone:PropTypes.func.isRequired,
}

export default CardTemplateCommunication;
